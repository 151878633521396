.topLiquid {
  width: 450px;
  height: 167px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
  border-radius: 10px;

}

.topLiquid .pt:first-child {
  text-align: center;
  padding-top: 12px;
  font-size: 20px;

}

.slLiquid {
  display: flex;
  width: 132px;
  height: 32px;
  border-radius: 30px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
  border-radius: 30px;
}

.slLiquid #activeBg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  width: 24px;
  height: 22px;
  background-repeat: 50%;
  font-size: 24px;
  margin-top: 4px;
  margin-left: 4px;
}

.infCont {
  padding-top: 16px;
  font-size: 16px;
  width: 418px;
  margin: 0 auto;
  line-height: 75%;
  font-weight: lighter;
}


#pt {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
}

#pt .slLiquid .pt {
  width: 95px;
  font-size: 16px;
  padding-top: 4px;
  line-height: 12px;
  margin-left: 4px;

}

.bottomLiqSl {
  width: 442px;
  height: 270px;
  margin-top: 18px;
  border-radius: 10px;
}

.centerContModal {
  display: flex;
}

.rightLiquid {
  display: flex;
  /* flex-direction: column; */
  height: 525px;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  margin-left: 30px;
}

#modal26 .bottomCardFirst {
  flex-direction: column;
  justify-content: center;

}

.rightLiquid .cardPartFirst {
  height: auto;
  margin-bottom: 0;
  margin-right: 0;
}


#modal26 .bottomCardFirst {
  height: 150px;
  background: none;

}

#modal26 .botC {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 20px;
  padding: 0 16px;
  width: 228px;
  height: 100px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));

}

#modal26 .boxDays {
  width: 80px;
  height: 80px;

}

#modal26 .boxDays .pt:first-child {
  width: 79px;
  text-align: center;
}

.boxPeriod {
  display: flex;
}

#modal26 .usIdWax {
  width: 100px;
  margin-right: 0;
}

.pDay {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 21px;
  border-radius: 30px;
  padding-top: 3px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.50));
  cursor: pointer;
}

.pDay:hover {
  background: var(--dark-elements, rgba(54, 53, 53, 0.16));
}

.pDay:not(:last-child) {
  margin-right: 4px;
}

#modal26 .btnRig {
  width: 260px;
  height: 40px;
  margin: 0px 0px 0px 0px;
  position: static;
}

.bottomLiqSl {
  display: flex;
  flex-wrap: wrap;
  width: 443px;
  height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 14px;
}


.bottomLiqSl::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background-color: var(--glass-card, rgba(35, 38, 48, .5));
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
  background: var(--glass-card, rgba(56, 59, 69, 0.5));
  border-radius: 10px;
  width: 20px;
}

.liqCars {
  width: 98px;
  height: 138px;
  border-radius: 10px;
  margin: 6px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 620px) {
  .liqCars {
    width: 93px;
  }
}

@media (max-width: 800px) {

  .centerContModal {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .rightLiquid {
    width: 320px;
  }

  .topLiquid {
    margin: 0 auto;
    width: 320px;
    height: 300px;
  }

  .infCont {
    width: 270px;

  }

  #pt {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }

  .slLiquid:not(:first-child) {
    margin-top: 4px;

  }

  .bottomLiqSl {
    width: 320px;
  }

  .leftLiquid {
    margin-bottom: 50px;
  }

  .rightLiquid {
    margin-left: 0;
    align-items: start;
    padding-bottom: 50px;
    width: 320px;
    margin: 0 auto;
    text-align: center;
  }

  #modal26 .cardPartFirst:not(:last-child) {
    margin: 0 auto;
  }

  #modal26 .btnRig {

    margin: 40px 0px 0px 0px;
  }
}

.rightLiquid .userPart {
  width: 94px;
}