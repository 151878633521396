.SpnToken {
    margin-top: 130px;
}

.SpnToken .assetspage {
    width: 540px;
    height: 471px;
}

.SpnToken .top__menu__name {
    width: 540px;
}

.SpnToken#t .nft__card__content {
    width: 540px;
    height: 410px;
}

.SpnToken .title {
    margin: 0 auto;
}

.SpnToken .ticket p {
    font-size: 20px;
    padding-top: 30px;
}
.SpnToken .ticket {
    width: 300px;
}

.SpnToken  input{
    width: 60px;
    background: none;
}
.SpnToken  .fonTicketTop{
    width: 130px;
   
}
.SpnToken  .contentBlockTicket{
    width: 268px;
    margin-top: 36px;
   
}

.SpnToken .nft__card__token .contentBlockTicket:last-child {
    margin-top: 30px;
}
.SpnToken  .fonTicketTop{
    width: 130px;
   
}
.SpnToken  .bottom__menu{
    width: 540px;
    margin: 0 auto;
    margin-top: 14px;
   
}
.SpnToken #t.bottom__menu{
    margin-top: 0;
}

.SpnToken .bottom__menu button:last-child {
    margin: 0;
    margin-bottom: 24px;
}

.price__coin {
    background: url(../../img/coinx2.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 42px;
    margin: 0 auto;
    margin-top: 9px;
    width: 30px;
    height: 30px;
}

.SpnToken .block__sum {
    background: none;
}
.SpnToken .icon__modal {
    top: 0;
    right: -15px;

}
.SpnToken .Craft__price {
    width: 130px;

}

.SpnToken .Craft__price:last-child {
    margin-top: 12px;
}

.SpmSumm {
    display: flex;
    flex-direction: column;
    width: 130px;
}
.SpmSumm .Craft__price .icon__sum p,.SpmSumm .Drop__card .icon__sum p{
   bottom: -8px;
   left: -35px;
}


.SpmSumm .icon__spn__p p {
    top: 4px;
    right: 38px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.boxIconPurchase {
    position: relative;
    width: 146px;
    height: 151px;
    margin-left: 53px;
}

.containerPurchase {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    
}

.iconBigCoin {
    pointer-events: none;
    background: url(../../img/maxToken.webp);
    background-repeat: no-repeat;
    background-position: bottom;
    width: 146px;
    height: 151px;
    position: absolute;
    top: 0;
    z-index: 2;
}

.iconCirclFon {
    pointer-events: none;
    background: url(../../img/Glow\ Circle\ \(5\)\ \(1\).webp);
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 200px;
    position: absolute;
    z-index: 1;
    right: -78px;
    top: 36px;
}
.SpmSummToken {
    display: flex;
    width: 101px;
   
}
.SpnToken .assets .name__wallet p:first-child {
   display: block;
   top: 24px;
}




.SpmSumm .rightSumm .icon__sum p{
    position: absolute;
    left: -13px;

}
.SpmSumm .rightSumm .icon__spn__p{
    position: absolute;
    right: -38px;
    bottom: 19px;
}

.SpmSumm .rightSumm .icon__spn__p{
    position: absolute;
    right: -38px;
    bottom: 19px;
}
.SpmSumm .leftSumm  .icon__spn__p p{
    position: absolute;
    right: 41px;
}
.SpmSumm .rightSumm .icon__spn__p{
    position: absolute;
    right: -48px;
    bottom: 19px;
}
.SpmSumm .leftSumm .icon__sum p{
    left: -50px;
}
.SpmSumm .equals {
    font-weight: 400;
    margin: 6px -30px 0px 2px;
}

.SpnToken .assets .name__wallet {
    margin-top: 5px;
}

.rightSumm {
    width: 40px;
    margin-left: 12px;
}

.leftSumm {
    width: 40px;
    margin-right: 5px;
}

.SpnToken .bottom__menu #b:disabled:hover{
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    box-shadow: none;
    animation: none;
    cursor: inherit;
}




@media (max-width: 610px) {
    .SpnToken .assetspage {
        width: 300px;
        
    }

    .SpnToken .top__menu__name {
        width: 300px;
    }
    
    .SpnToken#t .nft__card__content {
        width: 300px;
        height: 560px;
    }
    .BoxPurchase  span{
        display: block;
        text-align: center;
        width: 165px;
    }
    .SpmSumm {
       margin: 0 auto;
    }

    .SpnToken .ticket {
        width: 277px;
    }
    .SpnToken .ticket p{ 
        font-size: 18px;
    }

    .boxIconPurchase {
        margin-left: 0;
        margin-top: 25px;
    }

    .SpnToken .bottom__menu {
        width: 300px;
    }

    .containerPurchase {
        flex-direction: column;
        margin: 0 auto;
        width: 165px;
    }
    
   
}


