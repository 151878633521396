.content {
    height: 100%;
    text-align: center;
    margin-left: 120px;
    margin-right: 88px;
    flex-wrap: wrap;  
    overflow-y: scroll;
    overflow-x: hidden;
}


.FirstContent {

    margin-left: 0;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
}







#h {
    margin-left: 28px;
}


/* @media (max-width: 1024px) {
   
    .content {
        margin-right: 0;
        margin-left: 0;
    }
  
  
} */

@media (max-width: 610px) {
   
    .content {
        margin-right: 0;
        margin-left: 0;
    }
  
  
}