.wallet__content {
    display: flex;
    justify-content: space-between;
    height: 63px;
}

.icon__cash {
    background: url(/src/img/wallet\ \(1\).svg) no-repeat;
    width: 23px;
    height: 23px;
    margin: 20px 13px;
}

.block__sum {
    display: flex;
    position: relative;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    width: 116px;
    height: 20px;
   
}

.AssedsCardMenubottom .block__sum {
    background: none;
}

.block__sum:not(:last-child) {
    margin-bottom: 6px;
}

.wallet__info {
    margin: 6px 7px 11px 6px;
    flex-direction: column;
    height: 46px;
}


.icon__sum,
.icon__spn,
.name__wallet p {
    height: 10px;
    margin: 0;
    font-size: 16px;
}

.name__wallet p  {
    margin: 0px 14px 11px 8px;
}
.icon__spn p,
.icon__sum p {
    position: absolute;
    margin: 0;
    height: 20px;
    font-size: 16px;
}

.icon__spn p {
    right: 9px;
}
header .icon__sum p {
    width: 0;
}


header .wallet__content .icon__cash{
    display: none;
}
.header__cash,
header .wallet__content{
    width: 180px;
    height: 58px;
}
header .name__wallet{
    line-height: 22px;
}

header .icon__sum p {
    line-height: 22px;
}
header .nambTicket {
   line-height: 17px;
}

.boxSpnWallet {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    width: 46px;
    height:46px;
}
.icon__sum p {
    width: 87px;
    text-align: right;
}



.icon__spn p {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 400;
}

@media (max-width: 610px) {
    .mob__menu__container .icon__sum p {
        text-align: left;
    }

    .boxSpnWallet {
        padding-top: 0px;
    }
}
