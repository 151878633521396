.preloader {
  background-image: url(../../img/loader.gif);
 background-color: #1c1f28;
  background-repeat: no-repeat;
 
  background-position: center;
  background-size: 60px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* .preloaderBTN {
  background-image: url(../../img/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999;
  overflow: hidden;
  backdrop-filter: blur(24px);
} */




.preloader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preloaderSpinney {
  background-image: url(../../img/loader.gif);
  background-color: url(../../img/BG.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


.preloaderSpinney video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preloader-local {
  background-image: url(../../img/loader.gif);
  background-color:  var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  backdrop-filter: blur(2px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}