#modal25 .modal,
#modal26 .modal {
    max-width: 740px;
    width: 100%;
    height: 500px;
    background: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 15px;
}

@media(max-width: 800px) {
    flex-direction: column;

    & > div {
        text-align: center;
    }
}

#modal26 .modal {
    display: flex;
    width: 740px;
    height: 565px;
}

.topTitlModal {
    width: 100%;
    height: 51px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    margin-bottom: 10px;
    font-size: 32px;
    text-align: center;
    line-height: 55px;
}

.botContModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    height: 439px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

.contCardsPart {
    width: 700px;
    height: 282px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0px 10px 10px 10px;
    padding: 30px 20px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-start
}


.contCardsPart::-webkit-scrollbar {
    width: 3px;
    height: 15px;
}

::-webkit-scrollbar-track {
    background-color: var(--glass-card, rgba(35, 38, 48, .5));
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: var(--glass-card, rgba(56, 59, 69, 0.5));
    border-radius: 10px;
    width: 20px;
}


.cNftPartA {
    width: 125px;
    height: 125px;
    border-radius: 10px;
    margin-bottom: 10px;


}

.cNftPartA:not(:last-child) {
    margin-right: 10px;
}

.cNftPartA img {
    max-width: 100%;
}

.idCards {
    width: 125px;
    height: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.50));

}

/*  pict first card */
.onebgWAp,
.onebgSP {
    width: 125px;
    height: 125px;
    /* background-image: url(../../img/Group\ 776.webp); */
    background-color: #334976;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.onebgSP:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff;
    transition: all 0.3s;
}

.onebgSP:hover:before {
    opacity: 0.1;
}

/*  */
.onebgWAp {
    background-image: url(../../img/image\ 208.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

.idCards {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.NmbPartName {
    font-size: 16px;
    line-height: 16px;
    height: 18px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.50));
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 3px 0 0 0;
}

.NmbPartName .PartNum {
    width: 32px;
    height: 14px;
    text-align: center;
    margin-top: 0;
    margin-left: 8px;
    
    float: left;

}

.usPartN {
    margin-right: 9px;
    float: right;
}

.usPartN::first-letter {
    text-transform: uppercase;
}

#modal26 .btnRig,
#modal25 .btnRig {
    font-size: 20px;
    width: 152px;
    height: 30px;
    border: none;
    margin: 0px;
    position: absolute;
    bottom: 16px;
    right: 40px;
}

.activPart {
    width: 125px;
    height: 125px;
    border-radius: 10px;
    border: rgba(130, 193, 47, 0.80);

    box-shadow: 0px 0px 3px 0px rgba(130, 193, 47, 0.90), 0px 0px 7px 0px rgba(130, 193, 47, 0.80), 0px 0px 12px 0px rgba(130, 193, 47, 0.70), 0px 0px 20px 0px rgba(130, 193, 47, 0.50), 0px 0px 1.5px 0px #FFF;
}

.burns .onebgSP {
    width: 98px;
    height: 137px;
    background-repeat: no-repeat;
}