.containerRefPage {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.refPage {
    display: flex;
    flex-direction: column;
    margin-top: 116px;
    height: 100%;
    margin-bottom: 300px;

}

.ref__content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 408px;
}

#Lotery .ref__content {
    flex-direction: column;
}


.refbtn {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.20);
    background-image: url(../../img/Group\ 313.webp);
    cursor: pointer;
    padding: 0;
}

.bottom__width,
.block__ref {
    display: flex;
    width: 408px;
    height: 50px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 5px;
    margin-bottom: 9px;
}

.boxLeftReferal {
    margin-right: 24px;
}

.bottom__width {
    width: 200px;
}

#BoUp .bottom__width {
    width: 408px;
}

#BoUp #count {
    width: 358px;
}
.titleLeaderbordVolume {
    margin-top: -15px;
    background: url(../../img/Group\ 433.webp);
    background-size: 100%;
    background-position-y: center;
    background-repeat: no-repeat;
    width: 511px;
    height: 130px;
}
.refActiv,
.refCount,
.refNameLink {
    background-image: url(../../img/Link.webp);
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    height: 50px;
    width: 50px;
}

/* .contentRefPage #WaxRef{
 margin-top: 0;
} */

.refCount {
    background-image: url(../../img/Group\ 318.webp);

}

.refActiv {
    background-image: url(../../img/Group\ 318\ \(1\).webp);

}

.title__link {
    margin-left: 10px;
    margin-top: 13px;
}

#referrals,
#count {
    display: flex;
    flex-direction: column;

}

#referrals .title__link,
#count .title__link {
    margin-left: 10px;
    margin-top: 0px;
}

#referrals .title__link:first-child,
#count .title__link:first-child {
    font-size: 36px;
    margin-top: 14px;
    line-height: 9px;
}

.popup-message {
    font-size: 30px;
    color: aqua;
}

.ref__info {
    width: 408px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 68px;
}

.popup__link {
    width: 65px;
    height: 26px;
    background: var(--glass-card, rgba(35, 38, 48, 0.50));
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    font-size: 16px;
    position: absolute;
    top: -20px;
    right: -23px;
    text-align: center;
    line-height: 28px;
    border-radius: 5px;
}

.copy__link {
    width: 279px;
    height: 26px;
    background: linear-gradient(180deg, rgba(47, 51, 65, 0.95) 0%, rgba(25, 27, 32, 0.95) 100%);
    backdrop-filter: blur(4px);
    border-radius: 5px;
    margin: 12px 12px 12px 24px;
    padding-left: 6px;
    line-height: 28px;
    cursor: pointer;
    background-image: url(../../img/Send\ 2.webp);
    background-repeat: no-repeat;
    background-position: right;
    font-size: 16px;
    color: var(--text-2, #9AA0B0);
}

.block__ref2 .title__link.ico {
    position: relative;
    width: 50px;
    left: 23px;
    margin-left: -15px;
    margin-top: 12px;
}

.block__ref2 {
    display: flex;
}

.priceRef {
    font-size: 36px;
    font-weight: 400;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

#WaxRef {
    justify-content: space-between;
}

#WaxRef .title__link {
    margin-left: 16px;
}

.btnPoints {
    width: 40px;
    height: 13px;
    color: var(--text-2, #9AA0B0);
    font-size: 16px;
    padding-top: 3px;
    display: flex;
    text-transform: uppercase;
    background: linear-gradient(180deg, rgba(47, 51, 65, 0.95) 0%, rgba(25, 27, 32, 0.95) 100%);
    border-radius: 5px;
    border: none;
    justify-content: space-evenly;
    align-items: center;
    margin: 18px 12px 0 12px;
}

.copy__div {
    position: relative;
}

/* .ref__content .block__ref:last-child {
    margin-bottom: 150px;
} */
.ref__content .allRef{
    margin-bottom: 76px;
}
.blockPoints {
    display: flex;
    justify-content: space-between;
}

.bottomRef {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 60px;
}

.refRight {
    transform: rotate(180deg);
    margin-left: 16px;
}

.RefMenu {
    margin: 0 auto;
    text-align: center;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    margin-bottom: 70px;
    line-height: 31px;
}

.ref__content .top__menu__name {
    margin-bottom: 9px;
    width: 408px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.ref__content .top__menu__name .title {
    height: 45px;
    margin: 0 auto;
    line-height: 50px;
}

.refRp {
    background-image: url(../../img/rp.svg);
    background-position: center;
}


.refCalc {
    background-image: url(../../img/Group\ 535.svg);
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    height: 50px;
    width: 50px;

}

.refRp {
    background-image: url(../../img/rp.svg);
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    height: 50px;
    width: 50px;
}


.ProgressRefMultip {
    width: 408px;
    height: 84px;
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    padding-top: 26px;
    position: relative;

}

.progress-barRefMultip {
    width: 392px;
    height: 20px;
    border-radius: 5px;
    margin: 0 auto;


}

.blockRefMultip {
    position: relative;
    display: inline-block;
    height: 100%;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin-right: 2px;
    overflow: hidden;

}

.RefMultipProgress {
    width: 96px;
    height: 20px;
    border-radius: 5px;
}





.block-25,
.block-50,
.block-75,
.block-100 {
    width: 96px;
    border-radius: 5px;


}

.activeRef {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.blockRefMultip {
    text-transform: uppercase;
    text-align: end;

}

.blockRefMultip span {
    display: block;
    line-height: 23px;
    position: absolute;
    top: 0px;
    right: 5px;
}

.ProgressRefMultip .multLvl {
    background: url(../../img/Union\ \(5\).webp);

}

.ProgressRefMultip .multLvl.activ {
    background: url(../../img/Union\ \(4\).webp);

}

.ProgressRefMultip .contMultInfo__0 .multLvl {
    left: 38px;
}

.ProgressRefMultip .contMultInfo__1 .multLvl {
    left: -81px;
}

.ProgressRefMultip .contMultInfo__2 .multLvl {
    left: -106px;
}

.ProgressRefMultip .contMultInfo__3 .multLvl {
    left: -130px;
}

.ProgressRefMultip .multLvl p {
    top: -5px;
    left: 13px;
}

.ProgressRefMultip .multLvl span {
    top: 11px;
}

.lastRef {
    margin-bottom: 9px;
    height: 50px;

}

.firstTopRef {
    height: 126px;
    margin-bottom: 0px;
}
/* Leaderbord */
.Leaderbord {
    margin-top: 121px;
}

.wrapAllRef .Leaderbord{
    margin-top: 0;
}

.blockTopLeaderbord {
    display: flex;
    justify-content: center;
}

.titleLeaderbord {
    margin-top: -12px;
    background: url(../../img/Group\ 644.webp);
    background-size: 100%;
    background-position-y: -16px;
    background-repeat: no-repeat;
    width: 511px;
    height: 113px;
}

.boxLeaderbord {
    display: flex;
    width: 500px;
    height: 40px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    border-radius: 5px;
    margin-left: 13px;
    margin-right: 13px;
}

.boxNumdLeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 36px;
    width: 50px;
    height: 40px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 5px;
    line-height: 45px;
}

.borderBox {
    background: url(../../img/Rectangle\ 763.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 1px;
    height: 20px;
    margin: 10px 5px 0px 20px;
}

.winnerLoaderBg {
    width: 500px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.20);
    box-shadow: 0px 0px 3px 0px rgba(246, 205, 58, 0.90), 0px 0px 7px 0px rgba(246, 205, 58, 0.80), 0px 0px 12px 0px #F6CD3A;
}

.buttonRp {
    width: 40px;
    height: 14px;
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 12px 0px 0px;
    padding-top: 2px;
}

.winnerIconTicket {
    position: relative;
    background: url(../../img/Ticket\ 700x500\ 1\ \(4\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 40px;
    height: 40px;
}

.numWinTicket {
    position: absolute;
    bottom: 6px;
    right: -8px;
    width: 30px;
    height: 16px;
    text-align: center;
    line-height: 18px;
    border-radius: 13px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.Leader2 .infoPoints {
    width: 61px;
    margin-left: 50px;
}

.winnerBoxStarter {
    background-image: url(../../img/start1.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 47px;
    height: 36px;
}
.winnerBoxMystery {
    background-image: url(../../img/mys2.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 47px;
    height: 36px;
}
.numberLeader.volNumb {
    width: 90px;
}

.Leader2 .infoWallet {
    width: 222px;   
}
.winnerBoxInfinity {
    background-image: url(../../img/inf3.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 47px;
    height: 36px;
}


.walletNameWax {
    display: flex;
    align-items: center;
    width: 260px;
    margin-left: 20px;

}

.numberLeader {
    text-align: right;
    font-size: 36px;
    width: 60px;
    line-height: 43px;
    margin-right: 8px;

}

.contentLeaderbord .boxLeaderbord:not(:first-child) {
    margin-top: 4px;
}

.contentLeaderbord .boxLeaderbord:last-child {
    margin-bottom: 120px;
}

.wrapperContentLeader {
    height: 284px;

}
.wrapper .wrapAllRef{
    background-image: url(../../img/Group\ 655.webp);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;

}


.contentLeaderbord {
    padding-top: 14px;
    margin: 0 auto;
    height: 284px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 140px;
}

.contentLeaderbord::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.contentLeaderbord::-webkit-scrollbar-thumb {
    background: var(--Important-elements, linear-gradient(180deg, #f08119cb 0%, #f6cd3adb 100%));
	border-radius: 10px;
	width: 20px
}

.contentLeaderbord::-webkit-scrollbar-track {
    background-color: var(--glass-card,rgba(35,38,48,.5));
	border-radius: 10px;
	-webkit-box-shadow: 5px 5px 5px -5px rgba(34,60,80,.2) inset;
}

.playerLeaderbord {
    display: flex;
    width: 500px;
    height: 50px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    border-radius: 5px;
    margin-bottom: 12px;
}
.boxTopBord {
    margin-left: 13px;
}
.playerLeaderbord .walletNameWax {
    width: 160px;

}

.playerLeaderbord .boxNumdLeader {
    height: 50px;
    width: 50px;
    line-height: 54px;
}

.rew {
    display: flex;
    align-items: center;
}

.bgRW {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px 0px 33px;
    padding-right: 10px;
    width: 55px;
    height: 30px;
    border-radius: 20px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    position: relative;
    line-height: 31px;
}

.bgBlock {
    width: 400px;
    height: 2px;
    border-radius: 2px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 0 auto;
}

.btn__claim {
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    border-radius: 20px;
    border: none;
    margin: 10px 0px 0px 8px;

}

.tickFon {
    background: url(../../img/Ticket\ 700x500\ 1\ \(4\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 40px;
    height: 40px;
    position: absolute;
    left: -20px;
    top: -4px;
}

.boxInfoLeaderbord {
    width: 500px;
    height: 92px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    margin-bottom: 16px;
    border-radius: 5px;
}

.infoLeaderbord {
    width: 351px;
    height: 52px;
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    line-height: 16px;
    margin: 0 auto;
    padding-top: 23px;

}

.infoTopContent {
    display: flex;
    align-items: center;
    width: 500px;
    height: 40px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    border-radius: 5px;
    margin-top: 18px;
    margin-bottom: 6px;
    color: var(--Text-2, #9AA0B0);
}

.infoNumb {
    width: 31px;
    margin-left: 20px;
}

.infoWallet {
    width: 245px;
    margin-left: 20px;

}

.infoReward {
    width: 70px;
    margin-left: 5px;
}

.infoPoints {
    width: 92px;
    margin-left: 10px;

}

.linkLeader {
    line-height: 0.6;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media (max-width: 840px) {
    .ref__content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 408px;
    }

    .boxLeftReferal {
        margin-right: 0px;
    }

    .refPage {
        height: 100%;
    }

    .bgBlock {
        width: 200px;

    }

    #WaxRef {
        margin-top: 0px;
    }


}

@media (max-width: 810px) {
    .infoTopContent {
        width: 300px;
        margin: 0 auto;
        margin-top: 18px;
        margin-bottom: 8px;
    }
    .infoLeaderbord {
        width: 250px;
        height: 62px;
        padding-top: 13px;
    
    }

    .infoWallet {
        width: 63px;
        margin-left: 5px;
    }
    .infoReward {
        width: 55px;
        margin-left: 46px;
    }

    .infoNumb {
        width: 20px;
        margin-left: 5px;
    }
   .boxInfoLeaderbord{
        width: 300px;
        margin: 0 auto;
        margin-bottom: 16px;
   }
    .contentLeaderbord {
        width: 300px;
    }

    .boxLeaderbord {
  
        width: 300px;
    }

    .titleLeaderbord {
        background: url(../../img/Group\ 644.webp);
        background-size: 100%;
        background-position-y: -16px;
        background-repeat: no-repeat;

    }

    .ProgressRefMultip {
        margin-top: 69px;
        margin-bottom: 100px;
    }

    .containerRefPage {
        display: flex;
        justify-content: center;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

    }

    .ref__content .top__menu__name {
        width: 308px;
    }

    .block-25,
    .block-50,
    .block-75,
    .block-100 {
        width: 70px;
        border-radius: 5px;
    }

    .progress-barRefMultip {
        width: 290px;

    }



    .refPage .copy__link {
        width: 172px;
        margin: -29px 12px 12px 64px;
        overflow-y: scroll;
    }

    .ProgressRefMultip {
        width: 308px;
    }

    .block__ref {
        max-width: 308px;
    }

    .ref__info {
        width: 308px;
        flex-direction: column;
    }

    .block__ref2 {
        display: block;
    }

    .block__ref2 .title__link {
        margin-top: 0;
        text-align: center;

    }

    #referrals,
    #count {
        display: flex;
        flex-direction: initial;

    }

    .ref__content {
        width: 308px;
    }

    .wrapper .refPage {
        margin-top: 40px;
        max-width: 610px;
    }

    #referrals .title__link:first-child,
    #count .title__link:first-child {
        line-height: 26px;
    }

    #referrals .title__link,
    #count .title__link {
        margin-top: 11px;
    }

    .bottom__width {
        width: 308px;
    }

    .copy__link {
        margin: -8px 12px 12px 24px;
        background-color: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    }

    .ProgressRefMultip .contMultInfo__0 .multLvl {
        left: 15px;
    }

    .ProgressRefMultip .contMultInfo__1 .multLvl {
        left: -133px;
    }

    .ProgressRefMultip .contMultInfo__2 .multLvl {
        left: -180px;
    }

    .ProgressRefMultip .contMultInfo__3 .multLvl {
        left: 170px;
    }


    .ProgressRefMultip .contMultInfo__3 {
        top: 46px;
    }

  
    .playerLeaderbord {
        width: 300px;
        margin: 0 auto;
        margin-bottom: 25px;
    }

    .rew {
        margin-left: 10px;
    }

    .tickFon {
        left: -27px;
        top: -4px;
    }

    .bgRW {
        width: 120px;
    }

    .walletNameWax {
        margin-left: 5px;
    }


}

@media (max-width:610px) {
   
  

    .contentLeaderbord {
        width: 320px;
        margin: 0 auto;
    }

    .boxLeaderbord {
        width: 300px;
    }

    .buttonRp {
        width: 70px;
        margin-left: 12px;
    }

    .Leader2 .infoWallet {
        width: 104px;
    }
    .borderBox {
        margin-top: 15px;
    }

    .titleLeaderbord {
        background: url(../../img/Group\ 644.webp);
        background-size: 100%;
        background-position-y: -16px;
        background-repeat: no-repeat;

    }

     

}