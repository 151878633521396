.nft__card {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
}

.Assets1 {
    margin-top: 40px;
    width: 785px;
}


#topAssets {
    position: relative;
   
}

.preloaderBTN {
   width: 82px;
   height: 26px;
   border-radius: 20px;
}


.slider__container {
    display: flex;
    width: 373px;
    height: 440px;
}

#e .nft__card__content {
    position: relative;
    width: 380px;
    height: 440px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-top: 10px;
}


.bank__content .bottom__menu button {
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.3);
}

.bank__content .bottom__menu button a {
    text-transform: unset;
}

.nft__person_btn {
    position: absolute;
    top: 0px;
    left: 0;
    text-align: center;
    line-height: 20px;
    color: #fff;
    background: var(--glass-card, rgba(35, 38, 48, 0.50));
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    border-radius: 5px 5px 0px 0px;
    width: 98px;
    height: 16px;
    border: none;
    z-index: 999;
   
}

.ingameslot {
    pointer-events: none;
}
#bt {
    position: absolute;
    right: 38px;
}


#assets .bottom__menu {
    position: relative;
    left: 18px;
    bottom: 61px;
}

.bank__content .bottom__menu {
    background: none;
}

.assets {
    margin-right: 24px;
}

.assetspage {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    margin: 0 auto;
    max-width: 632px;
}

#nft__card__assets {
    width: 336px;
    height: 523px;
}

.nft__card .player {
    position: absolute;
    top: 24px;

}

/*  */
.bottom__menu__chek {
    display: flex;
    margin-left: 24px;
}

.radio__btn {
    margin-top: 5px;
    margin-right: 7px;
    height: 16px;
    width: 16px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.radio__btn__chek--bg__nft {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
}


/* game */

.radio__btn__text {
    cursor: pointer;
}

.bottom__menu__chek__game {
    display: flex;
    margin-left: 24px;
}

.radio__btn__game {
    margin-top: 5px;
    margin-right: 7px;
    height: 16px;
    width: 16px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}



.radio__btn__chek__game--bg__nft {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
}


.loaderGif {
    background: url(../../../img/loader.gif);
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
}



.nft_leaf {
    background: url(../../../img/newLeaflynn2\ \(1\).webp)no-repeat;
}

.nft_mag {
    background: url(../../../img/nevmag.webp)no-repeat;

}

.nft_steel {
    background: url(../../../img/newSteelade\ \(2\).webp)no-repeat;
}

.nft_frost {
    background: url(../../../img/newFrostee\ \(2\).webp)no-repeat;
}



.nft_legend {
    background: url(../../../img/newSunshine\ \(1\).webp)no-repeat;
}

.nft_violetta {
    background: url(../../../img/newVioletta\ \(1\).webp)no-repeat;
}
.nft_jingl{
    background: url(../../../img/01\ Jingles.webp)no-repeat;
    position: absolute;
    top: 0px;
    left: 0;
    background-repeat: no-repeat;
    width: 98px;
    height: 138px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.nft_leaf,
.nft_mag,
.nft_steel,
.nft_frost,
.nft_legend,
.nft_violetta {
    position: absolute;
    top: 0px;
    left: 0;
    background-repeat: no-repeat;
    width: 98px;
    height: 138px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
}



.nft__card .player__assets {
    width: 82px;
    height: 26px;
    border-radius: 20px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    text-align: center;
    margin-left: 20px;
}

.nft__card .title {
    margin-right: 40px;
}


.stakeNft {
    width: 98px;
    height: 138px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.60);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    cursor: default;
}


#stakeNft {
    width: 74px;
    height: 16px;
    border-radius: 10px;
    color: #fff;
    background: var(--glass-card, rgba(35, 38, 48, 0.50));
    /* New Card */
    backdrop-filter: blur(25px);
    position: absolute;
    z-index: 3;
    top: 61px;
    left: 12px;
    line-height: 18px;
}

#t .nft__card__content {
    width: 380px;
}


.common__test {
    background-image: url(../../../img/newCommon\ Rune.webp);
}

.legend__test {
    background-image: url(../../../img/newLegend\ Rune.webp);
}

.jingl_test {
    background-image: url(../../../img/snowPrommo.webp) !important;
}

.uncommon__test {
    background-image: url(../../../img/newUncommon\ Rune.webp);
}

.epic__test {
    background-image: url(../../../img/newEpic\ Rune.webp);
}

.rare__test {
    background-image: url(../../../img/newRare\ Rune.webp);
}

.promo__test {
    background-image: url(../../../img/138Prommo.webp);
}
.jingles__test {
    background-image: url(../../../img/01\ Jingles.png);
}

.jingl__test {
    background-image: url(../../../img/snowPrommo.webp);
}

#Assets__slider {
    width: 390px;
    height: 300px;
    padding-top: 16px;
    /* margin-bottom: 24px; */
    display: flex;
}

#Assets__slider .swiper-wrapper {
    width: 330px;
    height: 200%;
    margin-left: 8px;
    /* margin-top: 10px;  */
}




.Assets__slider .swiper-slide {
    width: 110px;
    height: 140px; 
    margin-bottom: 0;
    
}

#Assets__slider .craft__card__slider {
    left: 32px;
    overflow-y: unset;
}

.spinney {
    width: 390px;
    height: 100%;
}

#Assets__slider #available__violetta__4,
#Assets__slider #available__sunshine__5,
#Assets__slider #available__common__1,
#Assets__slider #available__legend__5,
#Assets__slider  #available__promo__0,
#Assets__slider  #available__rare__3,
#Assets__slider #available__epic__4,
#Assets__slider #available__uncommon__2,
#Assets__slider #available__leaf__2,
#Assets__slider #available__mag__0,
#Assets__slider  #available__steel__1,
#Assets__slider #available__frost__3 {
    left: 12px;
    right: 0;
    top: 0px;
}


@media (max-width: 800px) {
    .assetspage {
        flex-direction: column;
        width: 380px;
    }

   .w{
    margin-bottom: 130px;
   }

    .blockWallet {
        margin-bottom: 140px;
    }

    .assets {
        margin-bottom: 30px;
    }
}

@media (max-width: 610px) {
    .assetspage {
        
        max-width: 300px;

    }

    .assets {
        margin-bottom: 32px;
        display: flex
    }

    .blockWallet {
        height: 640px;
    }
    
    #Assets__slider .swiper-wrapper {
        width: 217px;
    }

    .assetspage {
        flex-direction: column;
    }

    .token__title p:first-child {
        width: 279px;
    }
    .assetspage .craft__cards {
        background: none;
    }
}
