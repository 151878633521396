.block__formLog {
  display: flex;
  justify-content: center
}

.container__form {
  display: flex;
  flex-direction: column;
  margin-top: 180px;

}

.container__form .LoginContent.top__menu__name{
  text-align: center;
  width: 289px;
}
.StartPage {
  margin-top: 180px;
  display: flex;
  justify-content: center;
}
.reg {
  display: flex;
  justify-content: center;
}
.btn__register {
  width: 152px;
  height: 31px;
  border: none;
  border-radius: 20px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
  cursor: pointer;
  color: #FFF;
  margin-top: 24px;
}
input:focus, textarea:focus, select:focus {
  outline: none !important;
}
input {
  padding-left: 9px;
  width: 189px;
  height: 22px;
  border: none;
  border-radius: 10px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}



#form .choose__server {
  margin-left: 49px;
}

#form .btn__register {
  margin-top: 20px;
}



