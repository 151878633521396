.BoostUpIcon {
    background-image: url(../../img/Group\ 314.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
}
.iconBack {
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    background-image: url(../../img/Group\ 619.webp);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.Back {
    display: flex;
    width: 100px;
    height: 30px;
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 20px;
    cursor: pointer;
}
.titleBack {
    margin-left: 17px;
    line-height: 33px;
}

#Lotery .ref__content {
    margin-top: 70px;
    margin-bottom: 40px;
}
/* s */
#Lotery .RefMenu {
    line-height: 32px;
}
#Lotery .block__ref {
    height: 40px;
}
#Lotery .ref__info .block__ref {
    height: 49px;
}
.boostUpWinnerList .title__link,
#Lotery .block__ref.hidden .title__link {
    margin-top: 7px;
}
.blockTopLotery {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    width: 228px;
    height: 30px;
    margin: 0 auto;

}
.blockTopLotery .RefMenu {
    margin-bottom: 0;
    line-height: none;
    width: 120px;
}
.idLink {
    background-image: url(../../img/Paperclip\ \(1\).webp);
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
}
.ref__info .RegisteredList {
    margin-bottom: 10px;
}


.RegisteredList #count .title__link ,
.RegisteredList #referrals .title__link{
    font-size: 20px;
    margin-top: 17px;
   
}
.regListTop .RegisteredList #count .title__link ,
.regListTop .RegisteredList #referrals .title__link{
    font-size: 20px;
    margin-top: 22px;
    
}


.RegisteredList #count .centerLink {
    background: none;
    text-align: center;
    margin-left: 0;
}

.iconPlayerInList {
    width: 22px;
    height: 30px;
    padding-top: 3px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px 10px 0 5px;
}

.ContentList #WaxRef{
    height: 40px;
}


.activCard {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* icon player */

.frosty__3 {
    background: url(../../img/newFrostee\ \(2\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
 
}
.common__1 {
    background: url(../../img/newSteelade\ \(2\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
  
 
}
.uncommon__2 {
    background: url(../../img/newLeaflynn2\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
  
 
}

.legend__5 {
    background: url(../../img/newSunshine\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
   
  
}
.violett__4 {
    background: url(../../img/newVioletta\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;


}
@media (max-width: 710px) {
    #BoUp .title__link.centerLink {
        margin-top: 14px;
        margin-left: 70px;
    }
}
