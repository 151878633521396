@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@100;300;400&family=Golos+Text:wght@100;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap');

.menu__item,
.u,
#slots,
.container__blur,
.degree,
.infoBtnLeader,
.total__add,
.title__link,
.equals,
.player__assets,
.FirstPage,
.fon__born__tocket,
.btn__modal button,
.option__block,
.option,
.blockRefMultip,
.choose__server,
.title__slots,
.register,
.block__logo__premium,
.block__logo,
.OfferTag__card,
.claim__price,
.icon__spn__p,
.link_step,
.title_purchase,
.btnPoints,
.cancelRuna,
.id__runs,
.stakeRuna,
.arag__modalRuna,
.title__modalRuna,
.BlockMyAvailable,
.Drop__menu__name,
.DropChances,
.expired,
.option,
.StopClaimDis,
.spnWax,
.Drop__card__content,
.numberLeader,
.textMarket,
.inf__leaf__c,
.leaf__one__price,
.leaf__one__button,
.headBurnSpn,
.leaf__one__description,
.available,
.btnProgress,
.titleDay,
.craft__menu button,
.bt__nav,
.name__state,
.contentText,
.AssedsCardMenuTop,
.nav a,
.menu__nav__bank,
#indexCard,
.buttonRp,
.rightInfoJackpot,
.storBalance,
.spnText,
.text__boxIncNumber,
.walletNameWax,
.q,
.numWinTicket,
.infoTopContent,
.boxNumdLeader,
.name__spinney,
.contAttention,
.Winnerslist,
.info__card__uncommon,
.btn__modal,
.titleCurrentBalance,
.titlePapBlock,
.nft__person_btn,
.winnerTicket,
.item__nav,
.rew,
p,
input,
.btn,
.tokenDiscont,
a,
.bgLevel,
.first__card,
.Burned,
.btn__claim,
.info__block,
.item,
.bottomContLavel,
.speed,
.bottom__menu,
.bgRW,
.title,
.TimeStartBtn,
.player,
.Registlist,
.BlockProgressInfo,
.playerSpin,
.slLiquid #activeBg,
.bottom__mobMenu,
.topTitlModal,
.clBtn,
.NmbPartName,
.idCards,
.infoLeaderbord,
.nav__content__link,
.burn,
.parag__modalRuna,
.top__mobMenu,
.reg button,
.multLvl,
.statusSpn,
.first__card p,
.slots__block,
.btnTicket,
.leftInfoJackpot,
.placeholder,
#stakeNft,
input[type=text],
.headerLevel,
.PartNum,
.usNameWax,
.usIdWax,
.priceRef,
.RefMenu,
.DropBuy,
.dropdown__optionsAtomic,
.copy__link,
.popup__link,
.btn__registe,
.nambTicket,
.pt,
.maxPartNum,
.costs,
.burnRunsCateg,
.textTicket,
.spinAll button,
.titleBack,
.SpinSpid {
  font-family: 'Dongle', sans-serif;
  font-style: normal;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-size: 20px;
}

.box-size, .box-size * {
  box-sizing: border-box;
  font-family: 'Dongle', sans-serif;
}

.btnNumberSpn,
.title-jackpot,
.btnNumberSpnTimer {
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
  font-weight: 900;
  color: var(--Text-1, #FFF);
}

.title-jackpot {
  font-size: 24px;
}

.btnNumberSpn {
  font-size: 36px;
}

.animNumberJack,
.titleWinner,
.sale__closed span,
.sold__out span {
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 900;
  color: var(--Text-1, #FFF);
}


body {
  padding: 0;
  margin: 0;
  min-width: 320px;
}

/* .jackpot-main::-webkit-scrollbar,
#modal6::-webkit-scrollbar,
.leaderboard-wrapper::-webkit-scrollbar,
.LevelPage::-webkit-scrollbar,
.containerRefPage::-webkit-scrollbar,
.main__content::-webkit-scrollbar{
	width: 8px;
  height: 30px;
} */
.wrapAllRef::-webkit-scrollbar,
.scroll-container::-webkit-scrollbar,
#modal6::-webkit-scrollbar,
.leaderboard-wrapper::-webkit-scrollbar,
.LevelPage::-webkit-scrollbar,
.containerRefPage::-webkit-scrollbar,
.main__content::-webkit-scrollbar {
  width: 8px;
  height: 30px;
}

::-webkit-scrollbar-track {
  background-color: var(--glass-card, rgba(35, 38, 48, .5));
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #00c6fb, #005bea);
  border-radius: 10px;
  width: 20px;
}

.app-wrapper .error-progress {
  background: var(--red-gradient, linear-gradient(180deg, #F48B6E 0%, #CB7662 100%));
  border-radius: 5px;
}

.app-wrapper .success-progress {
  background: var(--uncommon, linear-gradient(180deg, #A4D685 0%, #538E2E 100%));
  border-radius: 5px;
}



.app-wrapper {
  display: block;
  height: 100%;
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;

}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), linear-gradient(180deg, rgba(83, 93, 122, 0.3) 0%, rgba(67, 77, 103, 0.3) 100%);
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  width: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 10;

}

/* .fonWinterH {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  pointer-events: none;
} */
  /* winter off */
/* .winterFonMainStop {
  background: url(./img/image\ 201.webp);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
} */

/* .winterFonMain {
  background-image: url(/src/img/Group\ 691.webp);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;

} */

.video-background-stop {
  background-image: url(/src/img/BG\ \(3\).webp);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;

}

.video-background {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.video-background video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;
}


/* ru */
.btn__registe:lang(ru),
.first__card p:lang(ru),
.container__blur:lang(ru),
.popup__link:lang(ru),
.btnPoints:lang(ru),
.burn:lang(ru),
.placeholder:lang(ru),
.title__link:lang(ru),
.bottomContLavel:lang(ru),
.menu__item:lang(ru),
.headBurnSpn:lang(ru),
.u:lang(ru),
.btnNumberSpn:lang(ru),
.top__mobMenu:lang(ru),
.TimeStartBtn:lang(ru) #slots:lang(ru),
.headerLevel:lang(ru),
.bottom__mobMenu:lang(ru),
.btnNumberSpnTimer:lang(ru),
.costs:lang(ru),
.numberLeader:lang(ru),
.titleDay:lang(ru),
.titleCurrentBalance:lang(ru),
.degree:lang(ru),
.name__spinney:lang(ru),
.player__assets:lang(ru),
.spnWax:lang(ru),
.StopClaimDis:lang(ru),
.storBalance:lang(ru),
.FirstPage:lang(ru),
.blockRefMultip:lang(ru),
.fon__born__tocket:lang(ru),
.option__block:lang(ru),
.link_step:lang(ru),
.multLvl:lang(ru),
.ticket p:lang(ru),
.textMarket:lang(ru),
.BlockProgressInfo:lang(ru),
.option:lang(ru),
.btnProgress:lang(ru),
.choose__server:lang(ru),
.equals:lang(ru),
.spinAll button:lang(ru),
.name__state:lang(ru),
.expired:lang(ru),
.info__card__uncommon:lang(ru),
.bgRW:lang(ru),
.infoTopContent:lang(ru),
.title__slots:lang(ru),
.register:lang(ru),
.block__logo__premium:lang(ru),
.walletNameWax:lang(ru),
.block__logo:lang(ru),
.leftInfoJackpot:lang(ru),
.OfferTag__card:lang(ru),
.burnRunsCateg:lang(ru),
.claim__price:lang(ru),
.infoLeaderbord:lang(ru),
.textTicket:lang(ru),
.buttonRp:lang(ru),
.btnTicket:lang(ru),
.topNumberSpn:lang(ru),
.bgLevel:lang(ru),
.numWinTicket:lang(ru),
.winnerTicket:lang(ru),
.reg button:lang(ru),
.icon__spn__p:lang(ru),
.Registlist:lang(ru),
.title_purchase:lang(ru),
#stakeNft:lang(ru),
.cancelRuna:lang(ru),
.boxNumdLeader:lang(ru),
.contAttention:lang(ru),
.id__runs:lang(ru),
.AssedsCardMenuTop:lang(ru),
.titlePapBlock:lang(ru),
#indexCard:lang(ru),
.dropdown__optionsAtomic:lang(ru),
.Winnerslist:lang(ru),
.inf__num:lang(ru),
.rightInfoJackpot:lang(ru),
.titleBack:lang(ru),
.spnText:lang(ru),
.supply:lang(ru),
.DropSupply:lang(ru),
.stakeRuna:lang(ru),
.BlockMyAvailable:lang(ru),
.arag__modalRuna:lang(ru),
.title__modalRuna:lang(ru),
.Drop__menu__name:lang(ru),
.DropChances:lang(ru),
.option:lang(ru),
.Burned:lang(ru),
.Drop__card__content:lang(ru),
.inf__leaf__c:lang(ru),
.leaf__one__price:lang(ru),
.leaf__one__button:lang(ru),
.leaf__one__description:lang(ru),
.available:lang(ru),
.statusSpn:lang(ru),
.craft__menu button:lang(ru),
.btn__claim:lang(ru),
.text__boxIncNumber:lang(ru),
.bt__nav:lang(ru),
.title__s:lang(ru),
.rew:lang(ru),
.tokenDiscont:lang(ru),
.bt__nav a:lang(ru),
.nav a:lang(ru),
.menu__nav__bank:lang(ru),
.q:lang(ru),
.copy__link:lang(ru),
.btn__modal:lang(ru),
.nft__person_btn:lang(ru),
.item__nav:lang(ru),
p:lang(ru),
.total__add:lang(ru),
.btn:lang(ru),
.info__block:lang(ru),
.item:lang(ru),
.speed:lang(ru),
.bottom__menu:lang(ru),
.title:lang(ru),
.player:lang(ru),
.nav__content__link:lang(ru),
.titlef:lang(ru),
.price p:lang(ru),
.storage:first-child:lang(ru),
.playerSpin:lang(ru),
.parag__modalRuna:lang(ru),
.slots__block:lang(ru),
.contentText:lang(ru),
.sold__out span:lang(ru),
input:lang(ru),
.DropBuy:lang(ru),
.param:lang(ru),
.total__spn input:lang(ru),
.RefMenu:lang(ru),
.Drop__card__content:lang(ru),
.SpinSpid:lang(ru) {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 26px;
}

.titleWinner:lang(ru),
.RefMenu:lang(ru),
.sold__out span:lang(ru),
.storage:first-child:lang(ru) {
  font-size: 20px;
}

.runa__spn p:last-child:lang(ru),
.spin__spn p:last-child:lang(ru) {
  margin-top: 35px;
}

.runa__spn:lang(ru),
.spin__spn:lang(ru) {
  margin-bottom: 5px;
}

.btnNumberSpnTimer:lang(ru),
.priceRef:lang(ru),
.runa__spn p:first-child:lang(ru),
.spin__spn p:first-child:lang(ru),
.color__greey:lang(ru),
.coloro__g:lang(ru),
.color__pink:lang(ru),
.color__w:lang(ru),
.animNumberJack:lang(ru),
.color__b:lang(ru),
.icon__sum p:lang(ru) {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 62px;
}

.animNumberJack:lang(ru),
.Craft__price .icon__sum p:lang(ru),
.Drop__card .icon__sum p:lang(ru) {
  font-size: 25px;
  line-height: 40px;
}

.btnNumberSpn:lang(ru),
.btnNumberSpnTimer:lang(ru) {
  font-size: 25px;
  line-height: 50px;
}

/* modal */



@media (max-width: 740px) {
  .video-background {
    background-image: url(/src/img/BG\ \(3\).webp);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
  }

  .video-background video {
    display: none;
  }

}


.header-admin {
  z-index: 9999;
}

.adminwrap .wrapper {
  z-index: 9999;
}

.adminwrap>.container__header {
  display: none;
}

.header-admin .container__header {
  width: 100%;
}