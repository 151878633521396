.container__header {
  display: flex;
  justify-content: center;
  position: relative;
}

header {
  height: 98px;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);
  border-radius: 0px 0px 20px 20px;
  position: fixed;
  top: 0;
  z-index: 99;
}

.boxInform {
  height: 98px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  position: relative;
  top: 0;
  right: 248px;
  z-index: 99;
}

.header__logo {
  margin-left: 60px;
}

.shared__account {
  width: 155px;
  height: 18px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  line-height: 20px;
  text-align: center;
  margin-left: -9px;
  margin-bottom: 4px;
}

.wax__link {
  width: 156px;
  height: 20px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
  border-radius: 20px;
  line-height: 21px;
  text-align: center;
  padding-left: 10px;
}



.content__logo {
  position: relative;
  z-index: 1;
  display: flex;
  margin-top: 4px;
}

.lvl {
  background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
  backdrop-filter: blur(25px);
  border: none;
  border-radius: 26px;
  width: 44px;
  height: 18px;
  line-height: 22px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-right: 4px;
  cursor: pointer;
}

.spinner__card {
  position: absolute;
  background: linear-gradient(180deg, #2F3340 0%, #292C36 100%);
  border: 1px solid rgba(35, 38, 48, 0.8);
  width: 64px;
  height: 63px;
  border-radius: 50%;
  left: -44px;
  top: -46px;
}


.spinner__card__pro {
  position: absolute;
  background: #2E313D;
  border: 1px solid #F3A529;
  width: 64px;
  height: 63px;
  border-radius: 50%;
  left: -44px;
  top: -46px;
  z-index: 1;

  overflow: hidden;
}

.spinner__card__free {
  position: absolute;
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  width: 64px;
  height: 63px;
  border-radius: 50%;
  left: -44px;
  top: -46px;
  z-index: 1;
}


.fonTicketHeader {
  display: flex;
  justify-content: space-between;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));

}

.iconHeaderTicket {
  background: url(../../img/Group\ 480\ \(1\).svg);
  margin: 3px 0px 0px 5px;
}



.header__info__content .nambTicket {
  margin: 2px 9px 0px 0px;
}



.prem__infom,
.ticket__infom {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  height: 30px;
  width: 100px;
  box-sizing: border-box;
  color: #ffffff;
  line-height: 10px;
  font-size: 16px;
  text-align: start;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);
  font-family: 'Dongle';
  font-style: normal;
  font-weight: 300;
  z-index: 97;
  visibility: hidden;
  display: flex;
}

.prem__infom {
  right: -560px;
  top: 50px;
  padding: 6px 8px 2px 8px;

}

.ticket__infom {
  right: -560px;
  top: 10px;
  padding: 12px 8px 2px 8px;
}

.show {
  opacity: 1;
  visibility: visible;
  transition: 0.1s ease-in-out;
  transition-delay: 0.1s;

}

.iconHeaderPromo {
  background: url(../../img/Group\ 481.svg);
  margin: 3px 0px 0px 5px;
}

.iconHeaderTicket,
.iconHeaderPromo {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.conteinerHeaderRight {
  display: flex;
  width: 485px;
  justify-content: space-between;
  cursor: pointer;

}

.header__SpnToken .nambTicket {
  margin: 0 auto;
  line-height: 16px;
  align-items: center;
  font-size: 16px;
  background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.6;
  font-weight: 400;
  margin-top: 2px;
}

.header__SpnToken .price__coin {
  margin: 0 auto;

}

.header__info__content .fonTicketHeader:last-child {
  margin-top: 3px;
}

.shared__account__premium {
  display: flex;
  width: 133px;
  height: 18px;
  background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
  border-radius: 20px;
  position: relative;
  margin-bottom: 4px;
  right: 15px;
}


.mob__menu__container .shared__account__premium .prem__account {
  line-height: 18px;
}

#d {
  line-height: 21px;
  width: 56px;
  height: 18px;
}

.shared__account__premium .days {
  left: 126px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));

}

.shared__account__premium .icon__account {
  margin-left: 46px;
}


.icon__spinner__card {
  position: absolute;
  background: url(../../img/Spinner\ \(5\).svg);
  background-repeat: no-repeat;
  width: 54px;
  height: 55px;
  top: 5px;
  left: 5px;

}

.icon__spinner__pro {
  background: url(../../img/Ava\ Uncommon\ 1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  width: 66px;
  height: 66px;
  margin: -1px 0 0 -1px;
  border-radius: 50%;
}

.icon__settins {
  background: url(../../img/Group\ 111\ \(1\).svg);
}

.icon__menu__link {
  background: url(../../img/Group\ 112.svg);
}

.icon__map__link {
  background: url(../../img/Group\ 113.svg);
}

.icon__log_out {
  background: url(../../img/Group\ 114.svg);
}

.icon__refimg {
  background: url(../../img/Group\ 3.svg);
}

.icon__sound {
  background: url(../../img/Group\ 497.webp);
  background-size: 12px;
  background-position: center;
}

.icon__soundoff {
  background: url(../../img/Group\ 578.webp);
  background-size: 12px;
  background-position: center;
}

.icon__aminoff {
  background: url(../../img/vidOn.svg);
  background-size: 12px;
  background-position: center;
}

.activeStop {
  background: url(../../img/Group\ 477.svg);
  background-size: 12px;
  background-position: center;
}

.icon__volume {
  background: url(../../img/Group\ 577.webp)no-repeat;
  background-size: 10px;
  background-position: center;
}

.icon__volumeOff {
  background: url(../../img/Group\ 576.webp)no-repeat;
  background-size: 10px;
  background-position: center;
}

.icon__volume,
.icon__volumeOff,
.activeStop,
.icon__soundoff,
.icon__sound,
.icon__aminoff,
.icon__refimg,
.icon__settins,
.icon__menu__link,
.icon__map__link,
.icon__log_out {
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-position: center;
  position: relative;
}


.settins,
.menu__link,
.map__link,
.log_out {
  width: 18px;
  height: 18px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-right: 4px;
  cursor: pointer;
}


.header__cash {
  margin-right: 14px;
  width: 219px;
  height: 63px;
  border-radius: 10px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

.papBoxHeader:hover,
.header__cash:hover {
  transition: 0.1s;
  background: rgba(240, 128, 25, 0.90);
  border-radius: 13px;
  box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
}


/* mob menu */
.mob__menu__container {
  display: none;
  position: fixed;
  z-index: 100;
}

.BuySpn,
.DropBox {
  width: 80px;
  height: 63px;
  position: relative;

}

.mistery__box {
  width: 47px;
  height: 60px;
  background-size: 50px;
  background-image: url(../../img/Group\ 571.webp);
  background-repeat: no-repeat;
  position: absolute;
  top: 4px;
  right: 8px;
  z-index: 9;
}


.mob__menu__container .header__cash {
  margin: 0 auto;
}


.menu__mob {
  position: fixed;
  top: 0;
  right: -300px;
  width: 267px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(25px);
}

.menu__mob .shared__account__premium .icon__account {
  display: none;
}

.mob__menu__container .prem__account {
  margin: 0 auto;
}

.runa__spn p:last-child,
.spin__spn p:last-child {
  margin-top: 31px;
}

.menu__mob .icon__spinner__pro {
  top: 0;
  left: 0;
}

.menu__mob .icon__spinner__free {
  top: 0;
  left: 0;
}

.top__mobMenu .wax__link {
  line-height: 26px;
}

.menu__mob.open__mob {
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(25px);
  right: 0;
}

.mob__menu__container .prem__account {
  margin-top: 4px;
}

.menu-toggle__mob {
  position: fixed;
  top: 8px;
  right: 8px;
  margin: 0;
  padding: 10px;
}

.menu-items__mob {
  list-style: none;
  padding: 0;
  margin-top: 50px;
}

.menu-items__mob li {
  padding: 10px;
}

/* .BuySpn .price__coin {
  top: 6px;
  right: 5px;
  position: absolute;
} */

.mOpen {
  background-image: url(../../img/X\ \(5\).webp);
  background-repeat: no-repeat;
  background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
  background-position: center;
  backdrop-filter: none;
  border: none;
  border-radius: 50px;
  width: 40px;
  height: 40px;

}

header .price__coin {
  height: 23px;
  width: 23px;
  background-size: 30px;
}

.mClose {
  background-image: url(../../img/X\ \(6\).webp);
  background-repeat: no-repeat;
  background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
  background-position: center;
  backdrop-filter: none;
  border: none;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0;
  padding: 10px;

}

.boxLavel {
  display: flex;
  margin: 4px 0px 14px 0px;
}

.mob__menu__container .shared__account__premium,
.boxLavel,
.mob__menu__container .wax__link {
  width: 219px;
  height: 24px;
}

.mob__menu__container .shared__account__premium .icon__account {
  margin-left: 6px;
}

.mob__menu__container .icon__account {
  margin-top: 4px;
}


.mob__menu__container .prem__account {
  line-height: 26px;
}

.mob__menu__container .wax__link {
  padding-left: 0;
}

.mob__menu__container .spinner__card__pro {
  position: absolute;
  top: 58px;
  left: 107px;
}

.mob__menu__container .spinner__card__free {
  position: absolute;
  top: 58px;
  left: 107px;
}

.top__mobMenu {
  margin-top: 130px;
  margin-left: 24px;
}

.bottom__mobMenu .wax__link:not(:first-child) {
  margin-top: 4px;

}

.bottom__mobMenu .wax__link {
  display: flex;
  line-height: 26px;
  text-align: left;
}

.bottom__mobMenu {
  margin: 30px 0px 0px 24px;

}

.mob__menu__container .lvl {
  width: 52px;
  height: 24px;
  line-height: 26px;
}



.mob__menu__container .shared__account__premium {
  right: 0;
  top: 4px;
  margin-bottom: 8px;
}

.mob__menu__container .shared__account__premium .days {
  background: none;
  left: 165px;

}

.mob__menu__container .conteinerHeaderRight {
  width: 208px;
  margin: 0 auto;
  margin-top: 14px;
}

.mob__menu__container .icon__sum p {
  width: 58px;
}

.mob__menu__container .header__SpnToken .nambTicket {
  margin-top: 0;
}

.mob__menu__container .conteinerHeaderRight {
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.mob__menu__container .boxTicketHeader {
  margin-top: 15px;
}

.mob__menu__container .wallet__content {
  border-radius: 13px;
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 184px;
  height: 58px;
}

.mob__menu__container #d {
  line-height: 28px;
  color: #fff;
}

.vector_level {
  margin: 5px 7px 0px 26px;
  background-image: url(../../img/Group\ 400.webp);
  height: 14px;
  width: 14px;
}

.topLevel {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  line-height: 26px;
  width: 163px;
  height: 24px;
  border-radius: 15px;
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));

}

.top__mobMen {
  margin-top: 130px;
}

.bottom__mobMenu .settins,
.bottom__mobMenu .menu__link,
.bottom__mobMenu .map__link,
.bottom__mobMenu .log_out {
  margin: 3px 8px 0px 4px;
}

.bottom__mobMenu .wax__link {
  position: relative;
}

.bottom__mobMenu .vector_level {
  position: absolute;
  right: 0;
}

.header__SpnToken {
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
  margin-left: 6px;
  margin-top: 6px;
}


.nambTicket.droptext {
  height: 16px;
  width: 52px;
  position: absolute;
  transform: rotate(-90deg);
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
  top: 22px;
  right: 41px;
  line-height: 18px;
  color: var(--Text-2, #9AA0B0);
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 16px;
}

.papBoxHeader {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 208px;
  height: 58px;
  border-radius: 13px;
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.papBoxHeader .boxCurrentBalance {
  margin-right: 8px;
}

.boxTicketHeader {
  width: 66px;
  height: 58px;
  border-radius: 13px;
  border-radius: 13px;
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  cursor: pointer;
  transition: 0.1s;
}

.titleCurrentBalance {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 12px;
  text-align: center;

}

.boxCurrentBalance .nambTicket {
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  width: 50px;
  height: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 22px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
}

#activeBg {
  background: rgba(130, 193, 47, 0.80);
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0px rgba(130, 193, 47, 0.90), 0px 0px 7px 0px rgba(130, 193, 47, 0.80), 0px 0px 12px 0px rgba(130, 193, 47, 0.70), 0px 0px 20px 0px rgba(130, 193, 47, 0.50), 0px 0px 1.5px 0px #FFF;
}

#activeBg2 {
  border-radius: 13px;
  background: rgba(240, 128, 25, 0.80);
  box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
}

.boxTicketHeader:hover {
  transition: 0.1s;
  background: rgba(240, 128, 25, 0.90);
  box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
}

.titlePapBlock {
  position: absolute;
  left: -7px;
  top: 19px;
  font-weight: 400;
  transform: rotate(-90deg);
  width: 46px;
  height: 20px;
  text-align: center;
  line-height: 23px;
  border-radius: 13px;
  background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.iconTicketHeader {
  background: url(../../img/Ticket\ 700x500\ 1\ \(2\).webp);
  background-size: 100%;
  margin: 0 auto;
  width: 34px;
  height: 24px;
  margin-top: 4px;
  margin-bottom: 3px;
}

.boxTicketHeader .nambTicket {
  width: 50px;
  height: 20px;
  border-radius: 20px;
  background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  text-align: center;
  line-height: 22px;
  margin: 0 auto;
  font-size: 16px;
}

header .settins {
  margin-left: 21px;
}

@media (max-width: 1000px) {
  header {
    width: 800px;
  }
}


@media (max-width: 800px) {
  /* header {
    width: 600px;
  } */

  .content {
    margin-left: 0;
    margin-right: 0;
  }


  .mob__menu__container {
    display: block;
  }

  header {
    display: none;
  }

  .container__header {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
  }

  .header__SpnToken .price__coin {
    background-size: 30px;
  }



}

/* 
@media (max-width: 610px) {



} */