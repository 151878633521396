.LevelPage {
    margin-top: 116px;
    height: 100%;
}

.headerLevel {
    width: 784px;
    height: 51px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    border-radius: 10px;
    margin: 0 auto;
    font-size: 32px;
    text-align: center;
    line-height: 54px;
    margin-bottom: 16px;
    overflow: hidden;
}

.progressLevel {
    width: 784px;
    height: 106px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
}

.boxLevel {
    display: flex;
    width: 784px;
    height: 222px;
    margin: 0 auto;
    margin-top: 16px;

}



.rightBurnSpn {
    margin-left: 24px;
}

.bottomContLavel {
    width: 380px;
    height: 144px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    text-align: center;
}

.bottomContLavel p{
    width: 344px;
    height: 83px;
    line-height: 16px;
    margin: 0 auto;
    padding-top: 25px;
    color: var(--text-2, #9AA0B0);
}

.topContLevel {
    display: flex;
}

.levelFirst {
    position: relative;
    width: 88px;
    height: 70px;
    border-radius: 5px;
}

.levelSecond {
    position: relative;
    width: 90px;
    height: 70px;
    margin-left: 6px;
    border-radius: 5px;
}

.levelThird {
    position: relative;
    width: 190px;
    height: 70px;
    margin-left: 6px;
    border-radius: 5px;
}

.levelThird .icon__spn p {
    right: 10px;
    bottom: 21px;
    font-size: 20px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.levelSecond .title__link,
.levelFirst .title__link {
    width: 60px;
    font-size: 40px;
    font-weight: 400;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.boxLevel .refActiv,
.boxLevel .refCount,
.boxLevel .refNameLink {
    height: 70px;
}

.headBurnSpn {
    width: 180px;
    height: 40px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    border-radius: 10px;
    font-size: 32px;
    text-align: center;
}

.bottomBurnSpn {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 174px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-top: 8px;
}


.textBurnSpn p {
    color: var(--text-2, #9AA0B0);
    text-align: center;
    width: 134px;
    height: 23px;
    line-height: 13px;
    margin: 0 auto;
    padding-top: 30px;
}

.bottomBurnSpn #inp {
    display: flex;
    width: 140px;
    margin: 0 auto;
    margin-top: 27px;
    margin-bottom: 16px;
}

.burnNft p {
    font-size: 20px;
    line-height: 13px;
    text-align: center;
    width: 147px;
    height: 116px;
    padding-top: 15px;
}

.burn {
    width: 125px;
    height: 24px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    border: none;
    border-radius: 15px;
    line-height: 23px;
    margin: 0 auto;
}
.burnInput {
    position: relative;
}

.burnInput .icon__spn p{
    font-size: 20px;
}

.burnInput .icon__spn p {
    right: 25px;
    bottom: 31px;
}

.bgLevel {
    position: absolute;
    left: -22px;
    top: 26px;
    width: 66px;
    height: 18px;
    border-radius: 5px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    transform: rotate(-90deg);
    line-height: 19px;
    text-align: center;
}

.Burned {
    width: 76px;
    height: 66px;
    background-color: var(--20-dark, rgba(0, 0, 0, 0.20));
    background-image: url(../../img/Vector\ 415.webp);
    background-repeat: no-repeat;
    background-position: 46% 28%;
    border-radius: 5px;
    margin-top: 2px;
    margin-left: 2px;
   
}
.Burned p {
    padding-top: 16px;
    padding-left: 16px;
}
.levelFirst #referrals .NumLink {
    position: absolute;
    top: 9px;
    left: 31px;
}
.levelSecond #referrals .NumLink {
    position: absolute;
    top: 9px;
    left: 11px;
}

.levelThird #referrals .NumLink {
    width: 90px;
    font-size: 32px;
    position: absolute;
    top: 6px;
    left: 80px;
}
.levelThird .NumLink span {
display: block;
text-align: right;
}
.levelSecond .NumLink span {
display: block;
text-align: center;
}
.headProg {
    width: 700px;
    margin: 0 auto;
    position: absolute;
    top: 58px;
    left: 42px; 
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px; 
    overflow: hidden;

}

.btnProgress.btn__0,
.btnProgress.btn__1,
.btnProgress.btn__2,
.btnProgress.btn__3,
.btnProgress.btn__4,
.btnProgress.btn__5 {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    border: none;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    line-height: 17px;
    text-align: center;
    position: absolute;
    top: 43px;
    display: flex;
    justify-content: center;
}

.btn__0 {
    left: 24px;
}
.btn__1 {
    left: 23px;
}
.btn__2 {
    left: 23px;
}
.btn__3 {
    left: 23px;
}
.btn__4 {
    right: 23px;
}
.btn__5 {
    right: 23px;
}

.multLvl {
    background: url(../../img/Union\ \(2\).webp);
    background-repeat: no-repeat;
    width: 65px;
    height: 37px;
    position: absolute;
    top: 12px;
    left: 15px;

}

.multLvl p {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 10px;
    position: absolute;
    top: -9px;
    left: 12px;
    z-index: 99;


}
.multLvl span {
    position: absolute;
    font-size: 24px;
    text-transform: uppercase;
    top: 7px;
    left: 19px;
    z-index: 99;
}

.statusSpn p{
    position: absolute;
    top: 49px;
    left: 43px;
}
.statusSpn span{
    position: absolute;
    top: 83px;
    left: 38px;
    font-size: 16px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contMultInfo__0 {
    position: absolute;
}


.contMultInfo__3 .statusSpn p {
    left: 43px;
}
.contMultInfo__4 .statusSpn p {
    left: 43px;
}
.contMultInfo__5 .statusSpn p {
    left: 43px;
}
.contMultInfo__1 {
    position: absolute;
    left: 220px;
}
.contMultInfo__2 {
    position: absolute;
    left: 340px;
}
.contMultInfo__3 {
    position: absolute;
    left: 460px;
}
.contMultInfo__4 {
    position: absolute;
    left: 580px;
}
.contMultInfo__5 {
    position: absolute;
    left: 700px;
}

.contMultInfo__0 .statusSpn p {
    left: 43px;
}
/* 1 */
.contMultInfo__1 .statusSpn p {
    left: -51px;
}

.contMultInfo__1 .statusSpn span{
    left: -48px;
}

.contMultInfo__1 .multLvl {
    left: -69px;
}
/* 2 */
.contMultInfo__2 .statusSpn p {
    left: -32px;
}

.contMultInfo__2 .statusSpn span{
    left: -29px;
}

.contMultInfo__2 .multLvl {
    left: -51px;
}
/* 3 */
.contMultInfo__3 .statusSpn p {
    left: -14px;
}

.contMultInfo__3 .statusSpn span{
    left: -10px;
}

.contMultInfo__3 .multLvl {
    left: -33px;
}
/* 4 */
.contMultInfo__4 .statusSpn p {
    left: 3px;
}

.contMultInfo__4 .statusSpn span{
    left: 8px;
}

.contMultInfo__4 .multLvl {
    left: -15px;
}
/* 5 */
.contMultInfo__5 .statusSpn p {
    left: 21px;
}

.contMultInfo__5 .statusSpn span{
    left: 25px;
}

.contMultInfo__5 .multLvl {
    left: 3px;
}
.mobProgLevel{
    display: none;
}
.DtProgress{
    display: block;
}

.headbody {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.btnProgress.btn__0.activ {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.btnProgress.btn__1.activ {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.btnProgress.activ {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}


.multLvl.activ {
    background: url(../../img/Union\ \(3\).webp);
    background-repeat: no-repeat;
}

.headbody.mobBodyProg{
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.mobBodyProg{
    display: none;
}

.containerBurn{
    display: flex;
    justify-content:space-between;

}

@media (max-width: 785px) {
    .containerBurn{
        display: flex;
        justify-content:space-between;

    }

    .LevelPage {
        height: 100%;
        overflow-y: scroll;
    }

    .DtProgress {
        display: none;
    }

    .mobBodyProg{
        display: block;
    }

    .LevelPage {
        margin-top: 40px;
    }
    .mobProgLevel{
        display: block;
    }

    .boxLevel {
        display: flex;
        flex-direction: column;
        width: 380px;
        height: 100%;
        margin: 0 auto;
        margin-top: 30px;
    
    }
    .levelFirst #referrals .NumLink {
        top: -3px;
        left: 10px;
    }
    .levelSecond #referrals .NumLink {
        top: -3px;
        left: 13px;
    }
    
    .levelThird #referrals .NumLink {
     
        top: -3px;
        left: 83px;
    }
    .headerLevel{
        width: 380px;
    }

    .progressLevel{
        width: 380px;
    }

    .headProg {
        width: 338px;
        border-top-left-radius: 6px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px;
    }

    .rightBurnSpn {
        margin-left: 0;
        margin-top: 30px;
    }

    .contMultInfo__3 {
        position: absolute;
        left: 63px;
        top: 114px;
        z-index: 99;
    }

    .contMultInfo__4 {
        position: absolute;
        left: 178px;
        top: 114px;
        z-index: 99;
    }

    .contMultInfo__5 {
        position: absolute;
        left: 300px;
        top: 114px;
        z-index: 99;
    }

    

    .mobProgLevel {
        margin-top: 8px;
    }

    .mobProgLevel .headProg {
        left: 1px;
        border-top-left-radius: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }

    .headbody {
        border-top-left-radius: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px;
    }

}
