#Liquidity .titLeft {
    width: 124px;
    margin-left: 14px;
}

.logoWaxSpn {
    background: url(../../img/Group\ 777.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 80px;
    height: 60px;
    margin-left: 10px;
    margin-top: 5px;
}

#Liquidity .cardPartFirst {
    margin-top: 20px;
}

.boxIcCards {
    width: 136px;
    height: 190px;
    background-size: 100%;
    position: absolute;
    margin: 0;
    top: -12%;
    left: 25%;
    transform: translate(-12%, -25%);
    z-index: 2;
    transform: rotate(5deg);
}


#Liquidity .btnRig {
    width: 200px;
    height: 36px;
    margin-right: 19px;
    margin-left: 0;
}

#Liquidity .cardPartFirst {
    height: 364px;
}

.cardPartFirstWrap {
    width: 25%;
}

@media (max-width:800px) {
    .cardPartFirstWrap {
        width: 100%;
        text-align: center;
    }

    .cardPartFirstWrap .cardPartFirst {
        display: inline-block;
    }
}

.boxlw {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

/*  */

#Liquidity .bottomCardFirst {
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 120px;
    border-radius: 20px;

}



.topC {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    width: 228px;
    height: 40px;
    border-radius: 30px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    flex-wrap: wrap;

}

.topC .userPart {
    margin-left: 0px;
    width: 140px;
}

.clBtn {
    width: 78px;
    height: 24px;
    border-radius: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.clBtn.bt-only:hover {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    animation: shadow-pulse 1s infinite;
    cursor: pointer;
}

.botC {
    width: 228px;
    height: 62px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
}

.boxDays {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 78px;
    height: 62px;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.boxDays .pt:first-child {
    font-size: 20px;
    color: #9AA0B0;
}

.boxDays .pt:last-child {
    font-size: 48px;
    font-weight: 900;
}

.BoxTokens {
    display: flex;
    flex-direction: column;
}

.BoxTokens .pt {
    display: flex;
    text-align: end;
    line-height: 18px;

}

.BoxTokens .pt:first-child {
    font-size: 20px;
    color: #9AA0B0;
    text-align: start;
}

.iconWp {
    background: url(../../img/image\ 205.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 12px;
    height: 13px;
    margin-right: 5px;
}

.iconSp {
    background: url(../../img/cot.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 20px;
    margin-left: -3px;
    margin-right: 2px;
}

@media (max-width: 800px) {

    #Liquidity .centerContentPartners {

        max-width: 320px;

    }

    #Liquidity .boxlw {
        align-items: center;
        flex-direction: column;

    }

    #Liquidity .btnRig {
        margin-right: 0;
    }

    #Liquidity .titLeft {
        margin-left: 0;
    }

    .logoWaxSpn {
        margin-left: 0;
    }
}