.Drop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 784px;
    margin: 0 auto;
    /* padding-bottom: 139px; */
    height: 100%;
}

.DROP1 {
    margin-top: 40px;

}

.Drop .Drop__card:not(:last-child) {
    margin-right: 24px;
}

.Drop__card {
    margin: 0 auto;
    width: 380px;
    text-align: center;
}

.Drop__menu__name {
    width: 380px;
    height: 51px;
    border-radius: 10px;
    text-align: center;
}

.DropTitle {
    font-size: 32px;
    line-height: 55px;
}



.Drop__card__content {
    display: flex;
    flex-direction: row;
    width: 380px;
    height: 480px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;


}

.Drop__icon__leaf {
    background-image: url(../../img/238Leaflynn2.webp);
    background-repeat: no-repeat;
    height: 238px;
    width: 170px;
    margin-top: -7px;

}


.Drop__card .Group {
    margin-top: -7px;

}
.block__pack {
    margin-top: -7px;
}

.DropSupply,
.DropSold,
.DropAvailable {
    display: flex;
    justify-content: space-between;
    width: 130px;
    height: 20px;
    
   
}

.DropBuy {
    width: 170px;
    height: 20px;
    line-height: 22px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    margin-top: 8px;


}



.DropSupply div:first-child,
.DropSold div:first-child,
.DropAvailable div:first-child {
    font-size: 16px;
    margin-left: 8px;
    line-height: 23px;
}

.available__one,
.sold,
.supply {
    margin-right: 8px;
    line-height: 23px;
}

.block__pack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 107px;
    border-radius: 5px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    margin-right: 18px;
    /* margin-bottom: 39px; */

}

.Drop__card #inf__num{
    background: none;
}

.Drop__card .leaf__one__description {
    width: 130px;
    height: 238px;
    margin-top: -7px;
    margin-left: 0;
    margin-right: -22px;
    background: none;
}

.AssedsCardMenubottom .Craft__price,
.Drop__card .Craft__price {
    width: 170px;
    height: 49px;
}

.Craft__price .icon__sum p,
.Drop__card .icon__sum p {
    font-size: 36px;
    font-weight: 400;
    width: 110px;
    bottom: 52px;
    left: 24px;
}

.AssedsCardMenubottom .icon__spn__p p,
.Drop__card .icon__spn__p p {
    font-size: 20px;
    right: 0px;
    top: -37px;

}

.AssedsCardMenubottom .icon__spn__p p{
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-weight: 400;
}

.disab {
    animation: none;
    background: rgba(0,0,0,.2);
    box-shadow: none;
    pointer-events: none;
}



.AssedsCardMenubottom .icon__modal,
.Drop__card .icon__modal {
    right: -55px;
}

.DROP1 .Craft__price .icon__sum p,
.Drop__card .icon__sum p {
    width: 90px;

}


.Drop__card .inf__leaf__c {
    margin-top: 0;
    border: none;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
}

.Drop__card .xruns {
    margin-top: 2px;
    background: linear-gradient(180deg, #BE76E4 0%, #6D4AAC 100%);
}


.Drop__card #inf__num,
.inf__leaf__c {
    width: 100px;
    /* height: 20px; */
}

.Drop__card #inf__num {
    width: 130px;
    height: 20px;
    border-radius: 0px;
    margin-top: 0;
}

.block__pack .title__leaf {
    line-height: 22px;
}

.Drop__card .DropSupply,
.DropSold,
.DropAvailable {
    margin-top: 0px;
}

.Drop__card .DropBuy {
    margin-top: 8px;
}

.Drop__card .multi_leaf {
    font-size: 20px;
    margin-top: 0px;
    line-height: 27px;
}

.block__contains .title__leaf{
    margin-top: 9px;
}

.Claims .available__one {
    margin-right: 6px;
}

/* отображается у тех кого нет в белом листе  */
.notWhiteList,
.notWhiteList.no {
    border-radius: 0px 0px 10px 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    line-height: 20px;
}

.notWhiteList.no span {
    background: var(--Red-Gradient, linear-gradient(180deg, #F48B6E 0%, #CB7662 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
}

#wList.notWhiteList {
    background-color: var( rgba(0, 0, 0, 0.20));
} 

.notWhiteList span {
    color: var(--d-9-d-9-d-9, #82D784);
    font-size: 16px;
}

.notWhiteList {
    width: 380px;
    height: 20px;
    border-radius: 0px 0px 10px 10px;
    background-color: var( rgba(0, 0, 0, 0.20));
    bottom: 0;
    position: absolute;
    line-height: 20px;   
}

.notWhiteList  {
 
    background: var( rgba(0, 0, 0, 0.20));
}


.InfinityBoxIcon,
.MysteryBoxIcon,
.StarterPackIcon {
    height: 225px;
    width: 207px;
    background-size: 100%;
    margin-left: 7px;
    border-radius: 10px;
    position: relative;
    left: 22px;
   
    
}

.InfinityBoxIcon{
    background-image: url(../../img/Group\ 572.webp);
    background-repeat: no-repeat;
 
   
}

.inf__leaf__c.violetta {
    background: linear-gradient(180deg, #BE76E4 0%, #6D4AAC 100%);
    bottom: 1px;
    position: relative;
}

.MysteryBoxIcon {
    background-image: url(../../img/Group\ 571.webp);
    background-repeat: no-repeat;
 
}

.StarterPackIcon {
    background-image: url(../../img/Group\ 573.webp);
    background-repeat: no-repeat;
}

.fonMystery {
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    box-shadow: 0px 0px 30px 0px #F29723 inset;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(7.5px);
    position: relative;
}

.fonStarter{
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    box-shadow: 0px 0px 30px 0px #69CDFF inset;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(7.5px);
    position: relative;
}

.fonInfinity {
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    box-shadow: 0px 0px 30px 0px #9F2BAD inset;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(7.5px);
    position: relative;
}
.infinix,
.starter,
.mistery {
    font-family: 'Rubik', sans-serif;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
}

.infinix {
    text-shadow: 0px 0px 1.565999984741211px #F6CD3A, 0px 0px 3.131999969482422px #B542C0, 0px 0px 10.961999893188477px #B542C0, 0px 0px 21.923999786376953px #B542C0, 0px 0px 37.58399963378906px #B542C0, 0px 0px 65.77200317382812px #B542C0;
}
.starter {
    text-shadow: 0px 0px 1.565999984741211px #F6CD3A, 0px 0px 3.131999969482422px #69CDFF, 0px 0px 10.961999893188477px #69CDFF, 0px 0px 21.923999786376953px #69CDFF, 0px 0px 37.58399963378906px #69CDFF, 0px 0px 65.77200317382812px #69CDFF;
}
.mistery {
    text-shadow: 0px 0px 1.565999984741211px #F6CD3A, 0px 0px 3.131999969482422px #F29723, 0px 0px 10.961999893188477px #F29723, 0px 0px 21.923999786376953px #F29723, 0px 0px 37.58399963378906px #F29723, 0px 0px 65.77200317382812px #F29723;
}

.InfinityTitle {
    background: url(../../img/Group\ 545\ \(6\).webp);
    top: -40px;
}

.MysteryTitle {
    background: url(../../img/Group\ 545\ \(5\).webp);
    top: -39px;
}

.StarterTitle {
    background: url(../../img/Group\ 545\ \(4\).webp);
    top: -38px;
}

.DROP1 .inf__leaf__c {
    line-height: 22px;
}

.StarterTitle,
.InfinityTitle,
.MysteryTitle {
    position: absolute;
    width: 345px;
    height: 142px;
    background-repeat: no-repeat;
    background-size: 300px;
    
    left: 38px;
    position: absolute;
   
}


.Drop__card__content {
    position: relative;
    z-index: 1;
}

.total__add {
    width: 122px;
}

.Claims {
    display: flex;
}

.Claims .DropAvailable {
    width: 104px;
    margin-top: 8px;
    margin-right: 2px;
}

.Claims .plus {
    margin-left: 2px;
}

.Claims .minus {
    margin-right: 2px;
}

.Drop__card .box__slots {
    margin-top: 8px;
    margin-bottom: 0;
}



.discontIconDrop {
    background: url(../../img/Group\ 441\ \(1\)\ \(1\).webp);
    height: 37px;
    width: 60px;
    position: absolute;
    top: 333px;
    transform: rotate(270deg);
    right: 133px;
}
/* ------ */
.sale__closed,
.sold__out {
    width: 380px;
    height: 480px;
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(2px);
    position: absolute;
    z-index: 10;
    border-radius: 10px;
}
.sale__closed span,
.sold__out span{
    text-align: center;
    line-height: normal;
    font-style: italic;
    text-transform: uppercase;
    display: flex;
    transform: rotate(-15deg);
    flex-direction: column;
    justify-content: center;
    margin-top: 205px;
}
.sold__out span{
    text-shadow: 0px 0px 10.961999893188477px #82C12F, 0px 0px 21.923999786376953px #82C12F, 0px 0px 37.58399963378906px #82C12F, 0px 0px 65.77200317382812px #82C12F;
}

.sale__closed span{
    text-shadow: 0px 0px 10.961999893188477px #F29723, 0px 0px 21.923999786376953px #F29723, 0px 0px 37.58399963378906px #F29723, 0px 0px 65.77200317382812px #F29723;
}
.Drop__card .total__add {
    line-height: 25px;
}
.Drop__card .box__slots span:first-child {
    font-weight: 400;
}


.discontIconDrop span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 46px;
}

.timer__block__drop {
    display: flex;
    justify-content: space-between;
    width: 380px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px 10px 0px 0px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}
.state__drop {
    width: 190px;
    line-height: 55px;
    /* margin-left: 0px; */
}

.state__drop:first-letter {
    text-transform: uppercase;
}

.timer__drop {
    width: 190px;
    font-size: 20px;
    font-weight: 900;
    line-height: 55px; 
    margin-right: 31px;
}

.block__contains {
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    width: 130px;
    height: 90px;
    margin-bottom: 20px;
}


.block__chances {
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    width: 130px;
    height: 127px;
}
.block__chances #inf__num:last-child,
.block__pack .DropAvailable:last-child{
    border-radius: 0px 0px 5px 5px;
}

.block__chances__fon #inf__num:nth-child(1),
.block__chances__fon #inf__num:nth-child(3),
.block__chances__fon #inf__num:nth-child(5) {
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.fonDrop {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

@media (max-width: 1200px) {
    .Drop {
        flex-direction: column;
        width: 100%;

    }
    .Drop .Drop__card:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;

    }
    .Drop__card {
        margin: 0;
    }
    .Drop .Drop__card:last-child {
        margin-bottom: 140px;
    }
}

@media (max-width: 768px) {
   
   
   

    

}

@media (max-width: 610px) {

    .sale__closed{
        width: 300px;
    }
    .sale__closed span{
       font-size: 30px;
    }

    .discontIconDrop {
        top: 327px;
        right: 109px;
    }

    .StarterTitle, 
    .InfinityTitle {
        left: 4px;
    }

    .MysteryTitle {
        left: 4px;
    }

    
    .InfinityBoxIcon,
    .MysteryBoxIcon,
    .StarterPackIcon {
        height:150px;
        width: 150px;
        margin-left: 7px;
        left: 0px;
        top: 30px;
 
    }

   

    .InfinityBoxIcon{
        background-image: url(../../img/InfinityBox1\ \(1\).webp);  
    }

    .MysteryBoxIcon {
        background-image: url(../../img/MysteryBox1\ \(1\).webp);
    }

    .StarterPackIcon {
        background-image: url(../../img/StarterBox1\ \(1\).webp);
    }

    .timer__block__drop {
        width: 300px;
   
    }
    .Drop__menu__name {
        width: 300px;
        margin: 0 auto;

    }


    .Drop__card #inf__num{
        width: 100px;
    }

    .DropSupply, .DropSold, .DropAvailable {
        width: 100px;
    }
    .Drop__card__content {
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
    }

    .Drop__card .leaf__one__description {
        margin-right: 0;
        width: 110px;
    }

    .block__pack {
        margin-right: 7px;
    }


    .AssedsCardMenubottom .Craft__price,
    .Drop__card .Craft__price {
        width: 160px;
    }

    .AssedsCardMenubottom .icon__modal,
    .Drop__card .icon__modal {
        right: -48px;

    }

    .Craft__price .icon__sum p,
    .Drop__card .icon__sum p {
        left: 16px;
    }

    .AssedsCardMenubottom .icon__spn__p p,
    .Drop__card .icon__spn__p p {
        right: 7px;
    }




    .block__pack {
        width: 100px;
    }
    .block__chances,
    .block__contains {
        width: 100px;
    }
    .Drop__card .inf__leaf__c {
        width: 90px;
    }

    .Drop__card .leaf__one__description {
        margin-top: -12px;
    }

    .block__contains {
        margin-bottom: 8px;
    }
    .Drop__card .Group,
    .block__pack {
        margin-top: -20px;
    }

    .notWhiteList {
        width: 300px;
        height: 20px;
        line-height: 18px;
    }
}

@media (max-width: 320px) {}