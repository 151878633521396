.container__footer {
  height: 98px;
  width: 1000px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);
  border-radius: 20px 20px 0px 0px;

}

.bottom__nav {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
}



.container__footer .menu__item {
  text-transform: uppercase;
}

.block__footer {
  display: flex;
  /*  */
  position: relative;
  margin-top: 12px;
}

.menu__item {
  /* width: 290px; */
  width: 73px;
  height: 74px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
}


.menu__item div {
  display: block;
}

.active {
  position: relative;
  z-index: 2;
  color: #2D313D;
}

.icon__craft,
.icon__bank,
.icon__spin {
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 auto;
}

.active .icon__craft,
.active .icon__bank,
.active .icon__spin {
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 auto;
}

.nav__top__footer {
  padding-top: 15px;
}

.icon__craft {
  background-image: url(/src/img/craft.svg);
  background-position: center;
  height: 28px;
  width: 28px;
}

.icon__spin {
  background-image: url(/src/img/Group\ 88\ \(1\).svg);
  background-position: center;
  height: 28px;
  width: 28px;
}

.icon__bank {
  background-image: url(/src/img/bank.svg);
  background-position: center;
  height: 28px;
  width: 30px;
}

.icon__chance {
  background-image: url(/src/img/Group\ 90.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  margin: 0 auto;
}

.icon__leader {
  background-image: url(/src/img/Group\ 90.webp);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
  margin: 0 auto;
  position: relative;
}

.icon__free {
  background-image: url(/src/img/Group\ 677.webp);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
  margin: 0 auto;
  position: relative;
}

.icon__partners {
  background-image: url(/src/img/Group\ 742\ \(2\).webp);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
  margin: 0 auto;
  position: relative;
}

.icon__burn {
  background-image: url(/src/img/saleburn/burnsale-ico.svg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 21px;
  margin: 0 auto;
  position: relative;
}

.icon__liq {
  background-image: url(/src/img/liq-def.svg);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 22px;
  margin: 0 auto;
  position: relative;
}


.active .icon__craft {
  z-index: 2;
  background-image: url(/src/img/Group\ 86\ \(1\).svg);
  height: 28px;
  width: 28px;
}

.active .icon__spin {
  z-index: 2;
  background-image: url(/src/img/Group\ 89.svg);
  height: 28px;
  width: 28px;
  z-index: 5;
}

.active .icon__bank {
  z-index: 2;
  background-image: url(/src/img/g87.svg);
  height: 28px;
  width: 28px;
}

.active .icon__chance {
  z-index: 2;
  background-image: url(/src/img/Group\ 90\ 2.svg);
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
}

.active .icon__leader {
  z-index: 2;
  background-image: url(/src/img/Group\ 91.webp);
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
}

.active .icon__free {
  z-index: 2;
  background-image: url(/src/img/Group\ 677\ \(1\).webp);
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
}

.active .icon__partners {
  z-index: 2;
  background-image: url(/src/img/Group\ 742\ \(3\).webp);
  background-repeat: no-repeat;
  height: 28px;
  width: 24px;
}

.active .icon__burn {
  z-index: 2;
  background-image: url(/src/img/saleburn/burnsale-ico-act.svg);
  background-repeat: no-repeat;
  height: 28px;
  width: 21px;
}

.active .icon__liq {
  z-index: 2;
  background-image: url(/src/img/liq-act.svg);
  background-repeat: no-repeat;
}

.menu__bg {
  position: absolute;
  bottom: 12px;
  left: 0px;
  height: 74px;
  width: 75px;
  background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
  border-radius: 5px;
  transition: left 0.3s ease-in-out;
  z-index: 0;
}

.infoBtnLeader {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  right: -20px;
  top: -10px;

}

.activeBgTic {
  background: rgba(130, 193, 47, 0.80);
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0px rgba(130, 193, 47, 0.90), 0px 0px 7px 0px rgba(130, 193, 47, 0.80), 0px 0px 12px 0px rgba(130, 193, 47, 0.70), 0px 0px 20px 0px rgba(130, 193, 47, 0.50), 0px 0px 1.5px 0px #FFF;
}

.infoBtnLeader {
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px rgba(130, 193, 47, 0.90), 0px 0px 7px 0px rgba(130, 193, 47, 0.80), 0px 0px 12px 0px rgba(130, 193, 47, 0.70), 0px 0px 20px 0px rgba(130, 193, 47, 0.50), 0px 0px 1.5px 0px #FFF;
  animation: pulse 3s infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.icon__free .infoBtnLeader,
.icon__chance .infoBtnLeader,
.icon__craft .infoBtnLeader,
.icon__spin .infoBtnLeader,
.icon__bank .infoBtnLeader {
  display: none;
}

.active .icon__leader .infoBtnLeader.activeBgTic {
  display: none;
}

@media (max-width: 1000px) {
  .container__footer {
    width: 800px;
  }
}


@media (max-width: 800px) {
  .container__footer {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .container__footer {
    width: 500px;
    padding: 0;
  }

  .header__cash {
    margin: 0 auto;
  }

  .block__footer {
    overflow-x: scroll;
  }


  .infoBtnLeader {
    width: 18px;
    height: 18px;
    top: -1px;
  }

}

@media (max-width: 500px) {
  .container__footer {
    padding-left: 3px;
    padding-right: 3px;
    width: 380px;
  }


}