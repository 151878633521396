.FirstPage__content {
    width: 380px;
    height: 460px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

}

.FirstPage__content .playerSpin {
    height: 35px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 9px;

}




.Step1 {

    background-color: rgba(0, 0, 0, 0.3);
    /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25); */
    border-radius: 20px;
    border: none;
    color: #F1841B;
    width: 80px;
    height: 30px;
    margin: 0 auto;

}

.link_step {
    color: #F1841B;
}

.Step2 {
    background-image: url(../../img/Group\ 148\ \(1\).svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.3);
    /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25); */
    border-radius: 50px;
    border: none;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.Step3 {
    background-image: url(../../img/Group\ 149\ \(1\).svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.3);
    /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25); */
    border-radius: 50px;
    border: none;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    cursor: pointer;

}
.Step1:hover{
    background-color:rgba(242, 152, 35, 1);
}

.Step2:hover{
    background-color:rgba(242, 152, 35, 1);
    background-image: url(../../img/1-1.webp);
    background-repeat: no-repeat;
    background-position: center;

}
.Step3:hover {
    background-color:rgba(242, 152, 35, 1);
    background-image: url(../../img/2-2.webp);
    background-repeat: no-repeat;
    background-position: center;
}
.Step1:hover .link_step,
.link_step:hover {
    color: #2C303C;
}

.FirstPage {
    display: flex;
    flex-direction: column;
    width: 220px;

}
/* 
.FirstPage__block .FirstPage:first-child {
    margin-top: 32px;

} */

.FirstPage__block {
    text-align: center;
    width: 220px;
    height: 388px;
}

.FirstPage .title__time {
    width: 220px;
    margin-bottom: 0;
    line-height: 21px;
    color: var(--text-2, #9AA0B0);

}
.title__time:first-child {
    margin-top: 32px;
}
/* .Step1,
.Step2,
.Step3 {
    margin-top: 10px;
  
} */


@media (max-width: 610px) {
    .FirstPage__content {
        max-width: 300px;
    }

}

