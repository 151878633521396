

.wrapper .jackpot-top {
    background-image: url(../../img/er.webp);
    -webkit-backdrop-filter: blur(2.5px);
    background-repeat: no-repeat;
    backdrop-filter: blur(2.5px);
    background-size: cover; 
    background-position: center;
    width: 100%;
    height: 100%;
}


.jackpot-main {
    background-color: rgba(0, 0, 0, 0.60);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

} 

.centerBlockJack {
   padding-top: 40px;
}
.jackpot-main::-webkit-scrollbar{
    width: 8px;
    height: 30px;
}

.jackpot-main::-webkit-scrollbar-track{
	background-color: var(--glass-card,rgba(35,38,48,.5));
	border-radius: 10px;
	-webkit-box-shadow: 5px 5px 5px -5px rgba(34,60,80,.2) inset;
}
.jackpot-main::-webkit-scrollbar-thumb{
	background: linear-gradient(180deg,#00c6fb,#005bea);
	border-radius: 10px;
	width: 20px;
}
.jackpot-main  .circleFon {
    top: -30px;
    height: 100%;
}
.title-jackpot {
    background: url(../../img/Jackpot\ \(2\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 123px;
    height: 38px;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}
.btnNumberSpn {
    display: flex;
    justify-content: center;
    width: 340px;
    height: 60px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    box-shadow: 0px 0px 30px 0px rgba(246, 205, 58, 0.80) inset, 0px 0px 20px 0px rgba(240, 128, 25, 0.80) inset, 0px 0px 10px 0px rgba(159, 43, 173, 0.80) inset;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(7.5px);
    border-radius: 40px;
    border: none;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    line-height: 62px;
    text-shadow: 0px 0px 10.961999893188477px #CF8B3B, 0px 0px 21.923999786376953px #CF8B3B, 0px 0px 37.58399963378906px #CF8B3B, 0px 0px 65.77200317382812px #CF8B3B;
}




.jackpot-coin {
    width: 300px;
    height: 300px;
    background: url(../../img/coinJeck.webp);
    background-repeat: no-repeat;
    margin: 0 auto;
    background-size: 120%;
    background-position-x: center;
    background-position-y: center;
    z-index: 3;
    margin-top: 23px;
}
.btnNumberSpnTimer {
    display: flex;
    justify-content: center;
    width: 260px;
    height: 50px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    box-shadow: 0px 0px 30px 0px rgba(246, 205, 58, 0.80) inset, 0px 0px 20px 0px rgba(240, 128, 25, 0.80) inset, 0px 0px 10px 0px rgba(159, 43, 173, 0.80) inset;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    backdrop-filter: blur(7.5px);
    border-radius: 40px;
    border: none;
    margin: 0 auto;
    border: none;
    margin-top: 40px;
    text-align: center;
    line-height: 48px;
    text-shadow: 0px 0px 10.961999893188477px #CF8B3B, 0px 0px 21.923999786376953px #CF8B3B, 0px 0px 37.58399963378906px #CF8B3B, 0px 0px 65.77200317382812px #CF8B3B;
}

.topNumberSpn {
    text-align: center;
    text-shadow: 0px 0px 10.961999893188477px #CF8B3B, 0px 0px 21.923999786376953px #CF8B3B, 0px 0px 37.58399963378906px #CF8B3B, 0px 0px 65.77200317382812px #CF8B3B;
    font-size: 32px;
    font-style: normal;
    line-height: 48px;
    text-transform: uppercase;
}


.btnNumberSpnTimer:hover {
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    box-shadow: 0px 0px 30px 0px rgba(246, 205, 58, 0.80) inset, 0px 0px 20px 0px rgba(240, 128, 25, 0.80) inset, 0px 0px 10px 0px rgba(159, 43, 173, 0.80) inset;
    backdrop-filter: blur(7.5px);
    animation: shadow-pulse-hover 1s infinite;
    cursor: pointer; 
}

@keyframes shadow-pulse-hover {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
        
    }

    70% {
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}

.btnNumberSpnTimer.disab{
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    box-shadow: 0px 0px 10px 0px #F0821A inset;
    backdrop-filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
}

.contentCenterJackpot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1300px;
}
.boxRightFitstJackpot,
.boxRightSecondJackpot,
.boxLeftFitstJackpot {
    border-radius: 10px;
    width: 380px;
    height: 128px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
}
.boxRightSecondJackpot {
    margin-top: 8px;
    height: 116px;
}
.boxRightFitstJackpot{
    height: 100px;
}

.boxLeftJackpot {
    display: flex;
    align-items: center;
    width: 380px;
    height: 40px;
    background: var(--Glass-Card, rgba(35, 38, 48, 0.50));
    margin-top: 8px;
    border-radius: 5px;

}

.jackpotRightBlock {
    overflow-x: hidden;
    overflow-y: auto;
    height: 95px;
    width: 385px;
}
.jackpotRightBlock::-webkit-scrollbar{
    width: 3px;
    height: 15px;
}

::-webkit-scrollbar-track{
	background-color: var(--glass-card,rgba(35,38,48,.5));
	border-radius: 10px;
	-webkit-box-shadow: 5px 5px 5px -5px rgba(34,60,80,.2) inset;
}
::-webkit-scrollbar-thumb{
	background: var(--glass-card,rgba(56, 59, 69, 0.5));
	border-radius: 10px;
	width: 20px;
}

.boxRightSecondJackpot span,
.boxLeftFitstJackpot span {
    display: block;
    width: 288px;
    line-height: 16px;
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    margin: 0 auto;
    padding-top: 18px;
}

.boxRightSecondJackpot span{
    padding-top: 25px;
}
.boxRightSecondJackpot span{
    width: 332px;
}




.leftJ {
    width: 120px;
    text-align: center;
    margin-right: 120px;
    margin-left: 30px;
}

.rightJ {
    text-align: center;
    width: 81px;

}
.rightJ .linkLeader,
.leftJ .linkLeader {
    margin-left: 4px;
    font-weight: 400;

}

.centerJ {
    width: 100px;
    text-transform:initial;
    margin-left: 30px;
}

.boxRightFitstJackpot div {
    text-align: center;
   
}

.boxRightFitstJackpot .boxStor {
    height: 50px;
    width: 200px;
}

.rightInfoJackpot .leftJ .linkLeader {
    text-transform: capitalize;
}

.boxRightFitstJackpot .rightIconSpn {
    width: 50px;
    height: 50px;
    background-image: url(../../img/icon__purchase.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 14px 17px;
    margin-right: 0;
}
.boxRightFitstJackpot .storBalance {
    background: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.boxRightFitstJackpot .storBalance,
.boxRightFitstJackpot .spnText {
    text-align: right;
   
}
.boxRightFitstJackpot  .leftAllStop {
   margin-right: 6px;

}

.rightInfoJackpot .leftJ  {
    width: 120px;
    margin-right: 0px;

}
.contentCenterJackpot {
    margin: 0 auto;

   
}
.centerBlockJack {
    margin-bottom: 140px;
}





@media (max-width: 1330px) {
    .contentCenterJackpot {
        display: flex;
        width: 300px;
        flex-direction: column;
        align-items: center;
    }
    .btnNumberSpnTimer {
        margin-bottom: 170px;
    }

  
    

   

    
}


@media (max-width: 1200px) {
    .contentCenterJackpot {
        display: flex;
        width: 300px;
        flex-direction: column;
        align-items: center;
    }

    .contentCenterJackpot {
        margin-top: 30px;
        margin-bottom: 30px;
   
    }

    .centerBlockJack{
        padding-top: 40px;
    }
    .jackpot-main {
        display: block;  
        padding-top: 40px;
    } 
 

  
   

}
@media (max-width: 610px) {

    .btnNumberSpn {
        width: 315px;
    }
    .centerBlockJack{
        padding-top:0px;
    }
    

    .boxRightFitstJackpot,
    .boxRightSecondJackpot,
    .boxLeftJackpot,
    .boxLeftFitstJackpot {
        width: 320px;
    }

    .boxRightSecondJackpot span {
        width: 290px;
    }

    .jackpotRightBlock {
        
        width: 325px;
    }
}
