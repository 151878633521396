.fon_preload {
  background: url(../../img/BG.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}




.preloaderSpinney {
  background-image: url(../../img/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
}