.burnsale {
    float: left;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    min-height: 100vh;
}

.burnsale::-webkit-scrollbar {
    width: 3px;
    height: 15px;
}

::-webkit-scrollbar-track {
    background-color: var(--glass-card, rgba(35, 38, 48, .5));
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: var(--glass-card, rgba(56, 59, 69, 0.5));
    border-radius: 10px;
    width: 20px;
}

.new-content {
    margin: 120px auto 90px;
    max-width: 1000px;
    width: 100%;
    //overflow-y: auto;

    @media(max-width: 800px) {
        margin: 40px auto 60px;
    }
}

.top-burn {
    display: flex;
    max-width: 1000px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 999;
    backdrop-filter: blur(24px);
    padding: 0 0 0 107px;
    background-image: url(../../img/saleburn/burn-top-ico.png);
    background-repeat: no-repeat;
    background-position: 17px center;
    background-size: 62.33px;
    font-size: 24px;
    color: #fff;
    font-weight: 100;
    line-height: 16px;
    align-items: center;

    @media (max-width: 990px) {
        max-width: 800px;
    }
}

// .content-wrap {

// }

.burn-group {
    display: flex;
    justify-content: center;
    margin-top: 80px;

    @media(max-width: 800px) {
        margin-top: 40px;
    }

    .cardPartFirst {
        margin: 0 10px 20px;
    }

    .bodyCardFirst {
        height: 217px;
    }

    .iconNftPart {
        &.burn-img {
            height: 190px;
            width: 136px;
        }
    }

    .Starter {
        .lines>div span {
            border: 1px solid #69CDFF;
            background-color: #69CDFF;

            &:after {
                box-shadow: 0px 0px 3.906px #69CDFF, 0px 0px 2.232px #69CDFF, 0px 0px 1.302px #69CDFF, 0px 0px 0.651px #69CDFF, 0px 0px 0.186px #69CDFF, 0px 0px 0.093px #69CDFF;
            }
        }

        .fonViolCircl {
            background-image: url(../../img/saleburn/ring-Starter.png);
        }
    }

    .Mystery {
        .lines>div span {
            border: 1px solid #F08019;
            background-color: #F08019;

            &:after {
                box-shadow: 0px 0px 3.906px #F08019, 0px 0px 2.232px #F08019, 0px 0px 1.302px #F08019, 0px 0px 0.651px #F08019, 0px 0px 0.186px #F08019, 0px 0px 0.093px #F08019;
            }
        }

        .fonViolCircl {
            background-image: url(../../img/saleburn/ring-Mystery.png);
        }
    }

    .Infinity {
        .lines>div span {
            border: 1px solid #69CDFF;
            background-color: #69CDFF;

            &:after {
                box-shadow: 0px 0px 3.906px #69CDFF, 0px 0px 2.232px #69CDFF, 0px 0px 1.302px #69CDFF, 0px 0px 0.651px #69CDFF, 0px 0px 0.186px #69CDFF, 0px 0px 0.093px #69CDFF;
            }
        }

        .fonViolCircl {
            background-image: url(../../img/saleburn/ring-Infinity.png);
        }
    }

    & > div {
        margin-bottom: 40px;
    }

    @media(max-width: 800px) {
        flex-direction: column;

        & > div {
            text-align: center;
        }
    }
}

.box-descr {
    margin: 20px 0 0 0;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    background-color: rgba(35,38,48, 0.5);
    //border-radius: 30px;
    color: #9AA0B0;
    font-weight: 100;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

}
.box-descr-top {
    display: flex;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.20);
    border-radius: 30px;
    justify-content: space-between;
    backdrop-filter: blur(24px);
    padding: 0 24px;
    align-items: center;
}
.box-descr-left {
    display: flex;
    flex-direction: column;
    line-height: 0.7;
    padding-top: 4px;

    span {
        font-size: 25px;
        display: block;
        color: #fff;
    }

    i {
        font-size: 20px;
        display: block;
        font-style: normal;
    }
}
.box-descr-right {
    display: flex;
    flex-direction: column;
    line-height: 0.7;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 5px;
    span {
        font-size: 20px;
        display: block;
    }

    i {
        font-size: 20px;
        display: block;
        font-style: normal;
        position: relative;
        padding-left: 17px;
        padding-top: 2px;
        font-size: 24px;
        color: #fff;

        &.coin:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 13px;
            background-image: url(../../img/saleburn/wax.webp);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            margin-top: -1px;
        }
    }
}

.box-descr-bot {
    display: flex;
    padding: 12px;
    flex-direction: row;
    align-items: center;
}
.burn-count {
    flex: none;
    width: 78px;
    height: 62px;
    background-color: rgba(0, 0, 0, 0.20);
    font-size: 20px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    line-height: 0.75;
    padding-top: 8px;

    b {
        font-weight: 900;
        display: flex;
        font-size: 48px;
        color: #fff;
    }
}
.burn-text {
    width: 100%;
    padding-left: 13px;
    font-size: 20px;
    line-height: 16px;
    padding-top: 2px;
}
.box-bt {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    button {
        height: 42px;
        width: 140px;
        border-radius: 57px;
        background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
        backdrop-filter: blur(24px);
        border: none;
        outline: none;
        display: flex;
        //font-family: 'Rubik', sans-serif;
        font-style: italic;
        color: #fff;
        font-size: 22px;
        //font-weight: 900;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
            animation: shadow-pulse 1s infinite;
        }

        span {
            position: relative;
            display: block;
            padding-right: 46px;

            i {
                display: block;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 0;
                margin-top: -3px;
            }
        }
    }
}
