
.OfferTag__content {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    margin-top: 40px;
}
.OfferTag__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
 
}
.second__card,
.offer_card {
    width: 380px;
    height: 80px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border: none;
    margin-left: 20px;
}

/* Safari и Opera */

.sliderOfferTag::-webkit-scrollbar {
    width: 6px;
}

.sliderOfferTag::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color:  rgba(0, 0, 0, 0.20);
}

.sliderOfferTag::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.20);
}

.sliderOfferTag::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.20);
}

.offer_card p {
    width: 299px;
    height: 49px;
    line-height: 16px;
    color: var(--text-2, #9AA0B0);
    text-align: center;
    margin: 0 auto;
    padding: 8px 15px 15px 15px;
}

.OfferTag__block {
    width: 380px;
    /* height: 432px; */
    margin-right: 19px;
    height: auto;
}

.name__spinney {
    top: 30px;
    left: -21px;
    position: absolute;
    width: 72px;
    height: 20px;
    border-radius: 10px;
    transform: rotate(-90deg);  
    font-size: 16px;
    text-align: center;
    line-height: 21px;  
}

.name__state {
    top: 30px;
    right: -14px;
    position: absolute;
    width: 64px;
    height: 20px;
    border: none;
    border-radius: 10px;
    transform: rotate(-90deg);
    font-size: 16px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    text-align: center;
    line-height: 21px; 
}

.AssedsCardMenubottom .DropBuy {
    line-height: 28px;
}
 
.second__card {
    position: relative;
    display: flex;
    margin-top: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.fon__rare,
.fon__epic,
.fon__legend,
.fon__uncommon {
    position: absolute;
    top: 8px;
    left: 37px;
    width: 174px;
    height: 64px;
}

.fon__legend{
    background: url(../../../img/sansh.webp);
}
.fon__epic{
    background: url(../../../img/violet.webp);
}
.fon__rare{
    background: url(../../../img/froste.webp);
}
.fon__uncommon{
    background: url(../../../img/leaf.webp);
}
.info__card__uncommon {
    position: absolute;
    right: 36px;
}
.MyAvailable,
.MyChance,
.MyRegist,
.Total,
.RegPrice {
    margin-left: 8px;
}
.rightAssedsCard .reg__t,
.MyChance,
.MyRegist{
    line-height: 22px;
}
.MyRegist,
.Total,
.RegPrice {
    line-height: 21px;
}

.BlockMyAvailable,
.BlockMyChance,
.BlockMyRegist,
.BlockTotal,
.BlockRegPrice {
    display: flex;
    justify-content: space-between;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    width: 130px;
    height: 20px;
    border-radius: 15px;
    font-size: 16px;
    text-align: center;
    position: relative;
}

.BlockRegPrice {
    margin-top: 7px;
}
.BlockMyChance,
.BlockMyRegist,
.BlockTotal {
    margin-top: 2px;
}

.AssedsCardMenubottom .BlockMyChance,.AssedsCardMenubottom .BlockMyRegist {
    margin-top: 4px;
}

.AssedsCardMenubottom .BlockTotal {
    margin-top: 0;
}
.spnBg {
    position: absolute;
    top: 6px;
    right: 8px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-weight: 400;
}

.reg__t {
    line-height: 22px;
    margin-right: 8px;
}

.price__reg {
    line-height: 22px;
    margin-right: 29px;
}

.second__card.hovered {
    box-shadow: 0px 0px 15px #f38918, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;
}
  
.second__card.clicked {
    box-shadow: 0px 0px 15px #f38918, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;;
}
  
/* right block */
.rightAssedsCard {
    margin-left: 20px;
}

.AssedsCardMenuTop {
    display: flex;
    justify-content: center;
    font-size: 24px;
    width: 380px;
    height: 40px;
    background:var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
}
.boost1 .DropBuy {
    width: 152px;
    height: 30px;
}

.AssedsCardMenubottom {
    position: relative;
    width: 380px;
    height: 138px;
    background:var(--dark-elements, rgba(0, 0, 0, 0.20));
    margin-top: 4px;
    border-radius: 10px;
}

.boost1 .AssedsCardMenuTop div:first-child {
    margin-left: 24px;
    margin-top: 3px;
    width: 205px;
    line-height: 39px;
}
.offer1 .AssedsCardMenuTop div:first-child {
    margin-top: 3px;
    width: 270px;
    text-align: center;
    line-height: 39px;
}
   

#indexCard {
    width: 82px;
    height: 20px;
    border-radius: 20px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border: none;
    line-height: 22px;
    margin-top: 10px;
    margin-right: 24px;
}
#infoRightAsseds {
   position: absolute;
   top: 16px;
   left: 24px;
}
.AssedsCardMenubottom .icon__modal {
    top: -46px;
}
.AssedsCardMenubottom .Group {
    width: 170px;
    height: 88px;
    position: absolute;
    top: 16px;
    right: 24px;
}

.AssedsCardMenubottom  .price p {
    top: 15px;
    color: var(--Text-2, #9AA0B0);
}

.AssedsCardMenubottom .DropBuy {
    margin: 8px 0px 0px 10px;
  
}

.topRightAssedsCard {
    position: relative;
    height: 250px;
}

.fonCircle1 {
    background: url(../../../img/Glow-Circle-_4_.webp);
    background-repeat: no-repeat;
    width: 344px;
    height: 214px;
    position: absolute;
    top: 91px;
    z-index: -1;
    left: -70px;
}

.fonCircle2 {
    background: url(../../../img/Glow\ Circle\ \(3\).webp);
    background-repeat: no-repeat;
    width: 416px;
    height: 227px;
    position: absolute;
    top: 108px;
    right: -66px;
    z-index: -1; 
}

.cardChangeBurn {
    width: 92px;
    height: 166px;
    position: absolute;
    top: 30px;
    left: 60px;
    transform: rotate(-10deg);
    border-bottom-right-radius: 10px;
}

.cardChange {
    width: 136px;
    height: 190px;
    position: absolute;
    top: 28px;
    right: 66px;
    transform: rotate(10deg);
}

.com__1 {
    width: 92px;
    height: 166px;
}
.com__1 {
    background: url(../../../img/BURNSteelade.webp);
    background-repeat: no-repeat ;
}
.uncom__3 {
    background: url(../../../img/BURNLeaflynn2.webp);
    background-repeat: no-repeat ;
}
.froste__4 {
    background: url(../../../img/BURNFrostee.webp);
    background-repeat: no-repeat ;
}
.epic__5 {
    background: url(../../../img/BURNVioletta.webp);
    background-repeat: no-repeat ;
}

.commonca__2,
.rar__4,
.viol__3,
.sunsh__5,
.leaf__3 {
    width: 136px;
    height: 190px;
}

.commonca__2{
    background: url(../../../img/offertag\ commonca.webp);
    background-repeat: no-repeat ;
}
.rar__4 {
    background: url(../../../img/04\ Frostee\ \(1\).webp);
    background-repeat: no-repeat ;
  
}
.viol__3 {
    background: url(../../../img/05\ Violetta.webp);
    background-repeat: no-repeat ;

}
.sunsh__5 {
    background: url(../../../img/06\ Sunshine.webp);
    background-repeat: no-repeat ;

}
.leaf__3 {
    background: url(../../../img/03\ Leaflynn2.webp);
    background-repeat: no-repeat ;

}
.offer_card {
    margin-bottom: 54px;
}

.OfferTag__content .icon__sum p {
    left: 6px;
}
@media (max-width: 800px) {
    .OfferTag__content {
        flex-direction: column;
    }

    .BlockProgressInfo .circtbtn {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: none;
        position: absolute;
        top: 2px;
        left: 2px;
    }
    .rightAssedsCard {
        margin-bottom: 140px;
    }
}


@media (max-width: 610px) {
    .AssedsCardMenuTop div:first-child {
      
        font-size: 20px;
    }

    #infoRightAsseds {
        width: 300px;
        left: 10px;
    }

    .AssedsCardMenubottom .Group {
        right: 10px;
    }
    .BlockRegPrice {
        margin-top: 0;
    }

    .BlockMyAvailable, .BlockMyChance, .BlockMyRegist, .BlockTotal, .BlockRegPrice {
        width: 120px;
    }
    .AssedsCardMenubottom .DropBuy {
        width: 160px;
    }
    .AssedsCardMenubottom,
    .AssedsCardMenuTop,
    .second__card, .offer_card,
    .OfferTag__block {
        width: 320px;
        margin-left: 5px;
    }

    .fon__rare, .fon__epic, .fon__legend, .fon__uncommon {
        background-position: center;
        width: 111px;
    }
   
    .rightAssedsCard {
        margin-top: 40px;
    }
    .OfferTag__block  {
        margin-right: 0;
    }
    .OfferTag__content .OfferTag__block:last-child{
        margin-right: 0;
        margin-bottom: 160px;
    }

    .AssedsCardMenubottom .Winnerslist, 
    .AssedsCardMenubottom .Registlist {
        left: 4px;
    }

   
}


