.BoxSpinners {
    text-align: center;
    flex-wrap: wrap;
}

.BoxSpinnersContainer {
    margin-bottom: 100px;
    margin-left: 32px;
}

@media (max-width:740px) {
.BoxSpinnersContainer {
    margin-left: 0;
}
}