.Part_content {
    padding-top: 120px;
    /* display: flex;
    flex-direction: column; */
    max-height: 100vh;
    overflow-y: auto;
}

.Part_content::-webkit-scrollbar {
    width: 3px;
    height: 15px;
}

::-webkit-scrollbar-track {
    background-color: var(--glass-card, rgba(35, 38, 48, .5));
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: var(--glass-card, rgba(56, 59, 69, 0.5));
    border-radius: 10px;
    width: 20px;
}

.topContentPartners {
    display: flex;
    width: 1000px;
    height: 70px;
    border-radius: 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 999;
    backdrop-filter: blur(24px);
}

.centerContentPart {
    display: flex;
    justify-content: space-between;
    margin-left: 234px;
    width: 320px;
    height: 40px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 30px;
    margin-top: 15px;

}


.btnRig {
    padding-top: 2px;
    width: 137px;
    height: 38px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
    margin-left: 20px;
}

.pt {
    font-size: 24px;
    line-height: 16px;
}

.centerContentPart .pt {
    margin-left: 17px;
    margin-top: 14px;
}

.PartNum {
    width: 50px;
    height: 22px;
    margin-top: 9px;
    padding-top: 2px;
    margin-left: 2px;
    font-size: 16px;
    color: var(--White, #FFF);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maxPartNum {
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    margin-right: 13px;
}


.titLeft {
    width: 230px;
    height: 41px;
    margin-left: 39px;
    margin-top: 20px;

}

/* // card */

.cardPartFirst {
    width: 260px;
    height: 232px;
    margin-bottom: 20px;
    display: inline-block;
    text-align: left;
}

.cardPartFirst:not(:last-child) {
    margin-right: 10px;

}

.centerContentPartners {
    
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
    /* height: 380px;
    height: calc(100vh - 372px); */
    margin: 0 auto;
    /* overflow-x: hidden;
    overflow-y: auto; */
    padding: 60px 10px 240px 10px;
    font-size: 0;
}

.centerContentPartners > div {
    display: inline-block;
    text-align: center;
    width: 25%;
}

@media (max-width:800px) {
    .centerContentPartners > div {
        width: 100%;
    }
}

.centerContentPartners.liqWrap {
    justify-content: start;
}

.centerContentPartners::-webkit-scrollbar {
    width: 3px;
    height: 15px;
}

::-webkit-scrollbar-track {
    background-color: var(--glass-card, rgba(35, 38, 48, .5));
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: var(--glass-card, rgba(56, 59, 69, 0.5));
    border-radius: 10px;
    width: 20px;
}

.bodyCardFirst {
    position: relative;
    height: 185px;
}

.bottomCardFirst {
    width: 260px;
    height: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin-top: 20px;
}


.bottomCardFirst .PartNum {
    margin-top: 0;
    margin-left: 9px;
}

.closeCard {
    margin-right: 7px;
}

.usNameWax {
    width: 120px;
    line-height: 16px;
    font-size: 24px;
}

.usNameWax::first-letter {
    text-transform: uppercase;
}

.userPart {
    margin-top: 3px;
}

.usIdWax {
    margin-right: 34px;
    width: 120px;
    line-height: 16px;
    color: var(--Text-2, #9AA0B0);
}

/* fonPart */
.fonGrTop {
    background: url(../../img/Group\ 291\ \(3\).webp);
    background-repeat: no-repeat;
}

.fonGrCircl {
    background: url(../../img/Group\ 749.webp);
    background-repeat: no-repeat;
}

.fonViolTop {
    background: url(../../img/Group\ 738\ \(2\).webp);
    background-repeat: no-repeat;
}

.fonViolCircl {
    background: url(../../img/Group\ 291\ \(1\)\ \(1\).webp);
    background-repeat: no-repeat;
}

.fonBlTop {
    background: url(../../img/Group\ 746.webp);
    background-repeat: no-repeat;
}

.fonBlCircl {
    background: url(../../img//Group\ 745.webp);
    background-repeat: no-repeat;
}

.fonViolTop,
.fonGrTop,
.fonBlTop {
    background-size: 100%;
    width: 260px;
    height: 120px;
    position: relative;
    z-index: -1;


}

.fonGrCircl,
.fonViolCircl,
.fonBlCircl {
    background-size: 100%;
    width: 260px;
    height: 80px;
    position: absolute;
    bottom: -27px;
    z-index: -1;
}


/*  */
/* iconNftPart */
.iconNftPart {
    /* background: url(../../img/Group\ 748.png); */
    background-repeat: no-repeat;
    background-size: 100%;
    width: 152px;
    height: 152px;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: hidden;
}

.iconNftPart.loop-ico {
    transform: translate(-50%, -50%) rotate(3deg);
}

.iconNftPart img {
    max-width: 100%;
}

/* anim */

.animation {
    width: 240px;
    height: 180px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    margin-left: 18px;
}

.animation .fff,
.animation .fff1,
.animation .fff2,
.animation .fff3,
.animation .fff4 {
    position: absolute;
    filter: blur(0.5px);
    border: 1px solid #8973D8;
    width: 4px;
    background-color: #8973D8;
    border-radius: 5px;
}

.animation .fff:after,
.animation .fff1:after,
.animation .fff2:after,
.animation .fff3:after,
.animation .fff4:after {
    content: "";
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    width: 70%;
    transform: translateX(-50%);
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 3.906px #8973D8, 0px 0px 2.232px #8973D8, 0px 0px 1.302px #8973D8, 0px 0px 0.651px #8973D8, 0px 0px 0.186px #8973D8, 0px 0px 0.093px #8973D8;
    border: 1px solid #8973D8;
}

.animation .fff {
    bottom: -100px;
    left: 13px;
    height: 90px;
    animation: mymove 3s infinite;
}

.animation .fff1 {
    bottom: -24px;
    left: 25px;
    height: 20px;
    animation: mymove3 3s infinite;
}

.animation .fff2 {
    bottom: -90px;
    left: 0px;
    height: 20px;
    animation: mymove2 3s infinite;
}

.animation .fff3 {
    bottom: -100px;
    left: 200px;
    height: 5px;
    animation: mymove4 3s infinite;
}

.animation .fff4 {
    bottom: -170px;
    left: 220px;
    height: 5px;
    animation: mymove5 3s infinite;
}

@keyframes mymove {
    0% {
        bottom: -130px;
        height: 0px;
    }

    100% {
        bottom: 210px;
        height: 90px;
    }
}

@keyframes mymove2 {
    0% {
        bottom: -250px;
        height: 0px;
    }

    100% {
        bottom: 210px;
        height: 20px;
    }
}

@keyframes mymove3 {
    0% {
        bottom: -60px;
        height: 0px;
    }

    100% {
        bottom: 210px;
        height: 20px;
    }
}

@keyframes mymove4 {
    0% {
        bottom: -60px;
        height: 0px;
    }

    100% {
        bottom: 210px;
        height: 40px;
    }
}

@keyframes mymove5 {
    0% {
        bottom: -130px;
        height: 0px;
    }

    100% {
        bottom: 210px;
        height: 20px;
    }
}

/*  */
@media (max-width: 1100px) {
    .centerContentPart {
        margin-left: 270px;
    }

    .topContentPartners {
        width: 1000px;
    }


}

@media (max-width: 990px) {
    .centerContentPart {
        margin-left: 70px;
    }

    .topContentPartners {
        width: 800px;
    }



}


@media (max-width:800px) {
    .Part_content {
        margin-top: 60px;
        padding-top: 0;
    }

    .topContentPartners {
        flex-direction: column;
        justify-content: center;
        height: 200px;
        align-items: center;
    }

    .centerContentPart {
        margin-left: 0px;
        width: 306px;
    }

    .topContentPartners {
        width: 320px;
    }

    .titLeft {
        margin-left: 0;
        text-align: center;
    }

    .centerContentPartners {
        justify-content: center;
    }


}



.lines {}

.lines.hide {
    display: none;
}

.lines>div {
    position: absolute;
    /* filter: blur(0.5px); */
    /* left: 0px; */
    top: 0px;
    width: 10px;
    height: 50px;
    /* border: 1px solid #fff; */
    /* background-color: #fff; */
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
}

.lines>div span {
    position: absolute;
    filter: blur(0.5px);
    bottom: -185px;
    left: 2px;
    border: 1px solid #8973D8;
    width: 4px;
    height: 50px;
    background-color: #8973D8;
    border-radius: 5px;
    /* animation: 2s cubic-bezier(.04, .5, 1, .58) 0.5s infinite mymove !important; */
    bottom: 0;
    transform: translate(0, 65px);
    /* animation: mymove_l1 3s infinite; */
    /* 3s linear 1s infinite alternate slidein; */
}

.lines>div span:after {
    content: '';
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    width: 70%;
    transform: translate(-50%, 0);
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 3.906px #8973D8, 0px 0px 2.232px #8973D8, 0px 0px 1.302px #8973D8, 0px 0px 0.651px #8973D8, 0px 0px 0.186px #8973D8, 0px 0px 0.093px #8973D8;
    border: 1px solid #8973D8;
}

.lines>div.l1 {
    left: 15px;
}

.lines>div.l1 span {
    height: 15px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 0.5s infinite mymove;
}

.lines>div.l2 {
    left: 26px;
}

.lines>div.l2 span {
    height: 65px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 0.6s infinite mymove
}

.lines>div.l3 {
    left: 26px;
}

.lines>div.l3 span {
    height: 16px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 1s infinite mymove;
}


.lines>div.l4 {
    left: 40px;
}

.lines>div.l4 span {
    height: 16px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 0.4s infinite mymove;
}


.lines>div.l5 {
    left: 40px;
}

.lines>div.l5 span {
    height: 53px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 1.4s infinite mymove;
}


.lines>div.l6 {
    right: 40px;
}

.lines>div.l6 span {
    height: 30px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 1.2s infinite mymove;
}


.lines>div.l7 {
    right: 32px;
}

.lines>div.l7 span {
    height: 30px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 0.4s infinite mymove;
}

.lines>div.l8 {
    right: 29px;
}

.lines>div.l8 span {
    height: 34px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 1.4s infinite mymove;
}

.lines>div.l9 {
    right: 17px;
}

.lines>div.l9 span {
    height: 15px;
    animation: 2s cubic-bezier(.04, .5, 1, .58) 0.5s infinite mymove
}


/* .left .l1 */

/* @keyframes mymove {
    0% {
        transform: translate(0, 65px);
        height: 0px;
    }

    100% {
        transform: translate(0, -200px);
        height: 60px;
    }
} */

:root {
    --translateY-start: 65px;
    --translateY-end: -200px;
    --height-start: 0px;
    --height-end: 15px;
}

@keyframes mymove {
    0% {
        transform: translateY(var(--translateY-start));
        height: var(--height-start);
    }

    100% {
        transform: translateY(var(--translateY-end));
        height: var(--height-end);
    }
}

.l1 span {
    --translateY-start: 65px;
    --translateY-end: -200px;
    --height-start: 0px;
    --height-end: 15px;
}

.l2 span {
    --translateY-start: 75px;
    --translateY-end: -270px;
    --height-start: 0px;
    --height-end: 66px;
}

.l3 span {
    --translateY-start: 143px;
    --translateY-end: -225px;
    --height-start: 0px;
    --height-end: 16px;
}

.l4 span {
    --translateY-start: 40px;
    --translateY-end: -215px;
    --height-start: 0px;
    --height-end: 16px;
}

.l5 span {
    --translateY-start: 138px;
    --translateY-end: -235px;
    --height-start: 0px;
    --height-end: 53px;
}


.l6 span {
    --translateY-start: 135px;
    --translateY-end: -275px;
    --height-start: 0px;
    --height-end: 30px;
}

.l7 span {
    --translateY-start: 40px;
    --translateY-end: -215px;
    --height-start: 0px;
    --height-end: 30px;
}

.l8 span {
    --translateY-start: 150px;
    --translateY-end: -255px;
    --height-start: 0px;
    --height-end: 34px;
}

.l9 span {
    --translateY-start: 65px;
    --translateY-end: -200px;
    --height-start: 0px;
    --height-end: 15px;
}

/* 
@keyframes mymove_l1 {
    0% {
        transform: translate(0, 65px);
        height: 0px;
    }

    100% {
        transform: translate(0, -200px);
        height: 15px;
    }
}

@keyframes mymove_l2 {
    0% {
        transform: translate(0, 75px);
        height: 0px;
    }

    100% {
        transform: translate(0, -270px);
        height: 66px;
    }
}

@keyframes mymove_l3 {
    0% {
        transform: translate(0, 143px);
        height: 0px;
    }

    100% {
        transform: translate(0, -225px);
        height: 16px;
    }
}

@keyframes mymove_l4 {
    0% {
        transform: translate(0, 40px);
        height: 0px;
    }

    100% {
        transform: translate(0, -215px);
        height: 16px;
    }
}

@keyframes mymove_l5 {
    0% {
        transform: translate(0, 138px);
        height: 0px;
    }

    100% {
        transform: translate(0, -235px);
        height: 53px;
    }
}

@keyframes mymove_l6 {
    0% {
        transform: translate(0, 135px);
        height: 0px;
    }

    100% {
        transform: translate(0, -275px);
        height: 30px;
    }
}

@keyframes mymove_l7 {
    0% {
        transform: translate(0, 40px);
        height: 0px;
    }

    100% {
        transform: translate(0, -215px);
        height: 30px;
    }
}

@keyframes mymove_l8 {

    0% {
        transform: translate(0, 150px);
        height: 0px;
    }

    100% {
        transform: translate(0, -255px);
        height: 34px;
    }
}

@keyframes mymove_l9 {

    0% {
        transform: translate(0, 65px);
        height: 0px;
    }

    100% {
        transform: translate(0, -200px);
        height: 15px;
    }
} */