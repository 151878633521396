.StartLogin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.LogiContent .server {
  background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
  width: 100px;
  height: 30px;
  border-radius: 20px;
  text-transform: unset;
  font-size: 20px;
  line-height: 31px;
  bottom: 278px;
  right: 100px;
}
.LogiContent {
  margin-top: 6px;
}

.LogiContent #claim__server {
  margin-top: 4px;
}


.LogiContent #claim__server {
  backdrop-filter: blur(24px);
  width: 297px;
  height: 290px;
}

.arrowRight {
  background: url(../../img/Group\ 533.webp);
  background-repeat: no-repeat;
  position: absolute;
  top: 12px;
  right: 14px;
  width: 16px;
  height: 16px;
}

.LogiContent .choose__server {
  margin-top: 24px;
  text-align: center;
  margin-right: 0;
}
.LogiContent .choose__server {
  margin-top: 24px;
  text-align: center;
  margin-right: 0;
}

.LoginContent.top__menu__name{
  display: flex;
  justify-content: center;
  width: 297px;
  background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.boxLogin {
  display: inline-block;
}

.oneTitle {
  margin-top: 22px;
}

#claim__server.modal {
  text-align: center;
  display: inline-block;
}



#claim__server .claim__price {
  display: inline-block;
  text-align: center;
}


#claim__server .dropdown__header {
  text-align: left;
}


#claim__server .vector__open {
  top: 14px;
}

.LoginContent .title,
.StartLogin .title ,
#claim__server .title {
  height: 42px;
}

#claim__server .option,
#claim__server .option:not(:first-child) {
  text-align: left;
  margin: 8px 0px 4px 8px;
}

.block__formLog .LogiContent #claim__server {
  width: 289px;
  height: 205px;
}

.block__formLog input {
  width: 202px;
  height: 30px;
  border-radius: 20px;
}

.container__form #claim__server:last-child .choose__server{
  margin-bottom: 5px;
}


/* #form .choose__server {
  margin-left: 98px;
} */

.block__formLog .choose__server {
  text-align: left;
}

.block__formLog .top__menu__name {
  margin-bottom: 0;
}



#claim__server:last-child .choose__server{
  line-height: 7px;
  margin-bottom: 16px;
}

.dropdown__wrapper {
  width: 200px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.choose__server {
  line-height: 13px;
}

@media (max-width: 610px) {
  .LogiContent #claim__server{
    max-width: 300px;
  }

}