.chance__nav .nav__top {
    width: 244px;
    /* margin-top: 14px; */
}
.chance__nav.leaderbordVol .nav__top {
    width: 244px;
    margin-top: 113px;
}


.circleFon {
    width: 100%;
    height: 130%;
    background-image: url(../../img/Group\ 461\ \(1\).webp);
    background-repeat: no-repeat;
    background-position: center;
    animation: rotate 8s linear infinite;
    position: fixed;
    left: 0;
    right: 0;
    top: -15%;
    left: 0;
    bottom: 0;
    pointer-events: none;
}

.WinPage {
    position: relative;

}


@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.WinPageFon {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.titleWinner {
    text-align: center;
    text-shadow: 0px 0px 10.961999893188477px #82C12F, 0px 0px 21.923999786376953px #82C12F, 0px 0px 37.58399963378906px #82C12F, 0px 0px 65.77200317382812px #82C12F;
    font-size: 64px;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.container__game {
    display: block;
    position: relative;
    width: 540px;
    height: 420px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 0 auto;
    border-radius: 10px;
}

.container__game .title {
    font-size: 37px;
    line-height: 21px;
    text-align: center;

}

.titlBoostUp {
    width: 339px;
    margin: 0 auto;
    padding-top: 24px;
}

.container__game p {
    padding-top: 10px;
    margin: 0 auto;
    width: 163px;
    color: var(--Text-2, #9AA0B0);
}

.fonWinner {
    background: url(../../img/Group\ 461.webp);
    background-repeat: no-repeat;
    width: 600px;
    height: 368px;
    position: absolute;
    right: -45px;
    top: -91px;
    z-index: -1;
    pointer-events: none;
}

.container__game .btn__modal {
    left: 210px;
    bottom: 17px;
}

.fonWinnerIcon {
    margin: 0 auto;
    width: 136px;
    height: 190px;
}



.iconWinner {
    margin-top: 25px;
    position: relative;
    z-index: 99;
}

/* Losepage */

.TicketIcon {
    background-image: url(../../img/Ticket\ 700x500\ 1.webp);
    background-repeat: no-repeat;
    background-size: 80%;
    width: 214px;
    height: 152px;
    margin: 0 auto;

}

.LosePage .titlBoostUp {
    width: 410px;
}

.LosePage .container__game {
    width: 540px;
    height: 294px;
}

.LosePage .iconWinner {
    margin-top: -8px;
    margin-left: 42px;

}

.winnerTicket {
    position: absolute;
    left: 251px;
    bottom: 79px;
    width: 36px;
    height: 24px;
    border-radius: 15px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    text-align: center;
    line-height: 25px;
}

.winnerBoxTicker {
    display: flex;
    flex-direction: column;
    position: relative;

}

.winnersTiclet {
    background: url(../../img/Ticket\ 700x500\ 1\ \(3\).webp)no-repeat;
    height: 46px;
    width: 84px;
    background-size: 100%;
    position: absolute;
    top: 2px;
    left: 221px;
    z-index: 997;
}

.cirklFonTicket {
    background: url(../../img/Group\ 631\ \(1\).webp)no-repeat;
    background-size: 106%;
    height: 164px;
    width: 273px;
    position: absolute;
    top: -62px;
    left: 121px;
    z-index: 996;
    pointer-events: none;
}

.modalWin {
    left: 287px;
    bottom: 65px;
    z-index: 999;
}



.LosePage .container__game p {
    padding-top: 20px;
}

/* #ModalTicket {
    background-image: url(../../img/ddff.webp);
    background-repeat: no-repeat;
    position: fixed;
    height: 100%;
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;    
} */

#modal17 {
    overflow: auto;
    overflow-x: hidden;
    /* background: rgba(46, 51, 65, 0.5); */
    /* background: linear-gradient(180deg, rgba(47, 51, 65, 0.95) 0%, rgba(25, 27, 32, 0.95) 100%); */
    backdrop-filter: blur(4px);
    z-index: 9999;
    position: fixed;
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;


}

#modal15,
#modal16 {
    backdrop-filter: blur(4px);
    z-index: 9999;
    position: fixed;
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
}

#modal17::-webkit-scrollbar,
#modal15::-webkit-scrollbar,
#modal16::-webkit-scrollbar {
    width: 8px;
    height: 30px;
}

::-webkit-scrollbar-track {
    background-color: var(--glass-card, rgba(35, 38, 48, .5));
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, .2) inset;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #00c6fb, #005bea);
    border-radius: 10px;
    width: 20px;
}


#modal20 .Jack {
    width: 150px;
    text-align: center;
    padding-top: 20px;
    font-size: 32px;
    line-height: 22px;
}

.animNumberJack {
    margin: 0 auto;
    text-align: center;
    width: 242px;
    text-shadow: 0px 0px 1.565999984741211px #9F2BAD, 0px 0px 3.131999969482422px #F0821A, 0px 0px 10.961999893188477px #F0821A, 0px 0px 21.923999786376953px #F0821A, 0px 0px 37.58399963378906px #F0821A, 0px 0px 65.77200317382812px #F0821A;
    font-size: 40px;
    text-transform: uppercase;
    padding-top: 6px;
}


/* anim */
.animNumberJack.animDot {
    font-family: 'Rubik', sans-serif;
    display: flex;
    justify-content: center;
    width: 40px;
    align-items: center;
    /* margin-top: -10px;
    padding: 20px; */
    text-align: center;

}

.animNumberJack.animDot:before {
    content: '.';
    display: inline-block;
    font-size: 40px;
    animation: l 1s steps(4) infinite;
}

@keyframes l {
    0% {
        content: '.';
    }

    25% {
        content: '..';
    }

    50% {
        content: '...';
    }

    75% {
        content: '...';
    }

    100% {
        content: '...';
    }
}



#modal20 .container__game p {
    width: 179px;
    text-align: center;
    padding-top: 0;
    line-height: 16px
}

.animSp {
    font-size: 20px;
}

#modal20 .container__game {
    width: 540px;
    height: 210px;
}

#modal20 .btn__modal {
    margin: 0 auto;
}

#modal20 .WinPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* @media (max-width: 840px) {
    .WinPage {
        width: 700px;
        height: 870px;
        background-position: center;
        overflow: hidden;
       
    }
} */



@media (max-width:740px) {
    .LosePage .container__game {
        width: 320px;
        height: 310px;
    }

    .container__game {
        width: 320px;
        height: 450px;
    }

    .winnersTiclet {
        top: 11px;
        left: 124px;
    }

    .cirklFonTicket {
        top: -55px;
        left: 22px;
    }

    .modalWin {
        left: 191px;
        bottom: 89px;
    }

    .fonWinner {
        height: 397px;
    }

    .winnerTicket {
        left: 148px;
        bottom: 91px;

    }

    .LosePage .titleWinner {
        width: 300px;
    }

    .fonWinner {
        background: url(../../img/Group\ 458\ \(1\).png);
        width: 387px;
        right: -13px;
        top: -103px;
    }


    .WinPage {
        /* width: 320px;
        height: 870px; */
        background-position: center;
        overflow: hidden;

    }

    .container__game .title {
        font-size: 25px;
        width: 300px;
    }

    .container__game .btn__modal {
        left: 110px;
    }

    .titleWinner {
        font-size: 47px;
        margin-top: 67px;
    }

    #modal20 .container__game{
        width: 320px;
    }

    #modal20 .container__game .btn__modal {
        margin: 0 auto;
        left: 97px;
    }
    .chance__nav.leaderbordVol .nav__top {
        margin-top: 60px;
    }



}

@media (max-width: 320px) {
    .WinPage {
        width: 320px;
        height: 870px;
        background-position: center;
        overflow: hidden;

    }




}