.bank__content a {
    text-transform: uppercase;

}

.Token1 {
    margin-top: 40px;
    width: 785px;
}

.bank__content .top__menu__name {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    display: flex;
    justify-content: space-between;

}

.bank__content .player {
    color: #F29E26;
    padding-bottom: 10px;
}

#t .nft__card__content {
    position: relative;
    width: 380px;
    height: 595px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-top: 10px;
}

.info-tken-card {
    font-family: 'Dongle', sans-serif;
    position: relative;
    width: 308px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-top: 10px;
    padding: 16px 36px;
    line-height: 16px;
    font-weight: 300;
    color: #fff;
}

@media (max-width: 610px) {
    .info-tken-card {
        width: 268px;
        padding: 16px;
    }
}

.info-tken-card p {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

#t .bottom__menu {
    justify-content: center;
    margin-top: 30px;
}


.amount button {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border: none;
    padding-bottom: 5px;
    line-height: 14px;
    cursor: pointer;
}

.amount button:not(:last-child) {
    margin-right: 5px;
}

.token__title p:first-child {
    /* height: 47px; */
    width: 296px;
    color: #9AA0B0;
    padding-top: 30px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 15px;
}

.amount button.active {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
}

.amount button span {
    margin-left: 2px;
}

.total,
.token__title p {
    margin: 0 auto;
    text-align: center;
}

.total {
    width: 219px;
    /* height: 65px; */
    margin-top: 32px;
}

.total.fees-bank {
    margin-top: 16px;
    padding-left: 33.5px;
    padding-right: 33.5px;
    width: 152px;
}

.total.fees-bank .total__spn {
    margin-bottom: 4px;
}

.total.fees-bank .total__spn:last-child {
    margin-top: 0;
}

.total.fees-bank .pSpn {
    position: absolute;
    right: 10px;
    top: 2px;
}

.token__title p.last-child {
    width: 44px;
    height: 20px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    color: #D48928;
    margin-bottom: 27px;
    line-height: 23px;

}

#t .header__cash {
    margin: 0 auto;
}

.total p:first-child {
    width: 157px;
    line-height: 12px;
    text-align: center;
    margin: 0 auto;
}

.total__spn p:first-child {
    text-align: left;
    margin: 0px;
}

.total__spn {
    height: 20px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 9px 0px 16px;
    margin: 10px 0px 8px 0px;
    position: relative;
}

.total__spn div p:first-child {
    font-weight: 300;
    background: linear-gradient(180deg, #535D7A 0%, #434D67 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.total__spn p:last-child {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 0;
    line-height: 0.6;
    font-weight: 400;
}


.w .token__title {
    padding-top: 86px;
}

#t .nft__card__content,
.w .nft__card__content {
    height: 300px;
    padding-top: 15px;
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    align-content: center;
}

#t .nft__card__content>div,
.w .nft__card__content>div {
    width: 100%;
}

#t .nft__card__content>div.bottom__menu {
    position: static;
    margin: 0;
}

#t .nft__card__content>div.bottom__menu button {
    margin: 8px;
}

#t .w .nft__card__content>div.bottom__menu {
    position: static;
    margin: 20px 0 0 0;
}

.w .nft__card__content>div.bottom__menu button {
    margin: 8px;
}

.total__spn #res {
    height: 13px;
    background: linear-gradient(180deg, #e6e6e6 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.6px;
    font-family: 'Dongle', sans-serif;
}

.total__spn input {
    background: none;
    font-family: 'Dongle', sans-serif;
    font-weight: 300;
    width: 160px;
    margin-bottom: 2px;
    height: 20px;
    padding-left: 1px;
    line-height: 30px;
    font-size: 20px;
    display: block;
    padding-top: 6px;

}

.pSpn {
    padding-top: 4px;
}


#t .boxSpnWallet {
    padding-top: 3px;

}


.assets .wallet__info .block__sum:first-child,
.assets .icon__spn:first-child,
.assets #walle p:first-child {
    display: none;
}


.w .wallet__info .block__sum:last-child,
.w .icon__spn:first-child {
    display: none;
}

.assets .name__wallet p,
.w .name__wallet p {
    margin: -8px -19px -18px 8px;
}


.assets .wallet__info,
.w .wallet__info {
    display: flex;
    justify-content: center;

}

.assets .wallet__info {
    line-height: 25px;
}

.w .wallet__info {
    line-height: 22px;
}

.assets .price__coin,
.w .price__coin {
    background-size: 30px;
}

.assets .header__SpnToken .nambTicket,
.w .header__SpnToken .nambTicket {
    line-height: 11px;
}

.w .name__wallet p:first-child {
    margin-top: 0px;
}

.assets .name__wallet p:last-child {
    margin-top: -8px;
}

.assets .icon__cash {
    margin: 8px 13px;
}

.w .icon__cash {
    margin: 9px 13px;
}

.costs {
    position: absolute;
    left: 71px;
    bottom: 6px;
    width: 239px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    line-height: 0.6;
}



.assets .name__wallet {
    margin-top: 7px;
}

.assets .icon__sum p {
    bottom: 2px;
}



.assets .icon__sum {
    line-height: 27px;
}

.w .icon__sum {
    line-height: 24px;
}

.assets .icon__spn p,
.assets .icon__sum p {
    bottom: 2px;
}

.w .icon__spn p,
.w .icon__sum p {
    bottom: 1px;
}

.total__spn input[type=number]::-webkit-inner-spin-button,
.total__spn input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;

}

.total__spn input #inp {
    font-family: 'Dongle', sans-serif;
    font-style: normal;
    font-weight: 300;
    padding-left: 1px;
}


.Token1 .bottom__menu #b:disabled:hover,
.Token1 .bottom__menu button:disabled:hover {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    box-shadow: none;
    animation: none;
    cursor: inherit;
}

.Token1 .header__cash {
    pointer-events: none;
}


.Token1 .w .block__sum:not(:last-child) {
    margin-bottom: 0;
}


.Token1 #t .nambTicket,
.Token1 .w .nambTicket {
    margin-top: -1px;
    font-weight: 400;
    line-height: 0.6;
}

@media (max-width: 610px) {
    #t .nft__card__content {
        width: 300px;
    }



    .costs {
        left: 33px;
    }
}