.main__content {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.contentBankpage {
    height: 100%;
    margin-top: 114px;
    margin-bottom: 40px;

}

#Bank_1{
    margin-top: 113px;
}

.contentBankpage .container__footer {
    margin-top: 97px;
}

button .j {
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 20px;
    display: block;

}

.nav__top {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;

}

.bt__nav {
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 20px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    padding: 0;
    cursor: pointer;
}


.bt__nav:not(:first-child) {
    margin-left: 12px;
}

.animation__top {
    position: absolute;
    height: 2px;
    width: 37px;
    top: 36px;
    left: 22px;
    z-index: 0;
    background: linear-gradient(180deg, #9c510b 0%, #9f7f0d 80%);
    -webkit-text-fill-color: transparent;
    border-radius: 8px;
    transition: left 0.3s ease-in-out;
}

.active__top {
    /* background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;    */
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

/* .nav__top_container {
    margin-top: 16px;
} */
@media (max-width: 800px) {
    .contentBankpage {
        margin-bottom: 107px;
    }
}

@media (max-width: 610px) {


    .contentBankpage {
       margin-top: 40px;
       margin-bottom: 107px;
    }
    .bt__nav:not(:first-child) {
        margin-left: 5px;
    }
}