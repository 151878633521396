.nav__content {
  width: 100%;
  margin-top: 114px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

/* winter-fon */
.navWinter{
  background: url(../../img/winterNav.webp);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 312px;
  height: 38px; 
  position: absolute;
  top: -14px;
  z-index: 8;
  pointer-events: none;
}
/*  */

.nav__content__link1,
.nav__content__link2,
.nav__content__link3 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  z-index: 5;
  align-items: center;
  position: relative;
  cursor: pointer;
}
/* ----------- */
.nav__content__link2{
  background-color: rgba(0, 0, 0, 0.3);
  background-image:url(../../img/Group\ 148\ \(1\).svg) ;
  background-repeat: no-repeat;
  background-position: center;
}
.nav__content__link2:hover{
  background-color:rgba(242, 152, 35, 1);
  background-image:url(../../img/l1111.svg) ;
}
.nav__content__link1{
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(../../img/Group\ 149\ \(1\).svg);
  background-repeat: no-repeat;
  background-position: center;
}
.nav__content__link1:hover{
  background-color:rgba(242, 152, 35, 1);
  background-image:url(../../img/uuu2.svg) ;
}
.nav__content__link3{
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(../../img/Group\ 97.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.nav__content__link3:hover{
  background-color:rgba(242, 152, 35, 1);
  background-image:url(../../img/z3333.svg) ;
}
/* ----------------- */


/* 2 */
.nav__content__link1,
.nav__content__link3 {
  margin-left: 8px;
}


.nav__content__link1:hover,
.nav__content__link2:hover,
.nav__content__link3:hover{
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  animation: shadow-pulse 1s infinite;
}
.spinAll button:hover,
.boxStateMult:hover{
  overflow: hidden;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  animation: shadow-pulse 1s infinite;
  cursor: pointer;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
}

.ReferralMultiplier,
.ProfileMultiplier {
  display: block;
  width: 215px;
  height: 40px;
  border-radius: 20px;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
}

.ReferralMultiplier{
  margin-left: 8px;
  position: relative;
}

.ProfileMultiplier {
  margin-right: 8px;
  position: relative;
}

.boxStateMult {
  width: 80px;
  height: 40px;
  border-radius: 20px;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
  position: absolute;
  top: 0;
  right: 0;
;
}

.ReferralMultiplier .boxStateMult{
  left: 0;
  
}
.ReferralMultiplier .title,
.ProfileMultiplier .title{
  font-size: 20px;
  color: var(--Text-2, #9AA0B0);
  width: 140px;
  line-height: 42px;
}



.ReferralMultiplier .title{
  width: 288px;
}

.ProfileMultiplier .nambTicket{
  margin-right: 14px;
  line-height: 42px;
}

.ReferralMultiplier .nambTicket {
  margin-right: -29px;
  line-height: 42px;
}

.iconStateRef,
.iconStateMult {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.iconStateMult{
  top: 7px;
  right: 10px;
  background: url(../../img/Group\ 523.svg);
}

.iconStateRef {
  background: url(../../img/Group\ 524.svg);
  top: 7px;
  left: 10px;
}

.spinAll button{
  position:absolute;
  top: 0;
  right: -210px;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
  line-height: -20px;
}
.spinAll button:hover{
  background-color:rgba(242, 152, 35, 1);

} 

.ProfileMultiplier .nambTicket,
.ReferralMultiplier .nambTicket {
  pointer-events: none;
}
@media (max-width: 800px){
  .ReferralMultiplier .nambTicket,
  .ProfileMultiplier .nambTicket,
  .ReferralMultiplier .title,
  .ProfileMultiplier .title {
    display: none;
  }
  .ReferralMultiplier .boxStateMult,
  .ProfileMultiplier .boxStateMult  {
      width: 40px;
  }
  .ReferralMultiplier,
  .ProfileMultiplier {
      width: 40px;
  }
  .iconStateMult,
  .iconStateRef {
    top: 8px;
    left: 8px;
  }
  .spinAll button {
    right: -102px;
    width: 78px;
  }
 
}
@media (max-width: 1000px) {

  .navWinter{
    background: url(../../img/navWin.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 146px;
    height: 38px; 
    position: absolute;
    top: -14px;
    z-index: 8;
    pointer-events: none;
  }
  
  /* .navWinter1,
  .navWinter2,
  .navWinter3,
  .navWinter4 {
    background: url(../../img/w\ \(2\)\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 52px;
    height: 38px; 
    position: absolute;
    top: -14px;
    right: 128px;
    z-index: 8;
    pointer-events: none;
  }
  .navWinter2{
    right: 80px;
  }
  .navWinter3{
    left: 128px;
  }
  .navWinter4{
    left: 15px;
  } */

}

@media (max-width: 610px) {
  .nav__content {
    margin-top: 40px;
  }
  .spinAll button {
    right: -48px;
    width: 40px;
    font-size: 16px;
    line-height: 11px;
  }
    /* winter-fon */

  /*  */


}
