.craft {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 139px; */
    height: 100%;
}

.CRAFT1 {
    /* margin-bottom: 112px; */
    margin-top: 40px;
}

#craft .leaf__one__block {
    padding-top: 32px;
    margin-bottom: 20px;
}




#craft .leaf__one__button {
    margin-top: 22px;
}

.Craft__price {
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 200px;
    height: 50px;
    padding-top: 1px;
}

#craft .icon__sum p {
    font-size: 36px;
    font-weight: 400;
    left: 33px;
    top: -50px;
    background: none;
    box-shadow: none;
}

#craft .block__sum {
    background: none;
    box-shadow: none;
}

.craft .price p {
    position: absolute;
    color: var(--Text-2, #9AA0B0);
}

#craft .modal__content .wallet__content {
    display: flex;
    width: 200px;
    height: 50px;
    margin-top: 9px;
    background: rgba(35, 38, 48, 0.5);
    border-radius: 5px;
}

.craft__left__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    height: 505px;
    margin-bottom: 28px;
}

.craft__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 51px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin-bottom: 10px;
}

.craft__menu button:first-child {
    margin: 0px 8px 0px 17px;
}

.craft__menu button:last-child {
    margin: 0px 17px 0px 8px;
}

.craft__menu button {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 20px;
    border: none;
    color: #fff;
    cursor: pointer;
    width: 110px;
    height: 30px;
}

.craft__menu button.activeBtn {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
}

.craft__cards {
    width: 100%;
    height: 440px;
    position: relative;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
}

.spinney {
    overflow: hidden;
    overflow-y: scroll;

}

.craft__card__slider {
    display: flex;
    position: absolute;
    top: 24px;
    left: 12px;
    right: 30px;
    bottom: 58px;
    width: 355px;
    height: 330px;
    overflow: visible;
    border-radius: 10px;
}

/* .craft__card__slider>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 330px;
    height: 500px;
} */

.craft__card__slider button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.craft__card__slider button.active {
    color: #000;
}

.runes::-webkit-scrollbar,
.packs::-webkit-scrollbar,
.spinney::-webkit-scrollbar {
    width: 5px;
}

.sliderOfferTag ::-webkit-scrollbar-track,
.packs::-webkit-scrollbar-track,
.runes::-webkit-scrollbar-track,
.spinney::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.packs::-webkit-scrollbar-thumb,
.runes::-webkit-scrollbar-thumb,
.sliderOfferTag::-webkit-scrollbar-thumb,
.spinney::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.craft__right__content {
    width: 380px;
    height: 532px;
    margin-left: 24px;
    /* width: 431px;
    height: 440px; */
    /* background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2)); */
    margin-left: 24px;
    /* margin-top: 28px; */
}

.craft__right__content>div.active {
    display: block;
}

/* .available {
    display: flex;
    justify-content: center;
    width: 115px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 10%;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;
    color: #fff;
    z-index: 3;
} */

.available a:first-child {
    margin-left: 8px;
}

.available a {
    line-height: 22px;
}


#available__violetta__4,
#available__sunshine__5,
#available__common__1,
#available__legend__5,
#available__promo__0,
#available__rare__3,
#available__epic__4,
#available__uncommon__2,
#available__leaf__2,
#available__mag__0,
#available__steel__1,
#available__frost__3 {
    position: relative;
    top: 16px;
    /* left: 8px; */
    left: 20px;
    height: 138px;
    width: 98px;
    margin-bottom: 12px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
}

.bg__nft,
.all__bg_right.bg__nft,
#available__violetta__4.active,
#available__sunshine__5.active,
#available__common__1.active,
#available__legend__5.active,
#available__promo__0.active,
#available__rare__3.active,
#available__epic__4.active,
#available__uncommon__2.active,
#available__leaf__2.active,
#available__mag__0.active,
#available__steel__1.active,
#available__frost__3.active {
    background: linear-gradient(180deg, #2F3340 0%, #292C36 100%);
    box-shadow: 0px 0px 15px #f38918, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;
    border-radius: 5px;
    /* transition: 0.4s;
    animation-fill-mode: forwards; */
    cursor: pointer;
}

.runes #available__common__1,
.runes #available__rare__3,
.runes #available__uncommon__2 {
    background: var(--Dark, linear-gradient(180deg, #2F3340 0%, #292C36 100%));
}

#account {
    margin-left: 10px;
}

.leaf__player {
    background-image: url(../../../src/img/newLeaflynn2\ \(1\).webp);
}

.mag__player {
    background-image: url(../../../src/img/nevmag.webp);
}

.frost__player {
    background-image: url(../../../src/img/newFrostee\ \(2\).webp);
}

.steel__player {
    background-image: url(../../../src/img/newSteelade\ \(2\).webp);
}

.sunshine__player {
    background-image: url(../../../src/img/newSunshine\ \(1\).webp);
}

.violetta__player {
    background-image: url(../../../src/img/newVioletta\ \(1\).webp);
}

/* runes */


.runes__pic {
    position: absolute;
    top: 0px;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 98px;
    height: 138px;
}

.promo_player {
    background-image: url(../../../src/img/138Prommo.webp);
}

.rare__player {
    background-image: url(../../../src/img/newRare\ Rune.webp);
}

.epic__player {
    background-image: url(../../../src/img/newEpic\ Rune.webp);
}

.uncommon__player {
    background-image: url(../../../src/img/newUncommon\ Rune.webp);
}

.legend__player {
    background-image: url(../../../src/img/newLegend\ Rune.webp);
}

.common__player {
    background-image: url(../../../src/img/newCommon\ Rune.webp);
}

.silver__player {
    background-image: url(../../../src/img/basicPrem.webp);
    background-size: 100%;
}

.gold__player {
    background-image: url(../../../src/img/elitPrem.webp);
    background-size: 100%;
}

.supreme__player {
    background-image: url(../../img/suprame.webp);
    background-size: 100%;
}

#modal6 .supreme__player {
    background-image: url(../../img/suprame.webp);
    background-size: 100%;
}

.ticket__player {
    background-image: url(../../../src/img/tic.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.infinity__player {
    background-image: url(../../img/tmpl/749991.webp);
    background-size: 100%;
    /* background-position-y: 15px; */
    border-radius: 5px;
    background-repeat: no-repeat;
    background-color: var(--Dark, linear-gradient(180deg, #2F3340 0%, #292C36 100%));

}

.colorPassProgress {
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%)),
}

.mystery__player {
    background-image: url(../../img/tmpl/749990.webp);
    background-size: 100%;
    /* background-position-y: 15px; */
    border-radius: 5px;
    background-color: var(--Dark, linear-gradient(180deg, #2F3340 0%, #292C36 100%));
    background-repeat: no-repeat;
}

.starter__player {
    background-image: url(../../img/tmpl/749989.webp);
    background-size: 100%;
    /* background-position-y: 15px; */
    border-radius: 5px;
    background-color: var(--Dark, linear-gradient(180deg, #2F3340 0%, #292C36 100%));
    background-repeat: no-repeat;
}

.packs,
.runes {
    width: 390px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

/*  */

.leaf__one__info {
    display: block;
    margin: 0 auto;
}

.leaf__one__block {
    display: flex;
    height: 254px;
    position: relative;
    z-index: 3;
    /* margin-top: 32px; */
}

.leaf__one__image {
    background-image: url(../../img/238Leaflynn2.webp);
    background-repeat: no-repeat;
}

.titlef {
    font-size: 32px;
    text-transform: uppercase;
    height: 25px;

}

.inf__leaf__c {
    margin-bottom: 8px;
}

.nft_jingl {
    background: url(../../img/01\ Jingles.webp)no-repeat;
    position: absolute;
    top: 0px;
    left: 0;
    background-repeat: no-repeat;
    width: 98px;
    height: 138px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
}

#inf__num,
.inf__leaf__c,
.inf__leaf {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

.leaf__one__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    width: 126px;
    height: 239px;
    border-radius: 5px;
    margin-left: 20px;
}

.leaf__one__description:last-child .inf__leaf__c {
    margin-top: 4px;
}

.title__leaf {
    line-height: 20px;
}

#inf__num,
.inf__leaf__c {
    margin: 0 auto;
    width: 94px;
    height: 21px;
    font-size: 16px;
    /* line-height: 22px; */
}

.multi_leaf {
    font-size: 16px;
    margin-top: 8px;
}

/* .violetta__one__info .top__menu__name,
.sunshine__one__info .top__menu__name,
.frost__one__info .top__menu__name,
.steel__one__info .top__menu__name,
.mag__one__info .top__menu__name,
.leaf__one__info .top__menu__name {
    position: absolute;
    bottom: 445px;
} */

.cfon {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
    height: 440px;
    width: 380px;
    margin-top: 10px;
}

.bgFonCraft {
    /* margin-top: 30px; */
    width: 380px;
    height: 531px;
    /* background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2)); */
}

#inf__num {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inf__num {
    margin-left: 8px;
}

#inf__num div {
    margin-right: 8px;
}

#inf__num .inf__num_1 {
    margin-right: 12px;
}

.leaf__one__description div {
    text-align: center;
}

/* Price */
.leaf__one__price {
    width: 180px;
    height: 68px;
    background: linear-gradient(180deg, #2F3340 0%, #292C36 100%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 24px;
    margin-left: 100px;
    position: relative;
}

.leaf__one__button {
    width: 152px;
    height: 30px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    border-radius: 15px;
    border: none;
    margin-top: 13px;
    margin-left: 114px;
    cursor: pointer;
}

.leafWallet {
    position: absolute;
    top: 0px;
    right: 11px;
    font-size: 32px;
    font-weight: 400;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.leafSpn {
    position: absolute;
    top: 36px;
    right: 11px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.leafPrice {
    position: absolute;
    top: 30px;
    left: 11px;
    font-size: 32px;
}

.inf__leaf {
    width: 94px;
    height: 21px;
    margin-left: 9px;
}

#inf__num:not(:first-child) {
    margin-top: 2px;
}

.inf__leaf:not(:first-child) {
    margin-left: 8px;
}

/* mag */
.infinity__one__image,
.mystery__one__image,
.starter__one__image,
.supreme__one__image,
.ticket__one__image,
.gold__one__image,
.silver__one__image,
.leaf__one__image,
.promo__one__image,
.rare__one__image,
.epic__one__image,
.uncommon__one__image,
.legend__one__image,
.common__one__image,
.violetta__one__image,
.mag__one__image,
.steel__one__image,
.frost__one__image,
.sunshine__one__image,
.mag__one__image {
    background-repeat: no-repeat;
    width: 170px;
    height: 238px;
    margin-left: 32px;
    background-size: 100%;
}

/* ------- */
.gold__one__image {
    background: url(../../img/elitPrem.webp);
    background-size: 100%;
}

.silver__one__image {
    background: url(../../img/basicPrem.webp);
    background-size: 100%;
}

.infinity__one__image,
.mystery__one__image,
.starter__one__image,
.supreme__one__image,
.promo__one__image,
.ticket__one__image,
.gold__one__image,
.silver__one__image,
.rare__one__image,
.epic__one__image,
.uncommon__one__image,
.legend__one__image,
.common__one__image {
    margin-left: 0;
}

.leaf__one__block {
    justify-content: center;
}

.mag__one__image {
    background-image: url(../../img/nevmag.webp);
}

/* steel */

.steel__one__image {
    background-image: url(../../img/newSteelade\ \(2\).webp);
}

/* frost */
.frost__one__image {
    background-image: url(../../img/newFrostee\ \(2\).webp);
}

.sunshine__one__image {
    background-image: url(../../img/newSunshine\ \(1\).webp);
}

.violetta__one__image {
    background-image: url(../../img/newVioletta\ \(1\).webp);
}

.common__one__image {
    background-image: url(../../img/newCommon\ Rune.webp);
}

.legend__one__image {
    background-image: url(../../img/newLegend\ Rune.webp);
}

.uncommon__one__image {
    background-image: url(../../img/newUncommon\ Rune.webp);
}

.epic__one__image {
    background-image: url(../../img/newEpic\ Rune.webp);
}

.rare__one__image {
    background-image: url(../../img/newRare\ Rune.webp);
}

.promo__one__image {
    background-image: url(../../img/238Prommo.webp);
}

.ticket__one__image {
    background-image: url(../../img/tic.webp);
    background-size: 100%;
}

.supreme__one__image {
    background-image: url(../../img/suprame.webp);
    background-size: cover;
}

#craft .starter__one__image {
    background-image: url(../../img/tmpl/749989.webp) !important;
    background-size: contain !important;
    background-position: center center;
    width: 216px;
}

#craft .mystery__one__image {
    background-image: url(../../img/tmpl/749990.webp) !important;
    background-size: contain !important;
    background-position: center center;
    width: 216px;
}

#craft .infinity__one__image {
    background-image: url(../../img/tmpl/749991.webp) !important;
    background-size: contain !important;
    background-position: center center;
    width: 216px;
}


.top__menu__name.Starter,
.top__menu__name.Mystery,
.top__menu__name.Infinity,
.top__menu__name.Supreme {
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));

}

#Pass .premPass {
    width: 240px;
}

#Pass .icon__modal {
    right: -124px;
}

#Pass .SpmSummToken {
    display: flex;
}

#Pass .Craft__price {
    height: 49px;
}

.boxExt .player {
    display: none;

}

.bgFonCraft .boxExt .title {
    right: 137px;
}

.CRAFT1 .icon__spn__p p {
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-weight: 400;
    top: -34px;
}

.leftSumm .icon__sum__spn p {
    top: -36px;
    right: 12px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-weight: 400;
    text-align: right;
}

.spnWax {
    position: absolute;
    top: -45px;
    right: -11px;
    font-size: 16px;
    color: var(--Text-2, #9AA0B0);
    transform: rotate(-360deg);
    text-align: center;

}

.spnWax span {
    position: absolute;
    top: 10px;
    right: 4px;
}

.rightSumm .icon__sum__wax p {
    top: -16px;
    right: -42px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
}

#craft #Pass .contSpn {
    left: -22px;
    top: -52px;
    text-align: right;


}

#Boxes .icon__sum p {
    text-align: right;
}

#craft #Pass .contWax {
    left: 119px;
    top: -52px;
    text-align: left;
}

@media (max-width: 1024px) {
    .craft__cards {
        /* margin-bottom: 32px; */
        display: flex;
    }

}

@media (max-width: 768px) {
    .craft {
        flex-direction: column;
    }

    .frost__one__info .top__menu__name,
    .leaf__one__info .top__menu__name,
    .mag__one__info .top__menu__name,
    .steel__one__info .top__menu__name,
    .sunshine__one__info .top__menu__name,
    .violetta__one__info .top__menu__name {
        position: absolute;
        top: 607px;
    }

    .craft__right__content {
        margin-left: 0;
        margin-bottom: 110px;
    }

    .craft__left__content {
        margin-bottom: 61px;
    }

}

@media (max-width: 610px) {

    .cfon,
    .craft__cards {
        width: 300px;
    }

    .craft__right__content {
        width: 300px;
        margin-bottom: 138px;
    }



    .steel__one__image {
        background: url(../../img/160Steelade.webp);

    }

    .craft__menu {
        width: 300px;
    }

    .craft__right__content .title {
        right: 20px;
    }



    /* frost */
    .frost__one__image {
        background-image: url(../../img/160Frostee\ \(2\).webp);
    }

    .leaf__one__image {
        background-image: url(../../img/160Leaflynn2\ \(1\).webp);
    }

    .sunshine__one__image {
        background-image: url(../../img/160Sunshine\ \(1\).webp);
    }

    .violetta__one__image {
        background-image: url(../../img/160Violetta\ \(1\).webp);
    }

    .common__one__image {
        background-image: url(../../img/160Common\ Rune.webp);
    }

    .legend__one__image {
        background-image: url(../../img/160Legend\ Rune.webp);
    }

    .uncommon__one__image {
        background-image: url(../../img/160Uncommon\ Rune.webp);
    }

    .epic__one__image {
        background-image: url(../../img/160Epic\ Rune.webp);
    }

    .rare__one__image {
        background-image: url(../../img/160Rare\ Rune.webp);
    }




    .leaf__one__image,
    .promo__one__image,
    .rare__one__image,
    .epic__one__image,
    .uncommon__one__image,
    .legend__one__image,
    .common__one__image,
    .violetta__one__image,
    .mag__one__image,
    .steel__one__image,
    .frost__one__image,
    .sunshine__one__image,
    .mag__one__image {
        margin-left: 0px;
        margin-top: 6px;
        background-repeat: no-repeat;
        width: 167px;
        height: 224px;
    }

    .leaf__one__description {
        width: 115px;
        margin-left: 0;
    }

    #craft .leaf__one__button {
        margin-left: 74px;
    }
}