#claim {
    width: 540px;
    height: 180px;
    position: relative;
}
#modal8 #claim {
    width: 540px;
    height: 300px;
  
}

.contentServer {
    display: flex;
    flex-direction: column;
    height: 130px;
}

.firstBoxServ {
    display: block;
}


.icon__claim {
    background: url(../../img/icon__purchase.svg);
    background-repeat: no-repeat;
    width: 30px;
    height: 20px;
    position: absolute;
    top: 24px;
}

.icon_tic{
    background: url(../../img/Group\ 93\ \(1\).webp);
    background-repeat: no-repeat;
    height: 26px;
}


.claim__price {
    width: 167px;
    height: 20px;
    display: flex;
    justify-content: space-between;
}

#modal8 .claim__price {
    width: 167px;
    position: absolute;
    top: 82px;
    left: 183px;
    display: flex;
    justify-content: space-between;
}

#moda6 {
    overflow-y: scroll;
}

#modal6 .infoContentPass{
    width: 1100px;
    height: 610px;
}
.premModal {
    display: flex;
    justify-content: space-between;
    width: 1100px;
    height:auto;
    margin: 0 auto;
}
.rightTopPass{
    margin: 0 auto;
}
.boxLogin .dropdown__wrapper{
    background: var(--dark-elements, rgba(0, 0, 0, 0.40));
    backdrop-filter: blur(2px);
}
.boxLogin .dropdown__options{
    top: 32px;
    width: 200px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.80));
    backdrop-filter: blur(2px);
    border-radius: 15px;
    position: absolute;
    padding-top: 0px;
    padding-bottom: 15px;
}
#modal8 .dropdown__optionsAtomic, 
#modal8 .dropdown__options {
    top: 19px;
    left: -3px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.dropdown__optionsAtomic .optionAt{
    font-size: 20px;
}
#modal8 .optioAtn:not(:first-child),
#modal8 .option:not(:first-child) {
    margin: 8px 0px 4px 8px;
    padding-bottom: 3px;
}

#claim__server .claim__price {
    width: 200px;
    height: 30px;
}

.claim__stor {
    margin-top: 12px;
}

#modal8 .option:not(:first-child) {
    margin: 8px 0px 4px 8px;
    padding-bottom: 3px;
}
#modal8 .dropdown__header{
    line-height: 21px;

   
}

.optionAt {
    height: auto;
    line-height: 1;

  
}
#modal8 .clame__wallet,
#modal8 .dropdown__wrapper{
    border-radius: 10px;
}


.many__wallet {
    line-height: 24px;
    width: 74px;
    text-align: right;
}

.name__price {
    position: absolute;
    top: -3px;
    right: 9px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.clame__wallet {
    display: flex;
    justify-content: space-between;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 112px;
    height: 20px;
    margin-top: 14px;
    position: relative;
    z-index: 5;
}

#claim__server .clame__wallet {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    backdrop-filter: blur(10px);
}
#modal8 .dropdown__optionsAtomic, 
#modal8 .dropdown__options {
    width: 175px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.80));
    backdrop-filter: blur(2px);
}

#modal8 .option {
    height: 12px;
}

#claim .q {
    width: 414px;
    height: 32px;
    top: 52px;
    right: 63px;
}

.icon_server {
    background: url(../../img/Group\ 115.svg);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
}

.server {
    font-size: 32px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 16px;
}

.dropdown__header {
    width: 200px;
    margin-left: 8px;
    line-height: 26px;
    cursor: pointer;
}


#modal8 .dropdown__options {
    border: none;
    width: 180px;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 114px;
    left: 183px;
    z-index: 7;
    cursor: pointer;
    border-radius: 10px;
}

.option {
    height: 8px;
    margin-left: 8px;
    cursor: pointer;

}

.optionAt:first-child {
    margin-left: 8px;

}
.optionAt:not(:first-child),
.option:not(:first-child) {
    margin: 0px 6px 4px 8px;
}

.optionAt:hover, 
.option:hover {
    color: #F3AE2D;



}


.vector__open {
    background: url(../../img/Vector\ 7\ \(1\).svg);
    background-repeat: no-repeat;
    height: 5px;
    width: 10px;
    position: absolute;
    top: 7px;
    right: 8px;
    cursor: pointer;
}

.upside-down {
    transform: rotate(180deg);
    height: 5px;
    width: 10px;
    top: 7px;
    right: 8px;
    z-index: 5;
}


#serv {
    width: 200px;
    line-height: 20px;
}




/* premium */

.icon_premium {
    background: url(../../img/Group\ 116.svg);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
}

#slots {
    width: 540px;
    height: 370px;
    position: relative;
}

#premium {
    width: 540px;
    height: 610px;
    position: relative;
}

#premium .q {
    position: relative;
    text-align: left;
    width: 251px;
    height: 18px;
    bottom: 193px;
    right: -7px;
}

#modal6 #premium .q {
    width: 364px;
    right: -7px;
    bottom: 126px;
}

#modal6 .titleActiv {
   text-align: center;
}

#premium .server {
    color: #fff;
}
.icon_bornNft {
    background: url(../../img/Frame\ 1.webp);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    margin-bottom: 16px;
}

#premium .slider__modalRuna {
    position: absolute;
    bottom: 32px;
}

#premium .content_premium {
    width: 359px;
    top: -183px;
}

#modal17 #premium .content_premium{
    width: 127px;
}
#modal17 #premium {
    height: 523px;
}

#modal17 #premium .q {
    bottom: 184px;
    
}

#modal17 #premium .content_premium {
   width: 300px;
}

.fonSpn{
    position: relative;
    width: 282px;
    height: 24px;
    line-height: 26px;
    padding-left: 18px;
    border-radius: 15px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    color: #fff;
}

#modal17 #premium .content_premium {
    top: -168px;
}
#modal17 .content_premium .fonSpn:not(:last-child) {
    margin-bottom: 4px;
}

#modal17 #premium .contentBlockTicket {
    position: absolute;
    top: 254px;
    left: 152px;
}
.contPriceSpn {
    position: absolute;
    top: -1px;
    right: 12px;
    display: flex;
    justify-content: space-between;
    width: 78px;
}

#modal17 .contentBlockTicket {
    display: flex;
    justify-content: space-between;
}

#modal17 #premium .contentBlockTicket {
    width: 256px;
    height: 30px;
}
#modal17 .fonTicketTop {
    position: relative;
    width: 96px;
    height: 20px;
    border-radius: 26px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

#modal17 .contPriceSpn .icon__spn p {
    line-height: 29px;
}

.priceSpnColor {
    background: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 3px;
    font-size: 20px;
}
#modal8 .q.server {
    color: #fff;
}
#claim .secondServ .q.server{
    position: absolute;
    top: 140px;
  
}

#claim .secondServ .claim__price{
  top: 170px;
  left: 183px;
  
}
#modal8 .dropdown__optionsAtomic{
    border: none;
    width: 180px;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 204px;
    left: 183px;
    z-index: 1;
    cursor: pointer;
    overflow-x: hidden;
    white-space: initial;
    overflow-y: scroll;
   border-radius: 10px;
}
.dropdown__optionsAtomic::-webkit-scrollbar{
    width: 5px;
    height: 30px;
   margin-top: 5px;
}
.dropdown__optionsAtomic::-webkit-scrollbar-track{
	background-color: var(--glass-card,rgba(35,38,48,.5));
	border-radius: 10px;
	-webkit-box-shadow: 5px 5px 5px -5px rgba(34,60,80,.2) inset;
}
.dropdown__optionsAtomic::-webkit-scrollbar-thumb{
    background: linear-gradient(180deg, #A3A7B6 0%, #4E556B 100%);
	border-radius: 10px;
	width: 20px;
}
#modal8 .clame__wallet,
#modal8 .dropdown__wrapper{
    backdrop-filter:none;
}
#modal17 .fonTicketTop .nambTicket {
    width: 45px;
    position: absolute;
    top: 4px;
    right: 36px;
    text-align: right;
}


#premium .fonTicketTop {
    width: 90px;
}
#modal17 .fonTicketTop {
   margin-top: 5px;
}
#modal17 .icon__spn p{
   line-height: 24px;
}

#premium .contentBlockTicket {
    width: 185px;
    height: 30px;
}

#modal6 #premium .content_premium {
    top: -110px;
}

.contentBlockTicket .icon_premium {
    position: absolute;
    top: 3px;
    right: 6px;
}

#premium .contentBlockTicket {
    position: absolute;
    top: 309px;
}

#premium .clame__wallet {
    margin-top: 50px;
}

#premium .btn__modal {
    bottom: 19px;
}

#prem .days {
    top: 1px;
    width: 81px;
    left: 196px;
}

#prem {
    display: flex;
    width: 279px;
    height: 18px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    border-radius: 20px;
    position: absolute;
    top: 300px;
    right: 130px;
}

.icon__account {
    background: url(../../img/Group\ 117.svg);
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
    margin-top: 1px;
    margin-left: 8px;
    margin-right: 4px;
}

.prem__account {
    line-height: 20px;
}

.days {
    background: #31260A;
    border-radius: 26px;
    color: #DDDEDF;
    width: 61px;
    height: 16px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    left: 196px;
    top: 0px;
}

#slots .btn__modal button:last-child,
#premium .btn__modal button:last-child {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    cursor: pointer;
}
.rightBoxPass .q{
    right: 119px;

}

/* slots */

.wallet__info__price {
    display: flex;
    justify-content: space-between;
}

#slots {
    display: flex;
    justify-content: center;
}

.icon_slots {
    background: url(../../img/Group\ 118.svg);
    background-repeat: no-repeat;
    height: 30px;
}

.content__slots {
    display: flex;
    justify-content: space-between;
    width: 186px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #9AA0B0;

}

.total__s {
    width: 68px;
    height: 20px;
    line-height: 26px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 26px;
    color: #fff;

}

.total__add {
    width: 50px;
    height: 20px;
    line-height: 26px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 26px;
    color: #fff;
}

.content__slot {
    color: #9AA0B0;
}

.title__slots {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 400;
    position: absolute;
    top: 53px;
}

.sots__block {
    margin-top: 25px;
}

.sots__block .content__slots:last-child {
    margin-top: 4px;
}

.number__slots {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.number__slots .content__slots {
    width: 270px;
}

.box__slots {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 16px;
}

.blockPriceRegister .parag__modalRuna {
   margin: 10px 0px 0px 10px;
}

.blockPriceRegister  .Craft__price{
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 10px;
}

#modal14 .Craft__price{
    width: 170px;
    height: 49px;
}

#modal14 .icon__modal {
    right: -53px;
    top: -46px;
}
#modal14 .price p{
    color: var(--Text-2, #9AA0B0);
}

#modal14 .icon__spn__p p {
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 0.6;
    font-weight: 400;
}

#modal14 .icon__spn__p p {
    right: 2px;
    top: -35px;
}
#modal14 .OfferTag__content #runs__slider {
    margin-bottom: 0;
   

}


#modal6 .papBoxHeader {
    margin-bottom: 48px;
}
.premModal #premium {
    display: flex;
    justify-content: flex-end;
}

#modal14 .modal {
    height: 470px;
}

/* #modal14 .price p {
    top: 23px;
} */

#modal14  .icon__sum p {
    left: 5px;
}


#modal14 .btn__runs {
    padding-bottom: 20px;
}

#modal14 .Craft__price{
    width: 170px;
    height: 49px;
}
.plus {
    background: url(../../img/Group\ 126.svg);
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    margin-left: 4px;
    line-height: 23px;
    cursor: pointer;
}

.minus {
    background: url(../../img/Group\ 124.svg);
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    margin-right: 4px;
    line-height: 23px;
    cursor: pointer;
}

#slots .block__sum__slotts {
    background: none;
    box-shadow: none;
}

#slots .price {
    width: 26px;
}

.block__sum__slots {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 50px;
    margin-right: 6px;
    margin-top: 10px;

}


.icon__sum__wax p {
    text-align: right;
    font-size: 36px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.icon__wax__p p {
    line-height: 20px;
    margin: 0;
    text-align: right;
}

.Craft__price .price {
    width: 16px;
}

.icon__modal__wax {
    width: 80px;
    height: 50px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: relative;
}

#modal8 .dropdown__wrapper {
    width: 180px;
    border-radius: 10px;
}
.rightBoxPass {
    position: relative;
    width: 540px;
    height: 301px;
    border-radius: 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}
.rightBoxPass .btn__modal {
    left: 140px;
}
.rightBoxPass .titleActiv {
    width: 300px;
    color: #fff;
    position:none;
}

#modal6 .rightBoxPass:not(:last-child) {
    margin-bottom: 8px;
    
}  

.premModal .fon__born__tocket:last-child {
    margin-bottom: 53px;
}
#modal6 .slider__modalRuna{
    position: absolute;
    top: 60px;
    left: 45px;
}

#modal6 .server {
    bottom: 207px;
}


.Ticket1 .swiper-wrapper {
    width: 300px;
    margin-top: 22px;
}
#modal6 .btn__modal {
    width: 329px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#modal6 .tokenDiscont {
    line-height: 24px;
}
.rightTopPass .btn__modal .fonTicketTop {
    width: 125px;
    height: 24px;
    border-radius: 26px;
    line-height: 24px;
}
.rightTopPass .btn__modal .nambTicket {

    text-align: right;
    padding-right: 20px;
}
.rightTopPass .btn__modal .contentBlockTicket {
    width: 197px;
    height: 25px;
    border-radius: 26px;
    align-items: center;
    margin-top: 12px;
}

.premModal .contentBlockTicket {
    margin-top: -9px;
}


#modal17 .icon__spn p {
    font-size: 20px;
}
#modal6 .rightBoxPass .btn__modal {
    bottom: 24px;
    left: 104px;
}
#modal17 #spinn__slider {
    margin-bottom: 34px;
    padding-right: 7px;
    padding-left: 7px;
} 
/*  */
 #modal6 .rightBoxPass .titleActiv,
.nft__card__bottomAssets .title__modalRuna {
    text-transform: inherit;
}

#modal18 #claim {
    width: 540px;
    height: 221px;
}
#modal18 #claim .q {
    top: 15px;
}
#modal18 .btn__modal {
    position: absolute;
    bottom: 31px;
}

.contAttention {
    width: 300px;
    height: 86px;
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    line-height: 16px;
    margin-bottom: 8px;
}

.iconSpinnAll {
    background: url(../../img/Group\ 620.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;

}

#modal19 .content__slot {
    width: 300px;
    height: 27px;
    line-height: 16px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
}
#modal19 #slots {
    height: 284px;

}
#modal19 .sots__block {
    margin-top: 0px;

}
#modal19 .total__s {
    width: 96px;
    height: 20px;

}
#modal19 .content__slots {
    width: 219px;

}
#modal19 .btn__modal {
    bottom: 32px;

}
#modal19 .btn__modal button {
   margin-top: 0;

}

#modal19 .title__slots {
    top: 54px;
}
#modal19 .total__s {
    line-height: 23px;
}

#modal19 #slots .btn__modal button:last-child {
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}
#modal19 .content__slots {
    color: #fff;
}
#modal19 #slots .btn__modal button:last-child:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    border-radius: 20px;
}
#modal19 .sBottom .total__s {
    display: flex;
    justify-content: space-around;
}
#modal19 .spnBg {
    top: 5px;
    line-height: 14px;
    right: 9px;
}
#modal19 .title__slots {
    top: 54px;
}
#modal14 .block__sum {
    background: none;
}
.sBottom {
    position: relative;
  

}

#modal6 .papBoxHeader:hover {
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    box-shadow: none;
}
@media (max-width: 1100px) {

    #modal6 .btn__modal {
        width: 283px;
        height: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } 

    #modal6 .rightBoxPass .btn__modal {
        bottom: 24px;
        left: 12px;
    }

    #modal6 .slider__modalRuna {
        
        top: 43px;
        
    }

    #modal6 .server {
        bottom: 216px;
        width: 200px;
    }

    .rightTopPass .btn__modal .contentBlockTicket{
        margin-top: -9px;
    }
    #modal6 .slider__modalRuna{
        left: 89px;
    }
  
    #premium .contentBlockTicket {
        top: 300px;
    }
    #modal6 {
        height: 100%;
    }
    #modal6 .modal {
        height: 610px;
        margin-top: 600px;
    }


    #modal6 #premium .q {
        width: 270px;
        bottom: 132px;
    }
    #modal6 .papBoxHeader {
        margin-top: 10px;
    }
    .rightBoxPass:first-child{
        margin-top: 24px;
    }
    #modal6 .rightBoxPass:not(:last-child){
        margin-bottom: 24px;
    }
    #modal6 .rightBoxPass:last-child{
        margin-bottom: 24px;
    }
    .premModal {
        display: flex;
        flex-direction: column;
        width: 1100px;
        margin: 0 auto;
    }
    #modal6 .fon__born__tocket {
        width: 240px;
    }

    #modal6 #spinn__slider {
        width: 116px;
    }
    #modal6 #premium {
        width: 300px;
    }
    #modal6 #spinn__slider {
        width: 116px;
    }
    #modal17 #spinn__slider {
        margin-bottom: 34px;
    }
    .rightBoxPass {
        width: 300px;
    }
    .rightBoxPass .q{
        width: 200px;
        right: 49px;

    }

}

@media (max-width: 768px) {

  
   
    .rightTopPass {
        margin: 0 auto;
    }
    
    #modal8 #spinn__slider {
        width: 231px;
    
    }
    
    .rightBoxPass .btn__modal {
        left: 16px;
    }
    #modal17 #premium .contentBlockTicket{
        left: 24px;
    }
    .fonSpn {
        width: 258px;
    }
    #modal8 .dropdown__options {
        left: 64px;
        border: none;
        width: 180px;
        top: 116px;
    }

    .modal__contentRuna {
        width: 320px;
       
    }

    #modal3 .btn__runs {
        padding-top: 40px;
    }

    .swiper-wrapper {
        width: 300px;
        margin-top: 90px;
    }

    #modal6 .swiper-wrapper {
        width: 300px;
        margin-top: 0px;
    }
    #premium {
        width: 300px;
        height: 620px;
    }

    #premium .q {
        width: 280px;
    }

    #prem {
        width: 222px;
        height: 35px;
        left: 40px;
        top: 335px;

    }

    #modal6 .icon__account {
        margin-top: 10px;
    }
    
    #prem .days {
        top: 16px;
        width: 81px;
        left: 70px;
    }

    #spinn__slider {
        width: 150px;
        height: 179px;
        /* margin-bottom: 5px; */
    }



    #claim {
        width: 300px;
    }

    #modal2 #claim {
        height: 210px;
    }

    #claim .q {
        width: 175px;
    }

    #modal2 #claim .q {
        width: 243px;
        right: 27px;
    }

    #modal2 .claim__price {
        height: 2px;
    }

    #modal11 .u {
        max-width: 247px;
        margin-top: 10px;
    }

    #modal2 .claim__stor {
        margin-top: 10px;
    }

    #claim__unstake {
        width: 300px;
        height: 230px;
    }

    #modal14 .modal__contentRuna {
        height: 328px;
    }
    #premium .contentBlockTicket {
        position: absolute;
        top: 312px;
    }

    #premium .q.server{
        text-align: center;
        bottom: 217px;
    ;
    }
    #modal8 #claim {
        width: 300px;
    }
    #modal8 .firstBoxServ #claim .q{
        width: 263px;
        height: 32px;
        top: 61px;
        margin: 0 auto;
        right: 0px;
        
    }

  
    #modal8.q.server{
        right: 0;
        color: #fff;
    
    }
    #modal8 .secondServ #claim .q{
        width: 263px;
        top: 126px;
        right: 0px;
    }

    #claim .secondServ .q.server{
        top: 122px;
    }
    #claim .secondServ .claim__price,
    #modal8 .claim__price{
        left: 60px;
    }
    #modal8 .dropdown__options,
    #modal8 .dropdown__optionsAtomic {
        left: 59px;
      
    }
    


}