.Ticket1 {
    margin-top: 40px;
    width: 785px;
}

.Ticket1 .nft__card__content{
    position: relative;
}


.Ticket1 #t .nft__card__content, .Ticket1 .w .nft__card__content {
    height: 210px;
}
.ticket  {
    width: 308px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.nft__card__bottomAssets .ticket p {
    padding-top: 34px;
}

.ticket p {
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    line-height: 16px;
    margin: 0;
    padding-top: 24px;
   
}

.contentBlockTicket {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 30px;
    width: 255px;

}

.fonTicketTop{
    position: relative;
    width: 120px;
    height: 30px;
    border-radius: 5px;
    background-color: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
}

.fonTicketTop .icon__smoll__tic {
    right: 9px;
}
.fonTicketTop .nambTicket{
    margin: 1px 0px 0px 9px;
}

.nft__card__token .contentBlockTicket:last-child {
    margin-top: 8px;
}

.Ticket1 #t .bottom__menu {
    margin-top: 12px;
}

.Ticket1 .bottom__menu #b:disabled:hover,
.Ticket1 .bottom__menu button:disabled:hover{
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    box-shadow: none;
    animation: none;
    cursor: inherit;
}

.btnTicket {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
}


.bottomAssetsPage .ticket  {
    margin-bottom: 24px;
}

.Ticket1 input{
    width: 60px;
    background: none;
}

.Ticket1 input[type="number"]:focus {
  outline: none;
  background-image: none;
}
.placeholder {
    margin: 4px 0px 0px 0px;
}

.nft__card__bottomAssets {
    position: relative;
    width: 380px;
    height: 301px;
    border-radius: 10px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin-top: 30px;
}
.born__ticket {
    margin: 0 auto;
    width: 324px;
    height: 164px;
}
.fon__born__tocket {
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 24px;
    border-radius: 15px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    padding: 0 12px;
    line-height: 25px;
}

.fon__born__tocket:not(:last-child){
    margin-bottom: 4px;
}

.nft__card__bottomAssets .title__modalRuna {
    line-height: 70px;

}

.bottomAssetsPage .w {
    width: 380px;
}

#spinn__slider {
    width: 316px;
    height: 140px;
    margin-bottom: 24px;
}

.sliderBornTicket{
    position: absolute;
    top: 49px;
    left: 23px;

}

.bottomAssetsPage .nft__card {
    position: relative;
}

.bottomAssetsPage .nft__card #spinn__slider{
    width: 316px;
}
.bottomAssetsPage .btn__runs{
    position: absolute;
    bottom: 16px;
    left: 56px;
}

.ticketPap .fonTicketTop {
    width: 96px;
    height: 20px;
    border-radius: 20px;
    line-height: 19px;
}

.ticketPap .contentBlockTicket{
    width: 168px;
    height: 20px;
}

.ticketPap .textTicket{
   line-height: 20px;
}
.bottomAssetsPage .ticketPap{
    left: 37px;
}
.btn__runs.ticketPap {
    width: 309px;
    height: 24px;
    align-items: center;
}
.ticketPap .nambTicket{
    text-align: center;
    margin: 2px 0px 0px 0px; 
}
.ticketPap .sliderBornTicket {
    position: absolute;
    top: 47px;
    left: 31px;
}
.ticketPap .swiper-wrapper {
    margin-top: 2px;
}
.bornTicket #spinn__slider {
   height: 190px;
}
@media (max-width: 790px){
    .bornTicket .nft__card #spinn__slider {
        width: 121px;
    }
    .bornTicket#spinn__slider {
     
        margin-bottom: 17px;
        margin-left: 58px;
    }
    .sliderBornTicket {
        position: absolute;
        top: 47px;
        left: 131px;
    }
}

@media (max-width: 610px) {
    .ticket p{
        font-size: 16px;
    }
    .nft__card__bottomAssets {
        width: 300px;
    }
    .born__ticket {
       margin: 0;
      width: 300px;
    }
    .fon__born__tocket {
        width: 276px;
    }
    .Ticket1 .w {
        margin-bottom: 0px;
    }

    .bornTicket .nft__card__bottomAssets {
        margin-bottom: 130px;
    }
    .btn__runs.ticketPap {
        flex-direction: column;
        left: 0;
        height: 70px;
    }
    .ticketPap .contentBlockTicket{
       margin-bottom: 15px;
    }
    .bornTicket .nft__card #spinn__slider {
        width: 121px;
    }
    .bornTicket#spinn__slider {
        margin-bottom: 17px;
        margin-left: 58px;
    }
    .sliderBornTicket {
        position: absolute;
        top: 24px;
        left: 90px;
    }
    
}