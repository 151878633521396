/* first_card */
.tickets .offer_card p  {
    font-size: 16px;
}
/* first_card */

.tickets .second__card,
.tickets .offer_card {
    width: 380px;
    height: 116px;
}
/* first_card */
.tickets .offer_card p {
    width: 347px;
    height: 50px;
    padding: 15px 15px 15px 15px;
   
}
.tickets .offer_card {
    margin-bottom: 8px;

}
   
/* .fon__bg__slider {
    
    position: absolute;
    left: -7px;
    width: 419px;
    height: 520px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 6.29%, #D9D9D9 89.41%, rgba(217, 217, 217, 0.00) 100%);
} */
.numberTic{
    display: flex;
    width: 183px;
    height: 30px;
    margin: 0 auto;
    margin-top: 18px;
    margin-bottom: 12px;
}

.numberTic p {
    margin-right: 17px;
    line-height: 8px;
}


/* #modal17 .WinPage {
    background: none;
} */
#modal17 .box__slots {
    margin-top: 12px;
}

#modal17 .LosePage .container__game p {
    padding-top: 10px;
}

#modal17 .total__add {
    font-size: 20px;
    width: 50px;
    height:19px;
    line-height: 23px;
    padding: 1px 0px 0px 0px;
    
}


#modal17 .container__game .title span {
    font-size: 32px;
    text-transform: uppercase;
}
#modal17 .container__game .box__slots .minus,
#modal17 .container__game .box__slots .plus {
    font-size: 20px;
    text-transform: uppercase;
}

.tickets .DropBuy {
    width: 136px;
    height: 20px;
    line-height: 19px;
    margin: 8px 0px 1px 25px;
}

#modal17 .container__game .btn__modal {
    left: 144px;
}

.icon__smoll__tic{
    background: url(../../../img/Ticket\ 700x500\ 1\ \(1\).webp);
    width: 36px;
    height: 27px;
    position: absolute;
    right: 43px;
    bottom: 2px;
}

.fon__tic {
    background: url(../../../img/Rectangle\ 20.webp);
    width: 68px;
    height: 30px;
}

#modal17 .nambTicket {
   color: #fff;
   width: 10px;
   line-height: 13px;
}

.container__ticket {
    display: flex;
    position: relative;
}


.tickets  .name__state {
    top: 48px;
    right: -31px;;
    position: absolute;
    width: 100px;
    height: 20px;
   
}

.info__card__uncommon .infoProgressRegist {
    position: absolute;
    left: 0px;
    top: -57px;
}
.RegistlistOffer,
.Winnerslist,
.Registlist {
    border-radius: 15px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    text-align: center;
    font-size: 16px;
    width: 130px;
    height: 30px;
    cursor: pointer;
    line-height: 31px;
}
/* .WinnerslistOffer,
.RegistlistOffer {
    text-align: center;
    font-size: 16px;
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 122px;
    position: absolute;
    bottom: 30px;
    left: 43px;
    cursor: pointer;
    line-height: 0.6;
    font-weight: 400;
} */


.rightAssedsCard .Registlist{
    position: absolute;
    bottom: 33px;
    left: 24px;
}

.rightAssedsCard .RegistlistOffer{
    position: absolute;
    line-height: 22px;
    height: 20px;
    bottom: 28px;
    left:43px;
}

.rightAssedsCard .Winnerslist{
    position: absolute;
    bottom: 314px;
    left: -274px;
}
.tickets .Registlist{
    bottom: 35px;
    left: 50px;
}

.tickets .Winnerslist{
    bottom: 38px;
}

.infoProgressRegist{
   position: absolute;
   left: 40px;
   top: 14px;
}

.infoProgressRegist .progress__fon{
    width: 134px;
    height: 6px;
    position: absolute;
    top: 1px;
    left: 2px;
}

.infoProgressRegist .progressSpeed {
    display: flex;
    position: absolute;
    top: 29px;
    left: -7px;
    justify-content: space-around;
    width: 136px;
}

.infoProgressRegist .speed {
    font-size: 16px;
}


.BlockProgressInfo{
    position: relative;
    width: 136px;
    height: 16px;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

.BlockProgressInfo span {
    position: absolute;
    left: 20px;
    bottom: -5px;
}


.circtbtn {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: none;
    position: absolute;
    top: 2px;
    left: 2px;
}


.tickets .info__card__uncommon {
    position: absolute;
    left: 8px;
    top: 65px;
}

.BlockMyAvailable {
    margin: 0 auto;
    margin-bottom: 12px;
   
}

.tickets  .cardChange {
    width: 136px;
    height: 190px;
    position: absolute;
    top: 32px;
    right: 122px;
    transform: rotate(3deg);
    z-index: 9;
}
.tickets .cardChange.starter__one__image,
.tickets .cardChange.mystery__one__image,
.tickets .cardChange.infinity__one__image {
    width: 225px;
    height: 268px;
    position: absolute;
    top: 23px;
    right: 81px;
    transform: rotate(3deg);
    z-index: 9;
}

#modal14 .modal__content.activerun + .wrapper {
    backdrop-filter:none;
  }

.icon__reg__ticket{
    background: url(../../../img/Frame\ 1.webp);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.sliderOfferTag {
    height: 450px;
    width: 419px;
    overflow-y: auto;
    overflow-x: hidden;
   
}

.progressFonOfferTag {
    width: 134px;
    height: 8px;
    border-radius: 10px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    overflow: hidden;
    margin-top: 4px;
    position: relative;

}
  

.colorPassProgress {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
}
.slider-slide {
    border-bottom: none;
}

.dark_fon {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}

.last-slide {
    background: rgba(0, 0, 0, 0.20);
}

.tickets #infoRightAsseds {
    position: absolute;
    top: 14px;
    left: 210px;
}

.tickets  .AssedsCardMenubottom {
    width: 380px;
    height: 114px;
 
}

.tickets .AssedsCardMenubottom .Group {
    position: absolute;
    top: 58px;
    right: 23px;
}

.icon__infinity, 
.icon__mystery,
.icon__starter,
.icon__ticketlotery,
.icon__legendrun,
.icon__violetrun,
.icon__rarerun,
.icon__uncomonrun,
.icon__commonrun,
.icon__magmus,
.icon__legend,
.icon__rare,
.icon__uncomon,
.icon__comonca,
.icon__epic {
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 8px;
    right: 36px;
    width: 192px;
    height: 100px;
}

.second__card .icon__infinity, 
.second__card .icon__mystery,
.second__card .icon__starter{
    background-color: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: bottom;

}

#BoUp .title__link.centerLink {
    margin-top: 14px;
    margin-left: 63px;
}

.iconWinnerBoostUp.Winner__common,
.iconWinnerBoostUp.Winner__leaf,
.iconWinnerBoostUp.Winner__violet,
.iconWinnerBoostUp.Winner__frosty,
.iconWinnerBoostUp.Winner__sunch {
    margin-left: 12px;
}

.icon__epic {
    background: url(../../../img/Group\ 373.webp);
}
.icon__legend {
    background: url(../../../img/Group\ 489\ \(1\).webp)no-repeat;
    background-size: 100%;
}
.icon__rare {
    background: url(../../../img/Group\ 490.webp);
}
.icon__comonca {
    background: url(../../../img/Group\ 492.webp);
}

.icon__uncomon {
    background: url(../../../img/Group\ 491.webp);
}
.basic__player{
    background: url(../../../img/basicPrem.webp);
    background-size: 100%;    
}
.OfferTag__block .supreme__player,
.OfferTag__block .gold__player,
.OfferTag__block .basic__player{
    background-repeat: no-repeat;
    background-position: bottom;
    border-radius: 10px;
    position: absolute;
    top: 8px;
    right: 36px;
    width: 192px;
    height: 100px;
}


/*  */
.icon__magmus {
    background: url(../../../img/loterMagmius.webp);
}

.icon__commonrun {
    background: url(../../../img/loterCommon\ Rune\ \(1\).webp);
}
.icon__uncomonrun {
    background: url(../../../img/loterUncommon\ Rune.webp);
}
.icon__rarerun {
    background: url(../../../img/loterRareRune.webp);
}
.icon__violetrun {
    background: url(../../../img/loterEpicRune.webp);
}
.icon__legendrun {
    background: url(../../../img/loterLegend\ Rune.webp);
}
.icon__ticketlotery {
    background: url(../../../img/loterticket.webp);
}

.starter__one__image {
    background-image: url(../../../img/Group\ 573.webp);
    background-size: 100%;
    width: 216px;
}
.mystery__one__image {
    background-image: url(../../../img/Group\ 571.webp);
    background-size: 100%;
    width: 216px;
}
.infinity__one__image {
    background-image: url(../../../img/Group\ 572.webp);
    background-size: 100%;
    width: 216px;
}
.supreme__one__image {
    background-image: url(../../../img/suprame.webp);
    background-size: 100%;
}
/* box */
.icon__starter {
    background-image: url(../../../img/start1.webp);
    background-size: 70%;
    
}
.icon__mystery {
    background-image: url(../../../img/mys2.webp);
    background-size: 70%;
    
}
.icon__infinity {
    background-image: url(../../../img/inf3.webp);
    background-size: 70%;
   
}

.OfferTacCircl .fonCircle2{
    display: none;
}
.tickets .BlockMyRegist{
    margin-top: 0px;
}

.tickets .fonCircle2 {
    background: url(../../../img/Glow\ Circle\ \(3\).webp);
    background-repeat: no-repeat;
    width: 416px;
    height: 227px;
    position: absolute;
    top: 107px;
    right: -14px;
    z-index: -1;
  
}

.topRightAssedsCard {
    position: relative;
}

.tickets .topRightAssedsCard {
    height: 290px;

}

.iconTikets {
    background: url(../../../img/Group\ 444.webp);
    background-repeat: no-repeat;
    width: 315px;
    height: 264px;
    position: absolute;
    left: 22px;
    z-index: 10;
    overflow: hidden;
}

.infoProgressRegist .DropBuy {
    width: 136px;
    height: 20px;
}

.tickets .BlockMyAvailable {
    background: none;
    width: 211px;
}

.tickets .fonTicketHeader {
    border-radius: 5px;
    position: relative;
    width: 100px;
    height: 30px;
    right: 8px;
    bottom: 8px;
}

.tickets  .icon__smoll__tic {
    right: 7px;
    bottom: 4px;
}

.info__card__uncommon .Reffled{
    position: absolute;
    left: 0px;
    top: -29px;
}

.tickets  .MyAvailable {
    line-height: 14px;
}

.tickets .fonTicketHeader:hover{
    box-shadow: none;
    animation: none;
   cursor: auto;
}

.tickets  .nambTicket {
    margin-left: 9px;
}
.Group .DropBuy{
    display: flex;
    justify-content: center;
    width: 170px;
    text-align: center;
    line-height: 29px;
    margin: 8px 0px 0px 0px;
}

.tickets .DropBuy {
    line-height: 19px;
    width: 130px;
    margin-left:25px;
}
.OfferTag__block .second__card  .DropBuy {
    display: flex;
    justify-content: center;
    width: 135px;
    margin: 8px 0px 0px 1px;
    text-align: center;
    line-height: 22px;
}
.OfferTag__block .second__card:last-child  {
    margin-bottom: 20px;

}

@media (max-width: 740px) {
    .circleFon {
        display: none;
    }

    #modal17 .container__game .btn__modal {
        left: 29px;
        width: 300px;
    }

    .tickets .fonTicketHeader {
        bottom: 2px;
    }

    #modal17 .LosePage .titleWinner {
        padding-top: 132px;
    }
    
    .BlockProgressInfo .circtbtn {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: none;
        position: absolute;
        top: 2px;
        left: 2px;
    }


}

@media (max-width: 610px) {

    .rightAssedsCard .RegistlistOffer {
     
        line-height: 21px;
     
        bottom: 23px;
        left: 8px;
    }
    .rightAssedsCard .Registlist {
        position: absolute;
        bottom: 33px;
        left: 10px;
        width: 120px;
    }

    .AssedsCardMenubottom .Craft__price {
        width: 170px;
    }

    .AssedsCardMenubottom .icon__modal{
        right: -54px;
    }
    .ContentList #WaxRef {
        margin-top: 50px;
    }

    #Lotery .ref__info {
        height: 100px;
    }

    .regListTop .RegisteredList #count .title__link, .regListTop .RegisteredList #referrals .title__link {
        font-size: 20px;
        margin-top: 13px;
    }

    .WinnerslistOffer, .RegistlistOffer {
      
        left: 16px;
     
    }

    .rightAssedsCard .Winnerslist {
        position: absolute;
        bottom: 849px;
        left: 93px;
    }
  
    .tickets .second__card, .tickets .offer_card {
        width: 320px;
    }

    .tickets .Registlist {
        bottom: 23px;
    }

    .tickets .offer_card p{
        width: 256px;
    
    }

    .sliderOfferTag {
        width: 340px;
        height: 452px;
        padding-bottom: 40px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    /* .offer1 .second__card {
        margin-left: 10px;
    } */

    .offer_card p {
        line-height: 11px;
    }
    .OfferTag__content.tickets .BlockMyAvailable {
        width: 160px;

    }
    
    .icon__comonca,
    .icon__legend,
    .icon__rare,
    .icon__uncomon,
    .icon__epic {
        width: 134px;
        background-position: center;
        
    }

    .tickets .AssedsCardMenubottom .Group {
        position: absolute;
        top: 62px;
        right: -5px;
    }
    .OfferTag__block .supreme__player,
    .OfferTag__block .gold__player,
    .OfferTag__block .basic__player{
        width: 134px;
    }
    .second__card .icon__infinity, 
    .second__card .icon__mystery,
    .second__card .icon__starter{
        width: 134px;
        background-position: 20px -7px;
    }
    .tickets .BlockMyChance,
    .tickets .BlockMyRegist {
        width: 136px;

    }

    .tickets .AssedsCardMenubottom {
        width: 320px;
    }
    .tickets .cardChange {
        right: 75px;
    }
    .tickets .fonCircle2 {
        right: -51px;
    }

    .tickets #infoRightAsseds {
        left: 176px;
    }

    .tickets .infoProgressRegist {
        left: 10px;
    
    }
    .AssedsCardMenubottom .infoProgressRegist{
        top: 21px;
    }
    .tickets .AssedsCardMenubottom .Registlist {
        left: 14px;
    }


    .info__card__uncommon {
        margin-top: 6px;
    }

    .sliderOfferTag .infoProgressRegist {
        left: 0;
    }
}
