.winner #WaxRef{
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    height: 40px;
}

.block__ref.winner{
    height: 40px;
}

.winner .title__link {
    margin-top: 7px;
}

.winner .iconPlayerInList {
    margin: 5px 10px 0 5px;
}

#Lotery .refCount.OfferUpIcon {
    background-image: url(../../img/Group\ 314\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;

}

.WinnerList {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}
#BoUp .ref__info,
#Lotery .ref__info {
    height: 48px;
    margin-bottom: 30px;
}

#Lotery .ref__info {
    height: 48px;
    margin-bottom: 10px;
}


.boostUpWinnerList .block__ref.bottom__width {
    width: 408px;
}

.boostUpWinnerList .block__ref2 {
    width: 358px;
}

.RegisteredList  .centerLink {
    align-items: center;
 
}

.Winner__common,
.Winner__leaf,
.Winner__violet,
.Winner__frosty,
.Winner__sunch{
    width: 144px;
    height: 40px;
    margin-left: 0;
    /* margin-left: 29px;
    margin-left: 30px; */
}


.iconWinnerBoostUp.Winner__common,
.iconWinnerBoostUp.Winner__leaf,
.iconWinnerBoostUp.Winner__violet,
.iconWinnerBoostUp.Winner__frosty,
.iconWinnerBoostUp.Winner__sunch{
    margin-left: 0px;
}
#BoUp .title__link.centerLink {
    margin-top: 22px;
}


.starter__box{
    width: 214px;
    background: url(../../img/start1.webp);
    background-size: 105%;
    background-repeat: no-repeat;
    background-position: 1px -44px;
}
.mystery__box{
    width: 214px;
    background: url(../../img/mys2.webp);
    background-size: 105%;
    background-repeat: no-repeat;
    background-position: 1px -44px;
}
.infinity__box{
    width: 214px;
    background: url(../../img/inf3.webp);
    background-size: 105%;
    background-repeat: no-repeat;
    background-position: 1px -44px;
}
.Winner__starter{
    background: url(../../img/start1.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}


.Winner__mystery{
    background: url(../../img/mys2.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.Winner__infinity{
    background: url(../../img/inf3.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.Winner__starter,
.Winner__mystery,
.Winner__infinity {
    width: 144px;
    height: 40px;
    /* margin-left: 29px;
    margin-left: 30px; */
}
   
                    

.bgWinner .iconWinnerBoostUp.Winner__common,
.bgWinner .iconWinnerBoostUp.Winner__leaf, 
.bgWinner .iconWinnerBoostUp.Winner__violet, 
.bgWinner .iconWinnerBoostUp.Winner__frosty, 
.bgWinner .iconWinnerBoostUp.Winner__sunch {
    margin-left: 0px;
}
.title__link.OfferUpIcon ,  
#BoostUpList.title__link {
    width: 120px;
}
#WaxRef .winnerTimer.winnerTimer {
   display: flex;

   width: 123px;

}
.Winner__common {
    background: url(../../img/newSteelade\ \(2\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.bg__block__ref:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 0.2784px 0px #F18C1E, 0px 0px 0.5568px 0px #F18C1E, 0px 0px 1.9488px 0px #F18C1E, 0px 0px 3.8976px 0px #F18C1E, 0px 0px 6.6816px 0px #F18C1E, 0px 0px 11.6928px 0px #F18C1E;
    transition: 0.1s;
}


.ContentList .title__link{
    margin-top: 7px;
}
#Lotery .bgWinner .block__ref {
    height: 40px;
    border-radius: 5px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

#BoostUpList {
    margin-top: 0px;
}
.Winner__leaf {
    background: url(../../img/newLeaflynn2\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.Winner__violet {
    background: url(../../img/newVioletta\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.Winner__frosty {
    background: url(../../img/newFrostee\ \(2\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}


.bgTextPlayer {
    line-height: 0.6;
    font-weight: 400;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bgTextPlayer .title__link {
    margin-top: 15px;
    font-weight: 400;
}

.bgTextPlayer .title__link.myProff {
    margin-top: 15px;
    font-weight: 400;
}
.title__link.bgTextPlayer {
    margin-top: 15px;
    font-weight: 400;
}
.Winner__sunch {
    background: url(../../img/newSunshine\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.ref__content .block__ref:last-child {
    margin-bottom: 150px;
}

.boostUpWinnerList #WaxRef .title__link {
    width: 116px;
}
@media (max-width: 710px) {
    #Lotery .ref__info{
        height: 114px;
    }
}